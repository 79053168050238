/* eslint-disable */
/* eslint-disable prettier/prettier */
import * as React from "react";
import Icon from '@ant-design/icons'

const ImportFile = () => (
    <svg
       xmlns="http://www.w3.org/2000/svg"
       version="1.1"
       width="16"
       height="16"
       id="ImportFile"
       viewBox="0 0 64 64"
      >
      <g
         id="layer1">
        <g
           transform="matrix(3.4989886,0,0,-3.4978029,31.889085,32.244482)"
           id="g690">
          <path
             d="m 0,0 3.251,3.209 -2.46,0 -0.001,0 0,4.995 -1.58,0 0,-4.996 -2.461,0 L 0,0 z"
             id="path692"
             style={{fill:'#e6e7e7',fillOpacity:1,fillRule:'nonzero',stroke:'none'}} />
        </g>
        <g
           transform="matrix(3.4989886,0,0,-3.4978029,29.124546,21.021796)"
           id="g694">
          <path d="m 0,0 0,4.995 1.58,0 0,-4.994 10e-4,0 2.46,0 L 0.79,-3.208 -2.461,0 0,0 z m 0.165,-4.018 c 0.167,-0.165 0.389,-0.256 0.625,-0.256 0.235,0 0.458,0.091 0.625,0.256 l 3.527,3.482 c 0.188,0.168 0.296,0.41 0.296,0.661 0,0.491 -0.398,0.89 -0.892,0.89 l -1.752,0 0,4.105 c 0,0.491 -0.4,0.89 -0.89,0.89 l -1.831,0 c -0.491,0 -0.89,-0.399 -0.89,-0.89 l 0,-4.105 -1.75,0 c -0.364,0 -0.687,-0.217 -0.823,-0.552 -0.137,-0.336 -0.059,-0.716 0.198,-0.97 l 3.557,-3.511 z"/>
        </g>
        <g
           transform="matrix(3.4989886,0,0,-3.4978029,3.4444051,44.652251)"
           id="g698">
          <path
             d="m 0,0 0,-2.001 8.034,0 C 8,-1.875 7.981,-1.76 7.957,-1.641 7.943,-1.569 7.921,-1.493 7.911,-1.423 7.878,-1.212 7.859,-1.008 7.859,-0.806 7.859,-0.554 7.885,-0.29 7.936,0 L 0,0 z"
             id="path700"
             style={{fill:'#e6e7e7',fillOpacity:1,fillRule:'nonzero',stroke:'none'}} />
        </g>
        <g
           transform="matrix(3.4989886,0,0,-3.4978029,45.709052,45.706126)"
           id="g702">
          <path
             d="m 0,0 0,1.508 c 0,0.278 0.226,0.504 0.504,0.504 0.278,0 0.504,-0.226 0.504,-0.504 L 1.008,0 2.516,0 C 2.794,0 3.02,-0.226 3.02,-0.504 3.02,-0.782 2.794,-1.008 2.516,-1.008 l -1.508,0 0,-1.509 C 1.008,-2.795 0.782,-3.021 0.504,-3.021 0.226,-3.021 0,-2.795 0,-2.517 l 0,1.509 -1.508,0 c -0.278,0 -0.504,0.226 -0.504,0.504 0,0.278 0.226,0.504 0.504,0.504 L 0,0 z m -3.212,-0.504 c 0.001,-2.049 1.667,-3.716 3.715,-3.716 2.049,0 3.716,1.667 3.716,3.716 0,0.515 -0.11,1.021 -0.326,1.505 C 3.444,2.008 2.583,2.748 1.565,3.054 1.225,3.157 0.868,3.211 0.503,3.211 -0.906,3.211 -2.176,2.416 -2.813,1.135 -2.932,0.896 -3.025,0.647 -3.089,0.396 -3.173,0.06 -3.212,-0.226 -3.212,-0.504"
             id="path704"
             style={{fill:'#ffffff',fillOpacity:1,fillRule:'nonzero',stroke:'none'}}/>
        </g>
        <g
           transform="matrix(3.4989886,0,0,-3.4978029,31.123504,49.631026)"
           id="g706">
          <path d="m 0,0 c 0.011,-0.069 0.032,-0.146 0.046,-0.217 0.025,-0.119 0.043,-0.235 0.078,-0.361 l -8.035,0 0,2.001 7.936,0 C -0.026,1.133 -0.051,0.869 -0.051,0.617 -0.051,0.416 -0.033,0.211 0,0 M 1.355,2.257 C 1.992,3.538 3.263,4.333 4.671,4.333 5.037,4.333 5.394,4.279 5.733,4.176 6.752,3.87 7.612,3.13 8.061,2.123 8.278,1.639 8.388,1.133 8.388,0.618 8.387,-1.431 6.721,-3.098 4.671,-3.098 c -2.048,0 -3.714,1.667 -3.714,3.716 0,0.278 0.039,0.564 0.123,0.9 0.064,0.252 0.156,0.501 0.275,0.739 M 4.876,5.321 C 4.85,5.322 4.825,5.326 4.799,5.33 4.612,5.357 4.415,5.327 4.233,5.301 L 4.097,5.283 C 2.429,5.078 1.005,3.996 0.339,2.438 l -8.375,0 c -0.473,0 -0.859,-0.399 -0.859,-0.89 l 0,-2.251 c 0,-0.491 0.386,-0.89 0.859,-0.89 l 8.561,0 c 0.829,-1.552 2.408,-2.515 4.146,-2.515 2.605,10e-4 4.725,2.121 4.725,4.725 0,2.528 -1.986,4.594 -4.52,4.704"/>
        </g>
        <g
           transform="matrix(3.4989886,0,0,-3.4978029,38.670123,47.468683)"
           id="g710">
          <path d="m 0,0 c 0,-0.278 0.226,-0.504 0.504,-0.504 l 1.508,0 0,-1.509 c 0,-0.278 0.226,-0.504 0.504,-0.504 0.277,0 0.504,0.226 0.504,0.504 l 0,1.509 1.508,0 c 0.278,0 0.504,0.226 0.504,0.504 0,0.278 -0.226,0.504 -0.504,0.504 l -1.508,0 0,1.508 C 3.02,2.29 2.793,2.516 2.516,2.516 2.238,2.516 2.012,2.29 2.012,2.012 l 0,-1.508 -1.508,0 C 0.226,0.504 0,0.278 0,0"/>
        </g>
      </g>
    </svg>  
      )
      
      export const ImportGpsFile = () => <Icon component={ImportFile} />
    
    
const UnlockIcon = ({width, height}) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      id="unlockIcon"
      viewBox="0 0 16 16"
    >
    <g
      id="layer1">
      <g
        transform="matrix(0.78574594,0,0,-0.80366583,5.5205052,2.9119033)"
        id="g534">
        <path
          d="m 0,0 c -1.343,1.255 -3.262,1.396 -4.629,0.401 l -1.21,1.13 c 1.146,0.958 2.647,1.285 4.095,0.985 0.954,-0.198 1.882,-0.666 2.673,-1.404 1.087,-1.015 1.76,-2.386 1.896,-3.86 0.022,-0.248 0.008,-0.488 0,-0.73 l -1.16,0 -0.058,0 -0.277,0 c 0.023,0.249 0.025,0.503 0,0.76 C 1.235,-1.677 0.762,-0.712 0,0"
          id="path536"
          style={{fill:'#e6e7e7',fillOpacity:1,fillRule:'nonzero',stroke:'none'}} />
      </g>
      <g
        transform="matrix(0.78574594,0,0,-0.80366583,9.1074358,8.3032923)"
        id="g538">
        <path d="m 0,0 c -0.297,-0.108 -0.566,-0.268 -0.786,-0.479 0.219,0.207 0.486,0.365 0.78,0.471 C -0.004,-0.005 -0.003,-0.002 0,0"/>
      </g>
      <g
        transform="matrix(0.78574594,0,0,-0.80366583,7.8544853,5.7070531)"
        id="g542">
        <path d="m 0,0 -0.146,0 c 0.009,0.242 0.023,0.483 0,0.73 -0.135,1.474 -0.809,2.845 -1.895,3.86 -0.791,0.739 -1.719,1.207 -2.672,1.405 -1.448,0.3 -2.949,-0.027 -4.095,-0.985 l 1.209,-1.13 c 1.368,0.995 3.287,0.854 4.63,-0.401 0.76,-0.713 1.234,-1.678 1.329,-2.719 0.025,-0.257 0.023,-0.511 0,-0.76 l -0.958,0 c 0.027,0.216 0.037,0.437 0.017,0.664 -0.073,0.788 -0.434,1.521 -1.017,2.065 -1.096,1.026 -2.691,1.079 -3.707,0.127 -0.179,-0.167 -0.449,-0.166 -0.628,0 l -1.904,1.779 c -0.098,0.091 -0.155,0.222 -0.159,0.361 -0.004,0.139 0.048,0.273 0.141,0.37 2.23,2.323 5.936,2.313 8.44,-0.026 C -0.148,4.156 0.637,2.554 0.797,0.828 0.822,0.549 0.825,0.273 0.818,0 L 0.1,0 0,0 z"/>
      </g>
      <g
        transform="matrix(0.78574594,0,0,-0.80366583,11.120517,12.648714)"
        id="g546">
        <path
          d="M 0,0 C 0.03,-0.26 -0.06,-0.52 -0.24,-0.72 -0.242,-0.722 -0.245,-0.723 -0.247,-0.725 -0.248,-0.727 -0.249,-0.729 -0.25,-0.73 -0.43,-0.93 -0.69,-1.04 -0.97,-1.04 l -1.65,0 c -0.27,0 -0.54,0.11 -0.72,0.31 -0.18,0.2 -0.27,0.46 -0.24,0.72 l 0.23,1.96 C -3.77,2.34 -4,2.87 -4,3.44 c 0,0.582 0.249,1.108 0.652,1.488 0.22,0.211 0.489,0.371 0.786,0.479 0.244,0.088 0.506,0.143 0.782,0.143 1.22,0 2.21,-0.94 2.21,-2.1 0,-0.57 -0.23,-1.1 -0.65,-1.49 L 0,0 z m 3.723,7.64 -1.643,0 -0.01,0 -7.72,0 -1.486,0 0,0.01 -1.544,0 0,-9.72 c 0,-0.58 0.49,-1.05 1.1,-1.05 l 11.58,0 c 0.61,0 1.1,0.47 1.1,1.05 l 0,9.72 -1.377,0 0,-0.01 z"
          id="path548"
          style={{fill:'#e6e7e7',fillOpacity:1,fillRule:'nonzero',stroke:'none'}} />
      </g>
      <g
        transform="matrix(0.78574594,0,0,-0.80366583,15.127822,14.312302)"
        id="g550">
        <path d="m 0,0 c 0,-0.58 -0.49,-1.05 -1.1,-1.05 l -11.58,0 c -0.61,0 -1.1,0.47 -1.1,1.05 l 0,9.72 1.544,0 0,-0.01 1.486,0 7.72,0 0.01,0 1.643,0 0,0.01 L 0,9.72 0,0 z m 0.14,10.77 -0.14,0 -0.55,0 -0.827,0 -0.273,0 -1.274,0 -0.014,0 -0.082,0 -0.01,0 -6.354,0 -1.013,0 -0.292,0 -0.061,0 -1.226,0 -0.154,0 -0.106,0 -0.758,0 -0.246,0 -0.68,0 c -0.54,0 -0.97,-0.42 -0.97,-0.92 l 0,-9.85 c 0,-1.16 0.99,-2.1 2.21,-2.1 l 11.58,0 c 1.22,0 2.21,0.94 2.21,2.1 l 0,9.85 c 0,0.5 -0.43,0.92 -0.97,0.92"/>
      </g>
      <g
        transform="matrix(0.78574594,0,0,-0.80366583,10.224766,10.559182)"
        id="g554">
        <path d="m 0,0 -0.26,-0.18 0.28,-2.39 -1.34,0 0.28,2.39 L -1.3,0 c -0.29,0.2 -0.45,0.5 -0.45,0.84 0,0.58 0.49,1.05 1.1,1.05 0.61,0 1.1,-0.47 1.1,-1.05 C 0.45,0.5 0.29,0.2 0,0 M 0.9,-0.65 C 1.32,-0.26 1.55,0.27 1.55,0.84 1.56,2 0.57,2.94 -0.65,2.94 -0.925,2.94 -1.186,2.886 -1.429,2.799 -1.723,2.693 -1.99,2.535 -2.209,2.328 -2.611,1.948 -2.861,1.422 -2.861,0.84 c 0,-0.57 0.23,-1.1 0.65,-1.49 l -0.23,-1.96 c -0.03,-0.26 0.06,-0.52 0.24,-0.72 0.18,-0.2 0.45,-0.31 0.72,-0.31 l 1.65,0 c 0.28,0 0.54,0.11 0.72,0.31 0.001,0.002 0.002,0.004 0.003,0.005 0.177,0.199 0.267,0.457 0.237,0.715 L 0.9,-0.65 z"/>
      </g>
    </g>
  </svg>
)
      
export const PublicTourIcon = ({width, height}) => <Icon component={()=><UnlockIcon width={width} height={height}/>}/> 


const LockIcon = ({width, height}) => (
    <svg
       xmlns="http://www.w3.org/2000/svg"
       version="1.1"
       width={width}
       height={height}
       id="lockIcon"
       viewBox="0 0 16 16"
      >
        <g
            id="layer1">
            <g
            transform="matrix(0.80160321,0,0,-0.80160321,12.52828,6.5330659)"
            id="g474">
            <path
                d="m 0,0 -7.72,0 0,2.1 c 0,2.03 1.73,3.68 3.86,3.68 C -1.73,5.78 0,4.13 0,2.1 L 0,0 z m -2.07,-7.64 c 0.03,-0.26 -0.06,-0.52 -0.24,-0.72 -0.18,-0.2 -0.45,-0.31 -0.72,-0.31 l -1.65,0 c -0.27,0 -0.54,0.11 -0.72,0.31 -0.18,0.19 -0.27,0.46 -0.24,0.72 l 0.23,1.96 c -0.42,0.39 -0.65,0.92 -0.65,1.49 0,1.16 0.99,2.1 2.21,2.1 1.22,0 2.21,-0.94 2.21,-2.1 0,-0.57 -0.23,-1.1 -0.65,-1.49 l 0.22,-1.96 z M 1.38,2.11 C 1.38,4.86 -0.97,7.1 -3.86,7.1 -6.75,7.1 -9.1,4.86 -9.1,2.11 l 0,-2.1 -1.65,0 0,-9.72 c 0,-0.58 0.49,-1.05 1.1,-1.05 l 11.58,0 c 0.61,0 1.1,0.47 1.1,1.05 l 0,9.72 -1.65,0 0,2.1 z"
                id="path476"
                style={{fill:'#e6e7e7',fillOpacity:1,fillRule:'nonzero',stroke:'none'}} />
            </g>
            <g
            transform="matrix(0.80160321,0,0,-0.80160321,14.957138,14.316633)"
            id="g478">
            <path d="m 0,0 c 0,-0.58 -0.49,-1.05 -1.1,-1.05 l -11.58,0 c -0.61,0 -1.1,0.47 -1.1,1.05 l 0,9.72 1.65,0 0,2.1 c 0,2.75 2.35,4.99 5.24,4.99 2.89,0 5.24,-2.24 5.24,-4.99 l 0,-2.1 L 0,9.72 0,0 z m 0.14,10.77 -0.69,0 0,1.05 c 0,3.33 -2.85,6.04 -6.35,6.04 -3.5,0 -6.34,-2.71 -6.34,-6.04 l 0,-1.05 -0.68,0 c -0.54,0 -0.97,-0.42 -0.97,-0.92 l 0,-9.85 c 0,-1.16 0.99,-2.1 2.21,-2.1 l 11.58,0 c 1.22,0 2.21,0.94 2.21,2.1 l 0,9.85 c 0,0.5 -0.43,0.92 -0.97,0.92"/>
            </g>
            <g
            transform="matrix(0.80160321,0,0,-0.80160321,11.646517,5.6833665)"
            id="g482">
            <path d="m 0,0 -5.52,0 0,1.05 c 0,1.45 1.24,2.62 2.76,2.62 C -1.24,3.67 0,2.49 0,1.05 L 0,0 z m -2.76,4.72 c -2.13,0 -3.86,-1.65 -3.86,-3.68 l 0,-2.1 7.73,0 0,2.1 C 1.1,3.07 -0.63,4.72 -2.76,4.72"/>
            </g>
            <g
            transform="matrix(0.80160321,0,0,-0.80160321,9.9551338,10.573146)"
            id="g486">
            <path d="m 0,0 -0.26,-0.18 0.28,-2.39 -1.34,0 0.28,2.39 L -1.3,0 c -0.29,0.2 -0.45,0.5 -0.45,0.84 0,0.58 0.49,1.05 1.1,1.05 0.61,0 1.1,-0.47 1.1,-1.05 C 0.45,0.5 0.29,0.2 0,0 m -0.65,2.94 c -1.22,0 -2.21,-0.94 -2.21,-2.1 0,-0.57 0.23,-1.1 0.65,-1.49 l -0.23,-1.96 c -0.03,-0.26 0.06,-0.52 0.24,-0.72 0.18,-0.2 0.45,-0.31 0.72,-0.31 l 1.65,0 c 0.28,0 0.54,0.11 0.72,0.31 0.18,0.2 0.27,0.46 0.24,0.72 L 0.9,-0.65 c 0.42,0.39 0.65,0.92 0.65,1.49 0.01,1.16 -0.98,2.1 -2.2,2.1"/>
            </g>
        </g>
    </svg>
);

export const PrivateTourIcon = ({width, height}) => ( <Icon component={()=><LockIcon width={width} height={height}/>}/>);


const UndoIcon = () => (
    <svg
       xmlns="http://www.w3.org/2000/svg"
       version="1.1"
       width="18"
       height="18"
       id="undo"
       viewBox="0 0 18 18"
      >
         <g
            transform="translate(0,2)"
            id="layer1">
            <g
               transform="matrix(0.89473435,0,0,-0.89473435,17.240502,13.552633)"
               id="g222">
               <path
                  d="m 0,0 c -0.125,0 -0.243,0.057 -0.302,0.146 -3.034,4.638 -6.957,5.331 -9.026,5.331 -0.297,0 -0.556,-0.015 -0.769,-0.034 l -0.456,-0.04 0,-4.379 c 0,-0.1 -0.075,-0.192 -0.192,-0.236 -0.048,-0.018 -0.097,-0.027 -0.146,-0.027 -0.097,0 -0.191,0.034 -0.256,0.094 l -7.539,6.857 c -0.054,0.049 -0.083,0.107 -0.083,0.169 0,0.061 0.029,0.119 0.082,0.168 l 7.541,6.858 c 0.064,0.058 0.158,0.093 0.256,0.093 0.05,0 0.098,-0.009 0.143,-0.026 0.119,-0.044 0.194,-0.137 0.194,-0.236 l 0,-3.532 0.482,-0.018 c 4.649,-0.174 8.634,-3 9.915,-7.033 C 0.399,2.402 0.357,0.969 0.341,0.429 L 0.338,0.271 C 0.338,0.15 0.24,0.047 0.102,0.012 0.068,0.004 0.033,0 0,0"
                  id="path224"
                  style={{fill:'#e6e7e7',fillOpacity:1,fillRule:'nonzero',stroke:'none'}} />
            </g>
            <g transform="matrix(0.89473435,0,0,-0.89473435,7.4972033,-0.31574956)">
               <path d="m 0,0 c -0.219,0 -0.433,-0.078 -0.594,-0.223 l -7.54,-6.858 c -0.327,-0.297 -0.327,-0.779 0,-1.077 l 7.54,-6.857 c 0.16,-0.146 0.374,-0.224 0.593,-0.224 0.107,0 0.217,0.019 0.32,0.059 0.314,0.117 0.518,0.396 0.518,0.704 l 0,3.921 c 0.201,0.018 0.445,0.032 0.725,0.032 1.97,0 5.703,-0.663 8.608,-5.105 0.153,-0.234 0.43,-0.372 0.72,-0.372 0.073,0 0.148,0.009 0.222,0.027 0.364,0.091 0.616,0.392 0.616,0.735 l 0.002,0.148 c 0.017,0.563 0.063,2.057 -0.52,3.896 -1.348,4.241 -5.553,7.201 -10.373,7.381 l 0,3.051 c 0,0.308 -0.204,0.587 -0.518,0.704 C 0.216,-0.019 0.107,0 0,0 m -0.163,-1.184 0,-2.629 0,-0.963 0.962,-0.036 c 4.439,-0.166 8.24,-2.852 9.457,-6.684 0.432,-1.359 0.485,-2.518 0.482,-3.188 -3.124,4.478 -7.067,5.161 -9.176,5.161 -0.277,0 -0.55,-0.012 -0.814,-0.036 l -0.911,-0.08 0,-0.916 0,-3.499 -7.076,6.435 7.076,6.435"/>
            </g>
         </g>
      </svg>
);

export const UndoTourIcon = () => ( <Icon component={UndoIcon}/>);

const RedoIcon = () => (
    <svg
       xmlns="http://www.w3.org/2000/svg"
       version="1.1"
       width="18"
       height="18"
       id="undo"
       viewBox="0 0 16 16"
      >
         <g
            id="layer1">
            <g
               transform="matrix(0.79531979,0,0,-0.79531979,0.67502586,13.602337)">
               <path
                  d="M 0,0 C -0.033,0 -0.068,0.004 -0.104,0.013 -0.24,0.047 -0.338,0.15 -0.338,0.262 l -0.003,0.157 c -0.016,0.55 -0.058,1.983 0.498,3.736 1.28,4.033 5.265,6.86 9.915,7.033 l 0.482,0.018 0,3.532 c 0,0.1 0.075,0.193 0.192,0.236 0.046,0.017 0.094,0.026 0.144,0.026 0.098,0 0.192,-0.034 0.257,-0.093 l 7.54,-6.858 c 0.053,-0.048 0.082,-0.107 0.082,-0.168 0,-0.062 -0.029,-0.12 -0.082,-0.169 l -7.54,-6.857 c -0.066,-0.06 -0.159,-0.093 -0.256,-0.093 -0.05,0 -0.098,0.008 -0.144,0.026 -0.118,0.044 -0.193,0.136 -0.193,0.236 l 0,4.379 -0.456,0.04 C 9.884,5.462 9.625,5.477 9.328,5.477 7.26,5.477 3.335,4.784 0.302,0.146 0.243,0.058 0.125,0 0,0"
                  id="path216"
                  fill="#e6e7e7" />
            </g>
            <g
               transform="matrix(0.79531979,0,0,-0.79531979,9.3358167,1.2748834)">
               <path d="m 0,0 c -0.107,0 -0.216,-0.019 -0.319,-0.058 -0.314,-0.117 -0.517,-0.396 -0.517,-0.704 l 0,-3.051 c -4.821,-0.179 -9.026,-3.139 -10.373,-7.381 -0.584,-1.839 -0.538,-3.333 -0.521,-3.896 l 0.002,-0.148 c 0,-0.343 0.253,-0.644 0.616,-0.735 0.074,-0.018 0.149,-0.027 0.222,-0.027 0.29,0 0.567,0.138 0.72,0.372 2.905,4.441 6.638,5.105 8.608,5.105 0.28,0 0.524,-0.014 0.726,-0.032 l 0,-3.921 c 0,-0.308 0.203,-0.587 0.517,-0.704 0.103,-0.04 0.213,-0.059 0.32,-0.059 0.219,0 0.433,0.078 0.593,0.224 l 7.54,6.857 c 0.327,0.298 0.327,0.78 0,1.078 l -7.54,6.857 C 0.434,-0.078 0.219,0 0,0 m 0.164,-1.184 7.075,-6.435 -7.075,-6.435 0,3.499 0,0.916 -0.912,0.08 c -0.264,0.024 -0.537,0.036 -0.814,0.036 -2.109,0 -6.053,-0.683 -9.176,-5.161 -0.004,0.664 0.05,1.826 0.481,3.187 1.218,3.833 5.019,6.519 9.458,6.685 l 0.963,0.036 0,0.963 0,2.629"/>
            </g>
         </g>
      </svg>
);

export const RedoTourIcon = () => ( <Icon component={RedoIcon}/>);


const resetIcon = () => (
   <svg
   xmlns="http://www.w3.org/2000/svg"
   version="1.1"
   width="17"
   height="17"
   id="Reset"
   viewBox="0 0 10.999 10.999"
  >
      <title>Reset</title>
      <path d="M965.45613,2084.73183a5.48781,5.48781,0,0,0-5.15851,7.39292,0.14649,0.14649,0,0,0,.18551.08978l0.566-.18551a0.14831,0.14831,0,0,0,.0874-0.0767,0.15105,0.15105,0,0,0,.00535-0.11594,4.60484,4.60484,0,1,1,4.31422,3.00319,4.52823,4.52823,0,0,1-2.10776-.52322l0.283-.41263a0.14862,0.14862,0,0,0-.11059-0.23248l-1.95733-.15281a0.13627,0.13627,0,0,0-.13437.06362,0.1486,0.1486,0,0,0-.01189.148l0.84608,1.77123a0.14807,0.14807,0,0,0,.12248.08443l0.01189,0.00059a0.14873,0.14873,0,0,0,.12248-0.06421l0.31691-.462,0.00238,0.003A5.49747,5.49747,0,1,0,965.45613,2084.73183Z" transform="translate(-959.95633 -2084.73183)"/>
   </svg>
);

export const ResetIcon = () => ( <Icon component={resetIcon}/>);

const binIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="18"
    height="18"
    id="Bin"
    viewBox="0 0 18 18"
  >
    <g transform="translate(0,-14)">
      <g transform="matrix(0.9,0,0,-0.9,10.658634,14.000086)" id="g630">
        <path
          d="M 0,0 0,0 C 0.323,0 0.631,-0.06 0.911,-0.169 0.631,-0.06 0.323,0 0,0"
          fill="#e6e7e7"
        ></path>
      </g>
      <g transform="matrix(0.9,0,0,-0.9,11.478174,14.152186)" id="g634">
        <path
          d="M 0,0 C 0.28,-0.109 0.532,-0.266 0.744,-0.461 0.532,-0.266 0.28,-0.109 0,0"
          id="path636"
          fill="#e6e7e7"
        ></path>
      </g>
      <g transform="matrix(0.9,0,0,-0.9,9.2298872,19.8797)" id="g638">
        <path
          d="m 0,0 -5.85,0 0.799,-12.467 5.051,0 4.985,0 L 5.784,0 0,0 z"
          id="path640"
          fill="#e6e7e7"
        ></path>
      </g>
      <g transform="matrix(0.9,0,0,-0.9,14.6756,17.11022)" id="g642">
        <path
          d="m 0,0 -12.168,0 c -0.505,0 -0.916,-0.438 -0.916,-0.976 l 0,-1.101 7.033,0 6.967,0 0,1.101 C 0.916,-0.438 0.505,0 0,0"
          id="path644"
          fill="#e6e7e7"
        ></path>
      </g>
      <g transform="matrix(0.9,0,0,-0.9,15.5,18.9797)" id="g646">
        <path d="M 0,0 -6.967,0 -14,0 l 0,1.102 c 0,0.538 0.411,0.975 0.916,0.975 l 12.168,0 C -0.411,2.077 0,1.639 0,1.101 L 0,0 z m -1.982,-13.467 -4.985,0 -5.051,0 -0.799,12.467 5.85,0 5.784,0 -0.799,-12.467 z M -9.685,3.384 c 0,0.135 0.03,0.263 0.084,0.38 0.108,0.234 0.312,0.421 0.566,0.52 0.128,0.05 0.268,0.077 0.414,0.077 l 1.654,0 1.588,0 c 0.44,0 0.818,-0.246 0.98,-0.597 0.054,-0.117 0.084,-0.245 0.084,-0.38 l 0,-0.307 -5.37,0 0,0.307 z M 0.849,1.87 C 0.558,2.579 -0.124,3.077 -0.916,3.077 l -2.122,0 0,0.307 c 0,0.593 -0.262,1.13 -0.686,1.519 -0.212,0.194 -0.465,0.352 -0.745,0.461 -0.28,0.109 -0.588,0.169 -0.91,0.169 l -1.588,0 -1.654,0 c -1.291,0 -2.341,-0.964 -2.341,-2.149 l 0,-0.307 -2.122,0 C -14.14,3.077 -15,2.191 -15,1.102 l 0,-1.443 C -15,-0.705 -14.714,-1 -14.361,-1 l 0.542,0 0.828,-12.916 c 0.02,-0.309 0.299,-0.551 0.637,-0.551 l 5.387,0 0,0 5.321,0 0,0 c 0.338,0 0.618,0.242 0.637,0.551 L -0.18,-1 0.361,-1 C 0.714,-1 1,-0.705 1,-0.341 L 1,1.101 1,1.102 C 1,1.374 0.946,1.634 0.849,1.87"></path>
      </g>
    </g>
  </svg>
);
export const DeleteIcon = (props) => <Icon component={binIcon} style={props.style} onClick={() => props.onClick}/>;

const PencilIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="18"
    height="18"
    id="Bin"
    viewBox="0 0 32 32"
  >
    <g id="layer1">
      <g
        transform="matrix(1.7739116,0,0,-1.7777778,5.4580916,21.642851)"
        id="g382"
      >
        <path
          d="M 0,0 8.98,8.98 11.879,6.081 2.899,-2.899 0,0 z"
          id="path384"
          fill="#e6e7e7"
        ></path>
      </g>
      <g
        transform="matrix(1.7739116,0,0,-1.7777778,2.8864456,29.333333)"
        id="g386"
      >
        <path
          d="M 0,0 3.2,0.9 0.8,3.2 0,0 z"
          id="path388"
          fill="#ffffff"
        ></path>
      </g>
      <g
        transform="matrix(1.7739116,0,0,-1.7777778,27.898612,3.0222219)"
        id="g390"
      >
        <path
          d="M 0,0 C -0.6,0.6 -1.6,0.6 -2.2,0 L -2.9,-0.7 0,-3.6 0.7,-2.9 c 0.6,0.6 0.6,1.6 0,2.2 L 0,0 z"
          id="path392"
          fill="#ffffff"
        ></path>
      </g>
      <g
        transform="matrix(1.7739116,0,0,-1.7777778,29.14035,7.9999997)"
        id="g394"
      >
        <path
          d="m 0,0 -0.7,-0.7 -2.9,2.9 0.7,0.7 c 0.6,0.6 1.6,0.6 2.2,0 L 0,2.2 C 0.6,1.5 0.6,0.6 0,0 m -10.5,-10.5 -2.9,2.9 9,9 2.9,-2.9 -9,-9 z m -4.3,-1.5 0.9,3.2 2.3,-2.3 -3.2,-0.9 z M 0.1,3.7 c -0.5,0.5 -1.2,0.8 -2,0.8 -0.7,0 -1.4,-0.3 -2,-0.8 L -14.7,-7.2 c -0.1,-0.1 -0.1,-0.2 -0.2,-0.3 l -1.4,-5.2 c -0.1,-0.2 0,-0.4 0.2,-0.6 0.1,-0.2 0.3,-0.2 0.5,-0.2 l 5.2,1.4 c 0.1,0 0.2,0.1 0.3,0.2 l 11,11 c 0.5,0.5 0.8,1.2 0.8,2 0,0.7 -0.3,1.4 -0.8,2 L 0.1,3.7 z"
          id="path396"
          fill="#4d4c4b"
        ></path>
      </g>
    </g>
  </svg>
);
export const EditIcon = (props) => ( <Icon component={PencilIcon} style={props.style} onClick={() => props.onClick}/>);
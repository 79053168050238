import React from 'react';

export const unlock = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 19.97128">
            <path fill="none" d="M939.737,163.76009a1.07423,1.07423,0,0,0-1.1,1.05,1.00105,1.00105,0,0,0,.45.84l0.26,0.18-0.28,2.39h1.34l-0.28-2.39,0.26-.18a1.00105,1.00105,0,0,0,.45-0.84A1.07423,1.07423,0,0,0,939.737,163.76009Z" transform="translate(-931.73703 -152.44881)"/>
            <path fill="none" d="M939.747,162.70009a2.27039,2.27039,0,0,0-.782.143l-0.006.008a2.30005,2.30005,0,0,1,.779-0.141,2.14216,2.14216,0,0,1,2.2,2.1,2.01344,2.01344,0,0,1-.65,1.49l0.23,1.96a0.91973,0.91973,0,0,1-.237.715l0.007-.005a0.92077,0.92077,0,0,0,.24-0.72l-0.22-1.96a2.01344,2.01344,0,0,0,.65-1.49A2.15885,2.15885,0,0,0,939.747,162.70009Z" transform="translate(-931.73703 -152.44881)"/>
            <path fill="#e6e7e7" d="M937.636,156.07209a3.99706,3.99706,0,0,1,4.892-.401l1.278-1.13a5.30268,5.30268,0,0,0-4.327-.985,6.02505,6.02505,0,0,0-2.825,1.404,5.91923,5.91923,0,0,0-2.003,3.86,5.38883,5.38883,0,0,0,0,.73h1.579a3.8598,3.8598,0,0,1,0-.76A4.1655,4.1655,0,0,1,937.636,156.07209Z" transform="translate(-931.73703 -152.44881)"/>
            <path d="M938.965,162.84309a2.22971,2.22971,0,0,0-.786.479,2.23321,2.23321,0,0,1,.78-0.471Z" transform="translate(-931.73703 -152.44881)"/>
            <path d="M934.497,159.55009h0.154a5.38888,5.38888,0,0,1,0-.73,5.91923,5.91923,0,0,1,2.003-3.86,6.019,6.019,0,0,1,2.824-1.405,5.30268,5.30268,0,0,1,4.327.985l-1.278,1.13a3.99706,3.99706,0,0,0-4.892.401,4.1733,4.1733,0,0,0-1.405,2.719,3.8598,3.8598,0,0,0,0,.76h1.013a2.87733,2.87733,0,0,1-.018-0.664,3.16728,3.16728,0,0,1,1.074-2.065,2.96317,2.96317,0,0,1,3.918-.127,0.50245,0.50245,0,0,0,.663,0l2.012-1.779a0.49977,0.49977,0,0,0,.02-0.731,6.56137,6.56137,0,0,0-8.92.026,6.92222,6.92222,0,0,0-2.337,4.512,6.5724,6.5724,0,0,0-.022.828h0.864Z" transform="translate(-931.73703 -152.44881)"/>
            <path fill="#e6e7e7" d="M945.25,160.61009H934.391v-0.01h-1.544v9.72a1.07423,1.07423,0,0,0,1.1,1.05h11.58a1.07423,1.07423,0,0,0,1.1-1.05v-9.72H945.25v0.01Zm-3.723,7.64a0.92078,0.92078,0,0,1-.24.72l-0.007.005-0.003.005a0.95927,0.95927,0,0,1-.72.31h-1.65a0.97519,0.97519,0,0,1-.72-0.31,0.92077,0.92077,0,0,1-.24-0.72l0.23-1.96a2.01344,2.01344,0,0,1-.65-1.49,2.03837,2.03837,0,0,1,.652-1.488,2.22971,2.22971,0,0,1,.786-0.479,2.29861,2.29861,0,0,1,.782-0.143,2.15732,2.15732,0,0,1,2.21,2.1,2.01344,2.01344,0,0,1-.65,1.49Z" transform="translate(-931.73703 -152.44881)"/>
            <path d="M946.767,159.55009h-14.06a0.94853,0.94853,0,0,0-.97.92v9.85a2.15733,2.15733,0,0,0,2.21,2.1h11.58a2.15733,2.15733,0,0,0,2.21-2.1v-9.85A0.94853,0.94853,0,0,0,946.767,159.55009Zm-0.14,10.77a1.07423,1.07423,0,0,1-1.1,1.05h-11.58a1.07423,1.07423,0,0,1-1.1-1.05v-9.72h1.544v0.01H945.25v-0.01h1.377v9.72Z" transform="translate(-931.73703 -152.44881)"/>
            <path d="M941.287,166.30009a2.01344,2.01344,0,0,0,.65-1.49,2.14217,2.14217,0,0,0-2.2-2.1,2.30454,2.30454,0,0,0-.779.141,2.23321,2.23321,0,0,0-.78.471,2.04218,2.04218,0,0,0-.652,1.488,2.01344,2.01344,0,0,0,.65,1.49l-0.23,1.96a0.92078,0.92078,0,0,0,.24.72,0.97519,0.97519,0,0,0,.72.31h1.65a0.95928,0.95928,0,0,0,.72-0.31l0.003-.005a0.91973,0.91973,0,0,0,.237-0.715Zm-0.9-.65-0.26.18,0.28,2.39h-1.34l0.28-2.39-0.26-.18a1.00105,1.00105,0,0,1-.45-0.84,1.10119,1.10119,0,0,1,2.2,0A1.00105,1.00105,0,0,1,940.387,165.65009Z" transform="translate(-931.73703 -152.44881)"/>
    </svg>
);

export const lock = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 19.96">
        <path fill="none" d="M939.742,202.07162a1.07423,1.07423,0,0,0-1.1,1.05,1.00105,1.00105,0,0,0,.45.84l0.26,0.18-0.28,2.39h1.34l-0.28-2.39,0.26-.18a1.00105,1.00105,0,0,0,.45-0.84A1.07423,1.07423,0,0,0,939.742,202.07162Z" transform="translate(-931.742 -190.77162)"/>
        <path fill="none" d="M939.742,194.19162a2.7,2.7,0,0,0-2.76,2.62v1.05h5.51v-1.05A2.68476,2.68476,0,0,0,939.742,194.19162Z" transform="translate(-931.742 -190.77162)"/>
        <path fill="#e6e7e7" d="M944.982,196.81162a5.24626,5.24626,0,0,0-10.48,0v2.1h-1.65v9.72a1.07423,1.07423,0,0,0,1.1,1.05h11.58a1.07423,1.07423,0,0,0,1.1-1.05v-9.72h-1.65v-2.1Zm-3.45,9.75a0.92078,0.92078,0,0,1-.24.72,0.97519,0.97519,0,0,1-.72.31h-1.65a0.97519,0.97519,0,0,1-.72-0.31,0.90887,0.90887,0,0,1-.24-0.72l0.23-1.96a2.01344,2.01344,0,0,1-.65-1.49,2.21288,2.21288,0,0,1,4.42,0,2.01344,2.01344,0,0,1-.65,1.49Zm2.07-7.64h-7.72v-2.1a3.8644,3.8644,0,0,1,7.72,0v2.1Z" transform="translate(-931.742 -190.77162)"/>
        <path d="M946.772,197.86162h-0.69v-1.05a6.3527,6.3527,0,0,0-12.69,0v1.05h-0.68a0.94853,0.94853,0,0,0-.97.92v9.85a2.15733,2.15733,0,0,0,2.21,2.1h11.58a2.15733,2.15733,0,0,0,2.21-2.1v-9.85A0.94853,0.94853,0,0,0,946.772,197.86162Zm-0.14,10.77a1.07423,1.07423,0,0,1-1.1,1.05h-11.58a1.07423,1.07423,0,0,1-1.1-1.05v-9.72h1.65v-2.1a5.24626,5.24626,0,0,1,10.48,0v2.1h1.65v9.72Z" transform="translate(-931.742 -190.77162)"/>
        <path d="M939.742,193.14162a3.77594,3.77594,0,0,0-3.86,3.68v2.1h7.73v-2.1A3.79123,3.79123,0,0,0,939.742,193.14162Zm2.76,4.72h-5.52v-1.05a2.76374,2.76374,0,0,1,5.52,0v1.05Z" transform="translate(-931.742 -190.77162)"/>
        <path d="M939.742,201.02162a2.15732,2.15732,0,0,0-2.21,2.1,2.01344,2.01344,0,0,0,.65,1.49l-0.23,1.96a0.92078,0.92078,0,0,0,.24.72,0.97519,0.97519,0,0,0,.72.31h1.65a0.95928,0.95928,0,0,0,.72-0.31,0.92078,0.92078,0,0,0,.24-0.72l-0.23-1.96a2.01344,2.01344,0,0,0,.65-1.49A2.14216,2.14216,0,0,0,939.742,201.02162Zm0.65,2.94-0.26.18,0.28,2.39h-1.34l0.28-2.39-0.26-.18a1.00105,1.00105,0,0,1-.45-0.84,1.10119,1.10119,0,0,1,2.2,0A1.00105,1.00105,0,0,1,940.392,203.96162Z" transform="translate(-931.742 -190.77162)"/>
    </svg>
);

export const DragIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fillRule="evenodd" d="M8,18 C9.1045695,18 10,18.8954305 10,20 C10,21.1045695 9.1045695,22 8,22 C6.8954305,22 6,21.1045695 6,20 C6,18.8954305 6.8954305,18 8,18 Z M16,18 C17.1045695,18 18,18.8954305 18,20 C18,21.1045695 17.1045695,22 16,22 C14.8954305,22 14,21.1045695 14,20 C14,18.8954305 14.8954305,18 16,18 Z M8,10 C9.1045695,10 10,10.8954305 10,12 C10,13.1045695 9.1045695,14 8,14 C6.8954305,14 6,13.1045695 6,12 C6,10.8954305 6.8954305,10 8,10 Z M16,10 C17.1045695,10 18,10.8954305 18,12 C18,13.1045695 17.1045695,14 16,14 C14.8954305,14 14,13.1045695 14,12 C14,10.8954305 14.8954305,10 16,10 Z M8,2 C9.1045695,2 10,2.8954305 10,4 C10,5.1045695 9.1045695,6 8,6 C6.8954305,6 6,5.1045695 6,4 C6,2.8954305 6.8954305,2 8,2 Z M16,2 C17.1045695,2 18,2.8954305 18,4 C18,5.1045695 17.1045695,6 16,6 C14.8954305,6 14,5.1045695 14,4 C14,2.8954305 14.8954305,2 16,2 Z"/>
    </svg>
);

export const FolderIcon = () => (
    <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="folder"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
    >
        <path
            fill="#404040"
            d="M880 298.4H521L403.7 186.2a8.15 8.15 0 0 0-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32zM840 768H184V256h188.5l119.6 114.4H840V768z"
        ></path>
        <path fill="#c0d576" d="M372.5 256H184v512h656V370.4H492.1z"></path>
    </svg>
);
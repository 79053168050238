import React from 'react';
// ANTD
import { Select } from 'antd';

import { FormattedMessage } from 'react-intl';
import { listFilter } from '../../../../constants/filter.constant';
import { pageScope } from '../../../../constants/mapConstants';

// CONST
const { Option } = Select;

const hasDistance = (scope) =>
  scope === pageScope.tour ||
  scope === pageScope.track ||
  scope === pageScope.index;

const SortComponent = ({ handleChange, scope, filterType }) => {
  const onSearch = (text) => {
    console.log(text);
  };

  return (
    <>
      <div className="sort">
        <Select
          value={filterType}
          size="small"
          dropdownClassName="selectSortDrop"
          dropdownMatchSelectWidth={false}
          className="sortSelect"
          onChange={handleChange}
          showSearch
          onSearch={onSearch}
        >
          {scope !== pageScope.track && (
            <Option value={listFilter.RATING}>
              <FormattedMessage
                id="Most popular"
                defaultMessage="Most popular"
              />
            </Option>
          )}
          <Option value={listFilter.NEW}>
            <FormattedMessage
              id="From new to old ones"
              defaultMessage="From new to old ones"
            />
          </Option>
          <Option value={listFilter.OLD}>
            <FormattedMessage
              id="From old to new ones"
              defaultMessage="From old to new ones"
            />
          </Option>
          <Option value={listFilter.AZ}>
            <FormattedMessage id="From A to Z" defaultMessage="From A to Z" />
          </Option>
          <Option value={listFilter.ZA}>
            <FormattedMessage id="From Z to A" defaultMessage="From Z to A" />
          </Option>
          {hasDistance(scope) && (
            <Option value={listFilter.SHORT}>
              <FormattedMessage
                id="From short to long"
                defaultMessage="From short to long"
              />
            </Option>
          )}
          {hasDistance(scope) && (
            <Option value={listFilter.LONG}>
              <FormattedMessage
                id="From long to short"
                defaultMessage="From long to short"
              />
            </Option>
          )}
        </Select>
      </div>
    </>
  );
};
export default SortComponent;

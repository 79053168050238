import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input } from 'antd';
import withTranslations from 'app/components/HOGs/withTranslations';

import parse from 'html-react-parser';
import { ProfileInfoIcon } from '../../UserProfile/icons.svg';

const AboutClub = ({ tr, form }) => {
  const edit = useSelector((store) => store.clubFullDataReducer.isEditMode);

  const description = useSelector(
    (store) => store.clubFullDataReducer.data.description
  );

  useEffect(() => {
    if (edit && form && description)
      form.setFieldsValue({ about: description });
  }, [description, edit]);

  const getContent = () => {
    if (edit) {
      return (
        <Form.Item
          name="about"
          rules={[
            {
              required: true,
              message: tr['Required fields are not filled in'],
            },
          ]}
        >
          <Input.TextArea
            placeholder={`${tr['Write facts about the bike club']}*`}
          />
        </Form.Item>
      );
    }
    if (description) {
      return parse(description);
    }
    return tr['No info yet'];
  };

  return (
    <div className="about_layout">
      <div className="icon">
        <ProfileInfoIcon />
      </div>
      <div className="text">{getContent()}</div>
    </div>
  );
};

export default withTranslations(AboutClub);

import React from 'react';

import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import parse from 'html-react-parser';

const Members = ({ divider }) => {
  const members = useSelector(
    (store) => store.clubFullDataReducer.data.members
  );

  const adminMembers = Array.isArray(members)
    ? members
        .filter((member) => member.role_id !== 0 && member.role_id !== 6)
        .sort((a, b) => a.role_id - b.role_id)
    : [];

  return adminMembers.length > 0 ? (
    <>
      <div className="role_layout">
        <div className="role_list">
          {adminMembers.map((member) => {
            return (
              <div key={member.id} className="role_item">
                <span className="role_item_title">{`${member.role}:`}</span>
                <span className="role_item_user">
                  <Link to={`${member.url}`}>{parse(member.name)}</Link>
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {divider}
    </>
  ) : null;
};

export default Members;

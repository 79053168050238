import PropTypes from 'prop-types';

// MAP DATA => data
export const MapDataDataProps = {
  distance: PropTypes.number,
  download: PropTypes.number,
  duration: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  owner: PropTypes.number,
  pdf: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  view: PropTypes.number,
};

// MAP DATA
export const MapDataProps = {
  data: PropTypes.arrayOf(PropTypes.shape(MapDataDataProps)),
  error: PropTypes.any,
  fetched: PropTypes.bool,
  fetching: PropTypes.bool,
};

import * as types from '../action_types';

export const setTypeMouseCursor = (data) => {
  return (dispatch) => {
    dispatch({
      type: types.MOUSE_CURSOR_ICON_TYPE,
      payload: data,
    });
  };
};

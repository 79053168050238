import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Button, Input, Popconfirm } from 'antd';

import { FormattedMessage, useIntl } from 'react-intl';
import { clubAPI } from '../../../../API/club.api';

const Subdomain = ({ clubData }) => {
  const intl = useIntl();
  const { active } = useSelector((store) => store.languageData);
  const [inputClass, setInputClass] = useState('');
  const [input, setInput] = useState('');
  const [name, setName] = useState('');
  const [isNameExist, setIsNameExist] = useState(false);
  const [confVisible, setconfVisible] = useState(false);
  useEffect(() => {
    if (clubData && clubData.name) {
      setIsNameExist(clubData.name.length > 0);
      setName(clubData.name);
    }
  }, [clubData]);

  const isExistNameTitle = intl.formatMessage({
    id: 'Congratulation with the below mentioned name to your bike club. Tourstart thank you for using this service, and we hope you will be satisfied. Should you have any suggestions for improvements of Tourstart, just let us know on info@tourstart.org.',
    defaultMessage:
      'Congratulation with the below mentioned name to your bike club. Tourstart thank you for using this service, and we hope you will be satisfied. Should you have any suggestions for improvements of Tourstart, just let us know on info@tourstart.org.',
  });
  const isNotExitNameTitle = intl.formatMessage({
    id: 'We offer your bike club a unique sub-domain name. With the sub domain it is easier for your members and other interested bikers to find the web page of your bike club. Further, the sub domain is more personalized. Should you like to have your own sub domain name, just check if the domain is available in below form. This service is free of charge.',
    defaultMessage:
      'We offer your bike club a unique sub-domain name. With the sub domain it is easier for your members and other interested bikers to find the web page of your bike club. Further, the sub domain is more personalized. Should you like to have your own sub domain name, just check if the domain is available in below form. This service is free of charge.',
  });
  const confirmTitle = intl.formatMessage({
    id: 'You are now in the progress to select a unique domain name for your bike club, which cannot be changed later',
    defaultMessage:
      'You are now in the progress to select a unique domain name for your bike club, which cannot be changed later',
  });
  const DE = 'de';

  const checkSub = () => {
    if (clubData) {
      clubAPI.checkSubdomain(input, clubData.id).then((res) => {
        const { code } = res;
        if (code === 200) {
          setInputClass('success');
        } else {
          setInputClass('error');
        }
      });
    }
  };
  const setSub = () => {
    if (clubData) {
      clubAPI.setSubdomain(input, clubData.id).then((res) => {
        const { code, response } = res;
        if (code === 200) {
          setName(response.domain);
          setIsNameExist(true);
        } else {
          setInputClass('error');
        }
      });
    }
  };

  const cancel = () => {
    setconfVisible(false);
  };

  const setClick = () => {
    if (clubData) {
      clubAPI.checkSubdomain(input, clubData.id).then((res) => {
        const { code } = res;
        if (code === 200) {
          setconfVisible(true);
          setInputClass('success');
        } else {
          setInputClass('error');
        }
      });
    }
  };
  return (
    <div className="subdomain_layout">
      <div className="title">
        {isNameExist ? isExistNameTitle : isNotExitNameTitle}
      </div>
      <div className="input">
        {isNameExist ? (
          <span className="fullSubDomain">{`http://${name}.tourstart.org`}</span>
        ) : (
          <>
            <Input
              placeholder={intl.formatMessage({
                id: 'Type in you desired domain name',
                defaultMessage: 'Type in you desired domain name',
              })}
              value={input}
              className={inputClass}
              onChange={(e) => setInput(e.target.value)}
              onFocus={() => setInputClass('')}
            />
            <span>.tourstart.org</span>
          </>
        )}
      </div>
      {isNameExist ? null : (
        <div
          className="btn"
          style={
            active === DE
              ? {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <Button onClick={() => checkSub()}>
            <span>
              <FormattedMessage
                id="Select domain"
                defaultMessage="Select domain"
              />
            </span>
          </Button>
          <Popconfirm
            title={confirmTitle}
            onConfirm={setSub}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
            icon={null}
            overlayClassName="confirmSubdomain"
            placement="topLeft"
            visible={confVisible}
          >
            <Button
              onClick={() => setClick()}
              style={active === DE ? { marginTop: 10 } : {}}
            >
              <span>
                <FormattedMessage id="Set domain" defaultMessage="Set domain" />
              </span>
            </Button>
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default Subdomain;

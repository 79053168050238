const axios = require('axios');

const url = `${process.env.API_URL}/api`;

/*  Actual source */
let sourcePoly = axios.CancelToken.source();
let sourceData = axios.CancelToken.source();

const updateSourceData = () => {
  if (sourceData) sourceData.cancel('Loading data is not actual yet');
  sourceData = axios.CancelToken.source();
  return sourceData.token;
};

const updateSourcePoly = () => {
  if (sourcePoly) sourcePoly.cancel('Loading poly is not actual yet');
  sourcePoly = axios.CancelToken.source();
  return sourcePoly.token;
};

function getData(scope, objId) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    cancelToken: updateSourceData(),
  };

  return axios(`${url}/${scope}.preview?id=${objId}&scope=web`, requestOptions);
}

function getPoly(scope, objId) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    cancelToken: updateSourcePoly(),
  };

  return axios(
    `${url}/${scope}.getAproxData?with_start=1&id=${objId}`,
    requestOptions
  );
}

function clearRequests() {
  if (sourceData) sourceData.cancel('Loading data is not actual yet');
  if (sourcePoly) sourcePoly.cancel('Loading data is not actual yet');
}

export const previewAPI = {
  getData,
  getPoly,
  clearRequests,
};

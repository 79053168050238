import React from 'react';
//
import { useSelector } from 'react-redux';

// ANTD
import { Card, Button, Tooltip, Modal } from 'antd';
// UTILITY
import { LengthType } from 'app/constants/user.constants';
import { changeTourMode } from 'actions/Map/tour.full.data.action';
import store, { history } from 'app/store';
import { FormattedMessage, useIntl } from 'react-intl';

import moment from 'moment';
import { applyingTypes } from 'app/constants/folder.constants';
import { addedDreamFolder } from 'app/components/Utility/checkDreamFolders';
import {
  getTourLink,
  ConvertDistance,
  // isImageExists,
  // getImgBig,
} from '../../../Utility/utility';

import { ToursIcon } from '../../MainMenu/svg';

import { isTourPublic } from '../../../../constants/tourAccessibilityTypes.constants';
import * as types from '../../../../actions/action_types';
import CountryFlag from '../countryFlag';
import { ErrorBoundary } from '../../../Utility/errorBoundary';

import {
  PrivateTourIcon,
  DeleteIcon,
  EditIcon,
} from '../../TourFullView/components/svg';
import { pageScope, URL } from '../../../../constants/mapConstants';
import ItemTypeListLayout from '../listComponents/listItemLayout';

import pageCache from '../../../../API/cache.service';
import { deleteTourDay } from '../../../Utility/deleteTour';
import { previewAPI } from '../../../../API/preview.api';
import { StarFilledIcon } from '../../TourstartAcademy/academy.svg';
import FolderMenuButton from '../../Folders/FolderMenu';
import { getContainingTourFolders } from '../../Folders/folder.utils';

const { confirm } = Modal;

const TYPE_BLOCK = 'block';

const EDIT = 'edit';
const DELETE = 'delete';
const FOLDER = 'folder';
const CLICK = 'click';

let delay = null;
let actions = false;

export const AccessType = (item, style = {}) => {
  const { type } = item;
  return (
    !isTourPublic(type) && (
      <Tooltip
        title={<FormattedMessage id="Private" defaultMessage="Private" />}
      >
        <div style={style}>
          <PrivateTourIcon width="18" height="18" />
        </div>
      </Tooltip>
    )
  );
};

export const deleteAction = (item, intl, removeItemFromList) => {
  const deleteHandler = () => {
    previewAPI.clearRequests();
    removeItemFromList(item.id);
    deleteTourDay(null, item, null);
    store.dispatch((dispatch) => {
      dispatch({
        type: types.DELETE_MAP_ITEM,
        payload: item,
      });
      dispatch({ type: types.FETCH_PREVIEW_DATA_CLEANE });
      dispatch({ type: types.FETCH_PREVIEW_POLYLINE_CLEANE });
      pageCache.destroy(URL.TOUR.MY[0]);
    });
  };

  confirm({
    title: intl.formatMessage({
      id: 'Delete the tour?',
      defaultMessage: `Delete the tour?`,
    }),
    okText: intl.formatMessage({ id: 'Yes', defaultMessage: 'Yes' }),
    okType: 'danger',
    cancelText: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
    onOk() {
      deleteHandler();
    },
    onCancel() {
      return null;
    },
  });
};

const handleOnClick = (
  e,
  item = {},
  intl,
  removeItemFromList,
  scope = null
) => {
  e.stopPropagation();
  if (actions) {
    e.stopPropagation();

    if (delay) {
      clearTimeout(delay);
    } // Fix bug upload preview polyline after open tour;

    if (scope === EDIT) {
      history.push(getTourLink(item.title, item.id, item.share_key));
      setTimeout(() => {
        store.dispatch(changeTourMode(true));
      }, 1000);
      return;
    }

    if (scope === DELETE) {
      deleteAction(item, intl, removeItemFromList);
      return;
    }

    if (scope === FOLDER) {
      return;
    }

    if (scope === CLICK) {
      history.push(getTourLink(item.title, item.id, item.share_key));
    }
  }
};

const scope = pageScope.tour;

const handleMouseEnter = (props) => {
  const { item, data, previewJson } = props;

  if (delay) {
    clearTimeout(delay);
  }
  const tourList = data;
  const tourId = item.id;
  const hiperPrevTourData =
    tourList[tourList.findIndex((t) => t.id === tourId)];

  delay = setTimeout(() => {
    const prevTour = previewJson;
    if (!prevTour || prevTour.id !== tourId) {
      store.dispatch((dispatch) => {
        dispatch({
          type: types.FETCH_PREVIEW_DATA_HIPERSET,
          payload: { ...hiperPrevTourData, scope },
        });
        dispatch({
          type: types.FETCH_PREVIEW_DATA,
          payload: previewAPI.getData(scope, tourId).then((resp) => {
            if (!resp.data.response.image && hiperPrevTourData.image) {
              resp.data.response.image = hiperPrevTourData.image;
            }
            return { ...resp.data.response, scope };
          }),
        });
        dispatch({
          type: types.FETCH_PREVIEW_POLYLINE,
          payload: previewAPI.getPoly(scope, tourId).then((resp) => {
            const result = resp.data.response;
            return result;
          }),
        });
      });
    }
  }, 500);
};

const handleMouseLeave = () => {
  if (delay) {
    clearTimeout(delay);
  }
};

const blockItem = (props, getDistAndDuration, intl, item) => {
  // const imgStyle = isImageExists(item) ? {} : { objectPosition: 'bottom' };
  return (
    <Card
      bordered
      onClick={(e) => handleOnClick(e, item, intl, null, CLICK)}
      onMouseEnter={() => handleMouseEnter(props)}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ position: 'absolute', top: 150, right: 5 }}
        className="typeSvg"
      >
        <ToursIcon />
      </div>

      <div className="ListBlockImg">
        <img src={item.map_tour_image} className="route" alt="" />
      </div>
      {item.real_country && (
        <ErrorBoundary errorView={<div style={{ display: 'none' }} />}>
          <CountryFlag country={item.real_country} />
        </ErrorBoundary>
      )}

      {item.star ? (
        <StarFilledIcon width="22px" height="22px" className="list_star" />
      ) : null}

      <div className="preview-card-info">
        <div
          className="preview-card-title"
          style={{
            fontSize: '14px',
            color: '#aa2493',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {item.title}
        </div>
        {getDistAndDuration()}
      </div>
    </Card>
  );
};

const hasUserPermition = (user, item) =>
  user.id && item.owner && user.id === item.owner;

const ListTourItem = (props) => {
  const { item, removeItemFromList, type } = props;
  const intl = useIntl();
  const { user, isLoggedIn } = useSelector(
    ({ authentication }) => authentication
  );

  const tourFolders = useSelector(({ folders: folder }) => folder);
  const folders = isLoggedIn
    ? addedDreamFolder(tourFolders, user.id)
    : tourFolders;
  const selectedFolders = getContainingTourFolders(folders, item.id);

  const getDistAndDuration = () => (
    <div
      className="preview-card-small-info"
      style={{
        alignItems: 'center',
        fontSize: '12px',
        color: 'gray',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        lineHeight: '24px',
      }}
    >
      <ConvertDistance
        distance={item.distance}
        duration={item.duration}
        type={user.units ? user.units : LengthType.KM}
      />
    </div>
  );

  const getDateEdit = () => (
    <div className="edit">
      {item?.created_at && item?.created_at === item.updated_at
        ? `${intl.formatMessage({
            id: 'Created',
            defaultMessage: 'Created',
          })} ${moment(item.created_at).format('DD.MM.YYYY HH:MM')}`
        : `${intl.formatMessage({
            id: 'Edited',
            defaultMessage: 'Edited',
          })} ${moment(item.updated_at).format('DD.MM.YYYY HH:MM')}`}
    </div>
  );

  const folderMenuAction = (
    <div
      className="list_item_action_buttons"
      onMouseEnter={() => {
        clearTimeout(delay);
        console.log(297);
        actions = true;
      }}
      onMouseLeave={(e) => {
        selectItem(e.relatedTarget.parentNode);
        actions = false;
      }}
    >
      <FolderMenuButton
        tourId={item.id}
        folders={folders}
        selectedFolders={selectedFolders}
        applying={applyingTypes.TOUR_FOLDER}
      />
    </div>
  );

  const selectItem = (target) => {
    const isTargetItem =
      target.classList.contains('list_item_layout') ||
      target.parentNode.classList.contains('list_item_layout');

    if (isTargetItem) {
      handleMouseEnter(props);
    }
  };

  const getAction = () => (
    <div
      className="list_item_action_buttons"
      onMouseEnter={() => {
        clearTimeout(delay);
        actions = true;
      }}
      onMouseLeave={(e) => {
        selectItem(e.relatedTarget.parentNode);
        actions = false;
      }}
    >
      <div
        onClick={(e) => handleOnClick(e, item, intl, null, FOLDER)}
        aria-hidden
      >
        <FolderMenuButton
          tourId={item.id}
          folders={folders}
          selectedFolders={selectedFolders}
          applying={applyingTypes.TOUR_FOLDER}
        />
      </div>

      <Tooltip title={<FormattedMessage id="Edit" defaultMessage="Edit" />}>
        <Button
          className="list_action_button editTour"
          onClick={(e) => handleOnClick(e, item, intl, null, EDIT)}
        >
          <EditIcon style={{ width: 16, height: 16 }} />
        </Button>
      </Tooltip>
      <Tooltip title={<FormattedMessage id="Delete" defaultMessage="Delete" />}>
        <Button
          className="list_action_button deleteTour"
          onClick={(e) => {
            handleOnClick(e, item, intl, removeItemFromList, DELETE);
          }}
        >
          <DeleteIcon style={{ width: 16, height: 16 }} />
        </Button>
      </Tooltip>
      {!isTourPublic(item.type) && (
        <Tooltip
          title={<FormattedMessage id="Private" defaultMessage="Private" />}
        >
          <Button style={{ paddingTop: 2 }} className="list_action_button">
            <PrivateTourIcon width="16" height="16" />
          </Button>
        </Tooltip>
      )}
    </div>
  );

  return type === TYPE_BLOCK ? (
    blockItem(props, getDistAndDuration, intl, item)
  ) : (
    <ItemTypeListLayout
      item={item}
      type="tour"
      title={item.title}
      star={item.star}
      iconType={<ToursIcon />}
      meas={getDistAndDuration()}
      edit={item.updated_at ? getDateEdit() : null}
      action={hasUserPermition(user, item) ? getAction() : folderMenuAction}
      handleMouseEnter={() => handleMouseEnter(props)}
      handleMouseLeave={() => handleMouseLeave()}
      handleOnClick={(e) => {
        handleOnClick(e, item, intl, removeItemFromList, CLICK);
      }}
    />
  );
  // <ListItem item={props.item} props={props} accessType={AccessType} />
};

export default ListTourItem;

import store from 'app/store';
import * as types from 'actions/action_types';
import {
  tourOpenedMultiPartAction,
  tourSetActiveMultiPartAction,
} from 'actions/FullTour/full_tour_view.action';

import pageCache from '../../API/cache.service';
import { URL } from '../../constants/mapConstants';
import tourPrevAPI from '../../API/tour.prev.api';
import { updateFolders } from '../../actions/Menu/folders.actions';
import { setMyTours } from '../../actions/Menu/counter.action';

const updatePublicTourList = (id) => {
  const keys = [URL.TOUR.PUBLIC[0], URL.TOUR.MY[0], URL.TOUR.FRIENDS[0]];
  keys.forEach((key) => {
    if (pageCache.has(key)) {
      let tours = pageCache.get(key).slice();
      const index = tours.findIndex((tour) => tour.id === id);
      tours.splice(index, 1);
      pageCache.set(key, tours.slice());
      tours = null;
    }
  });
  setTimeout(() => {
    pageCache.destroy(URL.CONTENT.BEST[0]);
  }, 60000);
};

export const deleteTourDay = (url, data, activeTourTab) => {
  const { id, parts, user, multiDay } = data;

  let partId;

  if (parts && parts.length > 0 && +activeTourTab > 0) {
    partId = parts[+activeTourTab - 1].id;
  }

  if (data) {
    if (!activeTourTab || activeTourTab === '0') {
      tourPrevAPI
        .removeTour(id, user)
        .then(({ response }) => {
          const { folders, myToursCount } = response;
          store.dispatch((dispatch) => {
            dispatch(updateFolders(folders));
            dispatch(setMyTours(myToursCount));
          });
        })
        .finally(() => {
          if (url) {
            url = url.join('/');
            if (id) {
              updatePublicTourList(id);
              window.location.href = url;
            } else {
              window.location.href = 'createtour';
            }
          }
        });
    } else if (partId) {
      tourPrevAPI.removeTour(partId, user).then(({ response }) => {
        const { folders, myToursCount } = response;
        store.dispatch((dispatch) => {
          dispatch(updateFolders(folders));
          dispatch(setMyTours(myToursCount));
        });
      });
      store.dispatch((dispatch) => {
        if (multiDay === true && parts.length <= 2) {
          dispatch({
            type: types.TOUR_FULL_DATA_MAKE_MULTIDAYS_TOUR,
            payload: false,
          });
          dispatch(tourOpenedMultiPartAction('0'));
          dispatch(tourSetActiveMultiPartAction(0));
        }
        dispatch({
          type: types.TOUR_FULL_DATA_DELETE_DAY,
          payload: +activeTourTab - 1,
        });
      });
    } else {
      store.dispatch((dispatch) => {
        if (multiDay === true && parts.length <= 2) {
          dispatch({
            type: types.TOUR_FULL_DATA_MAKE_MULTIDAYS_TOUR,
            payload: false,
          });
          dispatch(tourOpenedMultiPartAction('0'));
          dispatch(tourSetActiveMultiPartAction(0));
        }
        dispatch({
          type: types.TOUR_FULL_DATA_DELETE_DAY,
          payload: +activeTourTab - 1,
        });
      });
    }
  }
};

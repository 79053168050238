/* eslint-disable import/no-cycle */
import React from 'react';

// ROUTER
import { Link } from 'react-router-dom';

// ANT DESIGN
import { Button, Modal, Tooltip } from 'antd';

// Component

import { FormattedMessage, useIntl } from 'react-intl';
// CSS
import '../../TourPreview/preview.css';

// IMAGE
import default_logo from 'static/img/Tourstart_org.jpg';

import store from 'app/store';
import * as types from 'actions/action_types';

import { LengthType } from 'app/constants/user.constants';
import CloseButton from '../../../Utility/closeButton';
import { TrackIcon } from '../../MainMenu/svg';
import {
  getTitle,
  addToDownload,
  ConvertDistance,
} from '../../../Utility/utility';

import { handleClick } from '../../List/listTrackItem/listTrackItem';
import PreviewInfoDownloadView from '../../TourPreview/PreviewInfoDownloadView';
import PreviewBodyWrapper from '../../TourPreview/PreviewBodyWrapper';

import { DeleteIcon } from '../../TourFullView/components/svg';

import { trackAPI } from '../../../../API/track.api';
import { previewAPI } from '../../../../API/preview.api';
import { openNotificationWithIcon } from '../../../Utility/notificationMessage';
import withTranslations from 'app/components/HOGs/withTranslations';

const { confirm } = Modal;

const previewListStyle = {
  position: 'relative',
  bottom: 0,
  height: 200,
};

export const deleteTrack = (id, intl) => {
  confirm({
    title: intl.formatMessage({
      id: 'Delete the track?',
      defaultMessage: 'Delete the track?',
    }),
    okText: intl.formatMessage({ id: 'Yes', defaultMessage: 'Yes' }),
    okType: 'danger',
    cancelText: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
    onOk() {
      trackAPI.removeTrack(id).then((res) => {
        if (res.code === 200) {
          previewAPI.clearRequests();
          store.dispatch((dispatch) => {
            dispatch({
              type: types.DELETE_MAP_ITEM_BY_ID,
              payload: id,
            });
            dispatch({ type: types.FETCH_PREVIEW_DATA_CLEANE });
            dispatch({ type: types.FETCH_PREVIEW_POLYLINE_CLEANE });
          });
          openNotificationWithIcon(
            'success',
            intl.formatMessage({ id: 'Success', defaultMessage: 'Success' }),
            3
          );
        }
      });
    },
    onCancel() {
      return null;
    },
  });
};

export const previewBody = (props) => {
  const title = getTitle(props.previewJson);

  const intl = useIntl();

  const renderProps = () => {
    return (
      <div className="middle-buttons-wrapper">
        <Tooltip
          title={<FormattedMessage id="Delete" defaultMessage="Delete" />}
        >
          <Button
            size="large"
            className="preview-tour-buttons"
            onClick={() => {
              confirm({
                title: intl.formatMessage({
                  id: 'Delete the track?',
                  defaultMessage: 'Delete the track?',
                }),
                okText: intl.formatMessage({
                  id: 'Yes',
                  defaultMessage: 'Yes',
                }),
                okType: 'danger',
                cancelText: intl.formatMessage({
                  id: 'No',
                  defaultMessage: 'No',
                }),
                onOk() {
                  deleteTrack(props.previewJson.id, intl);
                },
                onCancel() {
                  return null;
                },
              });
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className="tourPreviewBody">
      <div className="tourPreviewBodyImgBig" style={{ width: 177 }}>
        <div className="mainImg">
          <img
            src={default_logo}
            alt=""
            draggable="false"
            style={{ height: '100%' }}
          />
        </div>
      </div>
      <div className="tourPreviewBodyMainWrapper">
        <div
          className="entityPreviewBodyContentTitle"
          style={props.fromList ? { cursor: 'pointer' } : {}}
          onClick={() => {
            if (props.fromList) {
              handleClick(props.previewJson, props);
            }
          }}
          aria-hidden
        >
          <span>{title}</span>
        </div>
        <ConvertDistance
          type={props.userAuth ? props.userAuth.units : LengthType.KM}
          distance={props.previewJson.distance}
          duration={props.previewJson.duration}
          className="entityDistanceTimeInfo"
        />
        <PreviewBodyWrapper
          {...props}
          EntityIcon={TrackIcon}
          render={renderProps}
        />
        <div className="entity-bottom-buttons-wrapper">
          <PreviewInfoDownloadView {...props} />
          <Link
            to={`/createtour?copy=${props.previewJson.id}&track=1`}
            className="entity-bottom-button"
          >
            <Button
              className="viewBtn"
              style={{ width: '100%' }}
              onClick={() => {
                props.closePreviewer({});
                props.removeTourFromMap({ id: 0, points: [], poly: '' });
              }}
            >
              <span>
                <FormattedMessage
                  id="Add to Create tour"
                  defaultMessage="Add to Create tour"
                />
              </span>
            </Button>
          </Link>
          <Button
            type="primary"
            className="addBtn entity-bottom-button"
            onClick={(e) => {
              addToDownload(
                e,
                props.downloadTourGps.data,
                props.previewJson,
                props.openModalTourDownload,
                props.TourDownloadBasketData,
                props.TourDownloadCoord,
                { tourAdded: props.tr['Already added to download'] },
                'track'
              );
            }}
          >
            <span>
              <FormattedMessage
                id="Add to download"
                defaultMessage="Add to download"
              />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

const TrackPreview = (props) => {
  const info =
    props.fetched === false && props.fetching === false ? (
      <div className="tourPreviewBody">
        <FormattedMessage
          id="TRACK IS NOT AVAILABLE"
          defaultMessage="TRACK IS NOT AVAILABLE"
        />
      </div>
    ) : (
      previewBody(props)
    );

  const header = (
    <div
      style={{
        position: 'absolute',
        right: -5,
        top: -5,
        cursor: 'pointer',
        width: 20,
        height: 20,
        zIndex: 5,
      }}
      onClick={() => {
        props.closePreviewer({});
        props.removeTourFromMap({ id: 0, points: [], poly: '' });
      }}
      aria-hidden
    >
      <CloseButton />
    </div>
  );

  const render =
    Object.keys(props.previewJson).length === 0 ? null : (
      <div
        id="clubPreviewContainer"
        className="clubPreviewContainer"
        style={props.fromList ? { height: '100%' } : {}}
      >
        <div
          className="clubPreviewLayout"
          style={props.fromList ? previewListStyle : {}}
        >
          {props.fromList ? null : header}
          {info}
        </div>
      </div>
    );
  return render;
};

export default withTranslations(TrackPreview);

import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'antd';
import {
  DeleteOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import { checkIfMainDomainAvilableInLink } from './utility';

import '../../styles/modalPreviewPictures.css';

const ModalPreviewPictures = ({
  visible = false,
  setVisible,
  //   picture,
  picturesData,
  index = 0,
  deleteImage,
  isAuthUserPage,
}) => {
  const [inw, setI] = useState(index);
  const [px, setPx] = useState(0);
  const [tran, setTran] = useState(false);

  useEffect(() => setI(index), [index]);

  useEffect(() => {
    return () => {
      setPx(0);
      setTran(false);
    };
  }, []);

  const tRef = useCallback(
    (node) => {
      if (node) setTran(node.clientWidth < picturesData.length * 80);
    },
    [visible]
  );

  useEffect(() => {
    if (tran) {
      setPx(-inw * 75);
    }
  }, [inw]);

  const styleT = {
    height: 80,
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
  };

  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;
    if (keyCode === 39) {
      setI((inw) => {
        //eslint-disable-line
        if (inw === picturesData.length - 1) {
          return 0;
        }
        return inw + 1;
      });
    } else if (keyCode === 37) {
      setI((inw) => {
        //eslint-disable-line
        if (inw === 0) {
          return picturesData.length - 1;
        }
        return inw - 1;
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const setPrevPic = () => {
    if (inw === 0) {
      setI(picturesData.length - 1);
    } else {
      setI(inw - 1);
    }
  };

  const setNextPic = () => {
    if (inw === picturesData.length - 1) {
      setI(0);
    } else {
      setI(inw + 1);
    }
  };

  const getImageUrl = (img = {}) => {
    if (img.prev) {
      return img.prev;
    }
    if (img.source) {
      return `https://img.youtube.com/vi/${img.source}/default.jpg`;
    }
    if (img.src) {
      return img.src;
    }
    if (img.thumbUrl) {
      return img.thumbUrl;
    }
    if (img.fullpath) {
      return img.fullpath !== null && img.fullpath !== undefined && img.fullpath
        ? checkIfMainDomainAvilableInLink(img.fullpath)
        : img.fullpath;
    }
    return img.original !== null && img.original !== undefined && img.original
      ? checkIfMainDomainAvilableInLink(img.original)
      : img.original;
  };

  const getImagesDots = () => {
    return (
      <div style={styleT}>
        <div
          ref={tRef}
          style={{
            transform: `translateX(${px}px)`,
            transition: 'transform 0.45s ease-out',
            whiteSpace: 'nowrap',
            width: '100%',
            height: '100%',
            position: 'absolute',
          }}
        >
          {picturesData.map((img, i) => (
            <img
              onClick={() => setI(i)}
              key={`${img.id || i}img`}
              src={getImageUrl(img)}
              className="pictures"
              style={{
                width: '80px',
                height: '100%',
                objectFit: 'cover',
                marginRight: 5,
                borderRadius: 5,
                border: '2px solid',
                borderColor: i === inw ? '#aa2493' : 'transparent',
              }}
              aria-hidden="true"
              alt="pictures"
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={false}
      visible={visible}
      closable
      footer={false}
      destroyOnClose
      centered
      onCancel={() => setVisible(false)}
      zIndex={1052}
      style={{
        maxWidth: '70%',
        maxHeight: '70%',
        minWidth: 100,
        minHeight: 100,
        height: '70%',
        width: '70%',
      }}
      width={null}
      wrapClassName="PreviewPictures"
      maskClosable
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
      closeIcon={<CloseOutlined style={{ fontSize: '40px', color: '#fff' }} />}
    >
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LeftOutlined
          className="prevImgArrow left"
          onClick={() => setPrevPic()}
        />
        {picturesData && picturesData[inw] && picturesData[inw].source ? (
          <iframe
            title="iframe"
            width="600"
            height="400"
            style={{ borderRadius: 15 }}
            src={`https://www.youtube.com/embed/${picturesData[inw].source}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <img
            src={getImageUrl(picturesData[inw])}
            className="pictures"
            style={{
              minWidth: 100,
              minHeight: 100,
              objectFit: 'cover',
              maxHeight: '70vh',
            }}
            alt="pictures"
          />
        )}
        <RightOutlined
          className="prevImgArrow right"
          onClick={() => setNextPic()}
        />
        {isAuthUserPage ? (
          <DeleteOutlined
            className="delete_btn"
            theme="filled"
            style={{ fontSize: 40 }}
            onClick={(e) => deleteImage(e, picturesData[inw])}
          />
        ) : null}
      </div>

      {getImagesDots()}
    </Modal>
  );
};

export default ModalPreviewPictures;

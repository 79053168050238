import PropTypes from 'prop-types';

//FULL DATA => ... => images 
export const FullDataDataImagesProps = {
    fullpath:               PropTypes.string,
    id:                     PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    path:                   PropTypes.string,
    s:                      PropTypes.string,
    smallpath:              PropTypes.string,
    title:                  PropTypes.string,
}

//FULL DATA => ... => videos
export const FullDataDataVideosProps = {
    id:                     PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    title:                  PropTypes.string,
    embed:                  PropTypes.string,
    s:                      PropTypes.string,
    embed_video_prev:       PropTypes.string,
    videoInfo:              PropTypes.shape({
                                prev:   PropTypes.string,
                                width:  PropTypes.number,
                                height: PropTypes.number,
                            })
}

//FULLDATA => data => user
export const fullDataDataUserProps = {
    id:                     PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    name:                   PropTypes.string,
    sex:                    PropTypes.string,
    avatar:                 PropTypes.oneOfType([
                                PropTypes.string,
                                PropTypes.shape({
                                    s:          PropTypes.string,
                                    path:       PropTypes.string,
                                    smallpath:  PropTypes.string,
                                    fullpath:   PropTypes.string,
                                    title:      PropTypes.string,
                                    id:         PropTypes.string
                                })
                            ])
} 
//FULL DATA => data => parts 
export const fullDataDataParts = {
    allTourDays:            PropTypes.number,
    description:            PropTypes.string,
    distance:               PropTypes.number,
    duration:               PropTypes.number,
    id:                     PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    images:                 PropTypes.arrayOf(PropTypes.shape(FullDataDataImagesProps)),
    videos:                 PropTypes.arrayOf(PropTypes.shape(FullDataDataVideosProps)),
    multiDay:               PropTypes.bool,
    parentId:               PropTypes.number,
    points:                 PropTypes.arrayOf(PropTypes.shape(fullDataDataPointsProps)),
    title:                  PropTypes.string,
}

//FULL DATA => dataPoints
export const fullDataDataPointsProps = {
    address:                PropTypes.string,
    description:            PropTypes.string,
    id:                     PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    images:                 PropTypes.arrayOf(PropTypes.shape(FullDataDataImagesProps)),
    videos:                 PropTypes.arrayOf(PropTypes.shape(FullDataDataVideosProps)),
    lat:                    PropTypes.string,
    lng:                    PropTypes.string,
    s:                      PropTypes.string,
    title:                  PropTypes.string,
    type:                   PropTypes.string,
    url:                    PropTypes.string,
}


//FULL DATA => data
export const fullDataDataProps = {
    id:                     PropTypes.oneOfType([PropTypes.string, PropTypes.number]), 
    title:                  PropTypes.string ,
    lat:                    PropTypes.string ,
    lng:                    PropTypes.string ,
    user:                   PropTypes.shape(fullDataDataUserProps),
    created:                PropTypes.number,
    images:                 PropTypes.arrayOf(PropTypes.shape(FullDataDataImagesProps)),
    videos:                 PropTypes.arrayOf(PropTypes.shape(FullDataDataVideosProps)),
    map_zoom:               PropTypes.number ,
    map_type:               PropTypes.string ,
    link:                   PropTypes.string ,
    shortdescription:       PropTypes.string ,
    description:            PropTypes.string ,
    duration:               PropTypes.number ,
    distance:               PropTypes.number ,
    rating:                 PropTypes.number ,
    tourType:               PropTypes.number ,
    url:                    PropTypes.string ,
    s:                      PropTypes.string ,
    private:                PropTypes.bool   ,
    share_key:              PropTypes.string ,
    bookmark:               PropTypes.bool   ,
    multiDay:               PropTypes.bool   ,
    parts:                  PropTypes.arrayOf(PropTypes.shape(fullDataDataParts)),    
}

//FULL DATA 
export const fullDataProps = {
    data:                   PropTypes.shape(fullDataDataProps),
    dataPoint:              PropTypes.shape(fullDataDataPointsProps),
    fetching:               PropTypes.bool ,
    fetched:                PropTypes.bool ,
    activeTourTab:    PropTypes.string,
    error:                  PropTypes.any,
    id:                     PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
    map:                    PropTypes.shape({
                                lat: PropTypes.number,
                                lng: PropTypes.number
                            }),
}




import React from 'react';
// ANTD
import { Card } from 'antd';
// IMAGE
import default_logo from 'static/img/Tourstart_org.jpg';

import { history } from 'app/store';
import {
  getEntitiyLink,
  decodeSpecLetters,
  isImageExists,
  checkIfMainDomainAvilableInLink,
} from '../../../Utility/utility';
import { ErrorBoundary } from '../../../Utility/errorBoundary';
import { BikerBedIconSearch, BikerClubIconSearch } from '../../MainMenu/svg';
import { pageScope } from '../../../../constants/mapConstants';
import ActionStatus from './getStatus';
import { prevDispatch } from '../../Map/marker_listener_utils';

import ItemTypeListLayout from '../listComponents/listItemLayout';
import CountryFlag from '../countryFlag';

const TYPE_BLOCK = 'block';

let delay = null;
const handleMouseEnter = (props, isBikerBed) => {
  const scope = isBikerBed() ? pageScope.bikerbed : pageScope.club;
  const { item, data, previewJson } = props;
  if (delay) {
    clearTimeout(delay);
  }
  const clubList = data;
  const clubId = item.id;
  const hiperPrevClubData =
    clubList[clubList.findIndex((t) => t.id === clubId)];

  delay = setTimeout(() => {
    const prevEntity = previewJson;
    if (!prevEntity || +prevEntity.id !== +clubId) {
      prevDispatch(scope, clubId, hiperPrevClubData);
    }
  }, 500);
};
const handleMouseLeave = () => {
  if (delay) {
    clearTimeout(delay);
  }
};

const getTypeIcon = (isBikerBed) =>
  isBikerBed() ? <BikerBedIconSearch /> : <BikerClubIconSearch />;

const blockItem = (item, props, scopeMain, isBikerBed, handleOnClick) => {
  const imgStyle = isImageExists(item) ? {} : { objectPosition: 'bottom' };
  return (
    <Card
      bordered
      onClick={(e) => handleOnClick(e)}
      onMouseEnter={() => handleMouseEnter(props, isBikerBed)}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ position: 'absolute', top: 150, right: 4 }}
        className="typeSvg"
      >
        {isBikerBed() ? <BikerBedIconSearch /> : <BikerClubIconSearch />}
      </div>
      <div className="ListBlockImg">
        <img
          style={imgStyle}
          src={
            (item.image && item.image.length > 0) ||
            (item.logo && item.logo.length > 0)
              ? checkIfMainDomainAvilableInLink(item.image || item.logo)
              : default_logo
          }
          alt=""
          draggable="false"
        />
      </div>
      <div className="preview-card-info">
        <div className="actionsBtnListItem">
          <div className="preview-card-title">
            {decodeSpecLetters(item.title)}
          </div>
          <ActionStatus club={item} isBikerBed={isBikerBed} scope={scopeMain} />
          {item.country && (
            <ErrorBoundary errorView={<div style={{ display: 'none' }} />}>
              <CountryFlag country={item.country} />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </Card>
  );
};

const ListClubItem = (props) => {
  const { item, type } = props;

  const scopeMain = item.scope ? item.scope : props.scope;

  const isBikerBed = () => scopeMain === pageScope.bikerbed;

  const handleOnClick = () => {
    clearTimeout(delay);
    history.push(getEntitiyLink(scopeMain, item.title, item.id));
  };

  const mouseEnter = () => handleMouseEnter(props, isBikerBed);
  // return blockItem(item, props);
  return type === TYPE_BLOCK ? (
    blockItem(item, props, scopeMain, isBikerBed, handleOnClick)
  ) : (
    <ItemTypeListLayout
      title={item.title}
      iconType={getTypeIcon(isBikerBed)}
      meas={
        <ActionStatus club={item} isBikerBed={isBikerBed} scope={scopeMain} />
      }
      handleMouseEnter={() => mouseEnter()}
      handleMouseLeave={() => handleMouseLeave()}
      handleOnClick={() => handleOnClick()}
    />
  );
};

export default ListClubItem;

import axios from 'axios';
import store from 'app/store';
import { applyingTypes } from 'app/constants/folder.constants';

const URL = process.env.NODE_BACKEND;
const { API_URL } = process.env;
const url = `${process.env.API_URL}/api`;

export async function createFolder(userId, folder) {
  const data = JSON.stringify(folder);
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    data,
  };
  const res = await axios(`${URL}/folders/${userId}`, requestOptions);
  return res;
}

export async function getFolders(userId) {
  const requestOptions = {
    method: 'GET',
  };

  const res = await axios(`${URL}/folders/${userId}`, requestOptions);
  return res;
}

export async function deleteFolder(folderId) {
  const data = JSON.stringify({ id: folderId });
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method: 'DELETE',
    data,
  };
  const res = await axios(`${URL}/folders/deleteFolder`, requestOptions);
  return res;
}

export async function renameFolder(folderId, newFolderTitle) {
  const data = JSON.stringify({
    id: folderId,
    title: newFolderTitle,
  });
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    data,
  };

  const res = await axios(`${URL}/folders/renameFolder`, requestOptions);
  return res;
}

export async function getTours(folderId, userId, applying) {
  const requestOptions = {
    method: 'GET',
  };

  const res = await axios(
    `${API_URL}/api/tour.getToursFromFolder?id=${folderId}&user_id=${userId}&applying=${applying}`,
    requestOptions
  );
  return res;
}

export async function addTourToFolder(folderId, tours) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    data: {
      id: folderId,
      tours,
    },
  };

  const res = await axios(`${URL}/folders/addTours`, requestOptions);
  return res;
}

export async function removeTourFromFolder(folderId, tours) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    data: {
      id: folderId,
      tours,
    },
  };

  const res = await axios(`${URL}/folders/removeTours`, requestOptions);
  return res;
}

export async function removeTourFromAllFolders(userId, tourId) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    data: {
      userId,
      tourId,
    },
  };

  const res = await axios(`${URL}/folders/removeTourFromAll`, requestOptions);
  return res;
}

export async function saveFolderOrder(applying) {
  const foldersQueue =
    +applying === applyingTypes.PLACE_FOLDER
      ? store
          .getState()
          .foldersPlace.map(({ id, order_id }) => ({ id, order_id }))
      : store.getState().folders.map(({ id, order_id }) => ({ id, order_id }));

  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    data: {
      folders: foldersQueue,
    },
  };

  const res = await axios(`${URL}/folders/saveFolderOrder`, requestOptions);
  return res;
}

export async function fetchDreamTour() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  const res = await axios(
    `${url}/list.getWebDreamToursFolders`,
    requestOptions
  );
  return res;
}

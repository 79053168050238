import React from "react";

//PROP TYPES
import PropTypes from "prop-types";
import { history } from "app/store";

import { Button, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import "./members.css";
import "../List/listMobile.css";

import moment from "moment";

import { MemberIcon } from "../../Desctop/MainMenu/svg";

import SearchHeaders from "../searchHeaders/searchheaders";

import { content } from "../../Desctop/Members/members";

import avatar from "../../../../static/img/avatar.jpg";

import { decodeSpecLetters } from "../../Utility/utility";

export default class MembersMobile extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const itemRender = (obj) => {
      return (
        <div className="mobileItemLayout" onClick={() => history.push(obj.url)}>
          <div className="mainImage">
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "4px 0px 0px 4px",
              }}
              src={
                obj.photo && obj.photo !== ""
                  ? process.env.BACKEND_URL + obj.photo
                  : avatar
              }
              alt=""
            />
          </div>
          <div className="mainInfo">
            <div className="icon">
              <MemberIcon />
            </div>
            <div className="title" style={{ color: "#aa2493" }}>
              {decodeSpecLetters(obj.name)}
            </div>
            <div className="desc member">
                {this.props.admin ? (
                  <div
                    style={{
                      fontSize: "12px",
                      color: "gray",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                      height: 21,
                    }}
                  >
                    {obj.email}
                  </div>
                ) : null}
                <div
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: 21,
                    maxWidth: "100%",
                  }}
                >
                  <FormattedMessage
                    id="Member since"
                    defaultMessage="Member since"
                  />
                  : &nbsp;
                  {moment(obj.date).format("MMM D, YYYY")}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: 21,
                    maxWidth: "100%",
                  }}
                >
                  {obj.country}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: 21,
                    maxWidth: "100%",
                  }}
                >
                  {obj.bike_name ? obj.bike_name : ""}
                </div>
            </div>
            <div className="footerItem">
              <div />
              <Button
                size="small"
                type="primary"
                onClick={() => history.push(obj.url)}
              >
                <FormattedMessage id="Profile" defaultMessage="Profile" />
              </Button>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          zIndex: 500,
          backgroundColor: "#fff",
        }}
        className="tourListMobileLayout"
      >
        <SearchHeaders onChangeSearch={this.props.onChangeSearch} />
        {content(this.props, itemRender)}
      </div>
    );
  }
}
MembersMobile.propTypes = {
  admin: PropTypes.bool,
};

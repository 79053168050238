import React from 'react';

import { useSelector } from 'react-redux';

import { Button, Modal } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { history } from 'app/store';

import withTranslations from 'app/components/HOGs/withTranslations';

import pageDataAPI from 'app/API/pageData.api';

import { clubAPI } from 'app/API/club.api';

import { openNotificationWithIcon } from 'app/components/Utility/notificationMessage';

export const urlTitle = (title) => {
  if (title) {
    return title.replace(/[^\w\s]/gi, '').replace(/ /g, '_');
  }
  return '';
};

const SaveClub = (props) => {
  const { tr, page } = props;

  const clubData = useSelector((store) => store.clubFullDataReducer.data);

  const discardChangesAction = () => {
    Modal.confirm({
      title: tr['Do you want to discard changes?'],
      onOk() {
        history.push(`/club/${urlTitle(clubData.title)}_${clubData.id}`);
      },
    });
  };

  const deleteClub = () => {
    Modal.confirm({
      title: tr[`Bike Club Deletion`],
      icon: <ExclamationCircleOutlined />,
      content:
        tr[
          `You are about to delete your bike club. All data about your Bike Club will be deleted.`
        ],
      onOk() {
        if (clubData.id) {
          clubAPI
            .deleteClub(clubData.id)
            .then((res) => {
              const { code } = res;
              if (code === 200) {
                openNotificationWithIcon('success', tr.Success);
                pageDataAPI.clearClubs();
                history.push(`/club`);
              }
            })
            .catch((err) => console.log(err));
        }
      },
      onCancel() {},
    });
  };

  return (
    <div className="createBtn">
      {page === 'edit' ? (
        <>
          <div className="change_btn">
            <Button size="large" onClick={() => discardChangesAction()}>
              <span>{tr['Discard changes']}</span>
            </Button>
            <Button type="primary" size="large" htmlType="submit">
              <span>{tr['Save changes']}</span>
            </Button>
          </div>
          <div className="delete_btn">
            <Button
              type="primary"
              danger
              onClick={deleteClub}
              className="club_delete_btn"
              size="large"
            >
              {tr[`Delete bike club ...`]}
            </Button>
          </div>
        </>
      ) : (
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="create_btn"
        >
          <span>{tr.Create}</span>
        </Button>
      )}
    </div>
  );
};

export default withTranslations(SaveClub);

import { useState, useEffect } from 'react';

export const isMobile = (compareWidth = 750) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width < compareWidth;
};

export const isHeightFull = (compareHeight = 770) => {
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return height > compareHeight;
};

import translationEN from '../static/locales/en/translation.json';
import translationRU from '../static/locales/ru/translation.json';
import translationDA from '../static/locales/da/translation.json';
import translationDE from '../static/locales/de/translation.json';
import translationNO from '../static/locales/no/translation.json';
import translationDU from '../static/locales/du/translation.json';
import translationFI from '../static/locales/fi/translation.json';

export const translation = {
  en: translationEN,
  ru: translationRU,
  da: translationDA,
  de: translationDE,
  no: translationNO,
  du: translationDU,
  fi: translationFI,
};

import React, { Component } from 'react';

// STORE
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobileOnly, isBrowser } from 'react-device-detect';

import withTranslations from 'app/components/HOGs/withTranslations';
import store from '../../../store';
// TYPES ACTION
import * as types from '../../../actions/action_types';

import { API } from '../../../API/manager';
// REDUX

// ACTION
import {
  tourFullDataId,
  tourFullMapPosition,
} from '../../../actions/FullTour/full_tour_view.action';
import {
  openModalTourDownload,
  TourDownloadBasketData,
  TourDownloadCoord,
} from '../../../actions/TourDownload/tourDownloadGps.action';
import { setPreviewTourData } from '../../../actions/Map/preview-marker-data.action';
// CHECK DEVICES
// MODULE
import ListTour from './list';

// COMPONENT MOBILE
import TourListMobile from '../../Mobile/List/listMobile';

// Utility
import { getImgBig, getTitle, getDescription } from '../../Utility/utility';

// SVG
import { BookmarkIcon, BookmarkShapeIcon } from '../MainMenu/svg';
// Image
import file from '../../../../static/img/file.png';

import { pageScope } from '../../../constants/mapConstants';
import { getSortFunction } from './sortFunctions';
import userAPI from '../../../API/user.service.api';
import { setHistoryScope } from '../../../actions/Users/user.history.action';

const DISPLAY_NONE = 'none';
const DISPLAY_BLOCK = 'flex';
const TYPE_BLOCK = 'block';
const TYPE_LIST = 'list';
const ICON = 'icon';
const SELECT_ICON = 'selectIcon';

const SCOPE = 'LIST_SORT_SCOPE';

const scrollTop = 0;
const state = {
  display: DISPLAY_NONE,
  type: TYPE_LIST,
  classList: [ICON, SELECT_ICON],
  classBlock: [ICON],
  distan: 'km',
  width: window.innerWidth,
  items: 34,
  loadingState: false,
  readMore: true,
  listDataTour: [],
  itemsDisplay: [],
  filterType: 'new',
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = state;
    this.iScroll = React.createRef();
    // this.selectedTour = React.createRef();
    this.selectedEntity = React.createRef();
  }

  componentDidMount() {
    const isTourFolder =
      this.props.router.location.pathname.match(/\/tour\/folders\//);

    if (!isTourFolder) {
      this.filterListByMapBounds();
    } else {
      this.setListData(this.props.data);
    }
    const { width } = this.state;
    this.dragImg = new Image(0, 0);
    this.dragImg.src = file;
    if (isBrowser && width > 768) {
      this.iScroll.current.addEventListener('scroll', () => {
        if (
          this.iScroll.current.scrollTop + this.iScroll.current.clientHeight >=
          this.iScroll.current.scrollHeight
        ) {
          this.loadMoreItems();
        }
      });
    }
    if (
      scrollTop !== 0 &&
      this.props.router.location.state &&
      this.props.router.location.state.fromFullView
    ) {
      this.iScroll.current.scrollTo(0, scrollTop);
    }
    if (Object.keys(this.props.resp.data).length !== 0) {
      this.setState({
        display: DISPLAY_BLOCK,
      });
    } else {
      this.setState({
        display: DISPLAY_NONE,
      });
    }
    if (this.state.type === TYPE_LIST) {
      this.setState({
        display: DISPLAY_NONE,
      });
    }
    if (
      this.props.router.location.state &&
      this.props.router.location.state.fromFullView
    ) {
      this.setState({
        display: DISPLAY_NONE,
      });
    }
    if (this.props.mapData.fetching === true) {
      this.setState({
        loadingState: true,
      });
    }
    if (this.props.historyData[SCOPE]) {
      this.setState({
        filterType: this.props.historyData[SCOPE],
      });
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  getExtDataTour = (items) => {
    const itemsId = [];
    items.forEach((i) => {
      itemsId.push(i.id);
    });
  };

  // Infinity Scroll
  displayItems = () => {
    const { listDataTour } = this.state;
    const items = [];
    if (items.length < listDataTour.length) {
      items.push(listDataTour.slice(items.length, this.state.items));
      this.getExtDataTour(items[0]);
    } else {
      return [];
    }

    return items[0];
  };

  loadMoreItems() {
    const data = this.displayItems(this.state.listDataTour);
    if (data && data.length < this.props.data.length) {
      this.setState((state) => {
        return {
          items: state.items + 8,
          loadingState: false,
        };
      });
    } else {
      this.setState({ loadingState: false });
    }
  } //

  handleChangeFilter = (filterType) => {
    const { user, setHistoryScope } = this.props;
    this.setState({ filterType });
    if (user && user.id) {
      setHistoryScope(SCOPE, filterType);
      userAPI.setHistorySelectedData(user.id, SCOPE, filterType);
    }
  };

  filterListByFilter = () => {
    this.setState((state) => ({
      listDataTour: [...state.listDataTour].sort(
        getSortFunction(state.filterType)
      ),
    }));
  };

  filterListByMapBounds = () => {
    const bounds = this.props.boundsUrl.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
    const listDataTour = [];
    if (bounds && bounds.northWest && bounds.southEast) {
      const [lngN, latN] = bounds.northWest.split('|').map((v) => +v);
      const [lngS, latS] = bounds.southEast.split('|').map((v) => +v);
      if (this.props.data) {
        this.props.data.forEach((el) => {
          if (
            el.pointScope === 'main_club_marker' ||
            el.pointScope === 'main_bed_marker' ||
            el.scope === 'wla_poi'
          ) {
            return;
          }
          if (
            el.lat < latN &&
            el.lat > latS &&
            el.lng < lngS &&
            el.lng > lngN
          ) {
            listDataTour.push(el);
          }
        });
      }
    }
    listDataTour.sort(getSortFunction(this.state.filterType));
    this.setState({
      listDataTour,
    });
  };

  setListData(data) {
    this.setState((state) => ({
      listDataTour: [...data].sort(getSortFunction(state.filterType)),
    }));
  }

  removeItemFromList = (id) => {
    this.setState((state) => ({
      listDataTour: state.listDataTour.filter((el) => el.id !== id),
    }));
  };

  setSelectedEntity = (ref, entity) => {
    const { data } = this.props.resp;
    if (data.id === entity.id && ref) {
      this.selectedEntity.current = ref;
    }
    if (!ref) this.selectedEntity.current = null;
  };

  componentDidUpdate = (prevProps, prevState) => {
    const isTourFolder =
      this.props.router.location.pathname.match(/\/tour\/folders\//);

    if (prevProps.data !== this.props.data) {
      if (
        this.props.data &&
        this.props.data.length > 0 &&
        Object.keys(this.props.resp.data).length > 0
      ) {
        let index;
        this.props.data.forEach((item, i) => {
          if (item.id === this.props.resp.data.id) {
            index = i;
          }
        });
        const item = this.props.data.splice(index, 1);
        this.props.data.splice(0, 0, item[0]);
      }

      if (!isTourFolder) {
        this.filterListByMapBounds();
      } else {
        // console.log(this.props.data);
        this.setListData(this.props.data);
      }
    }

    if (prevProps.boundsUrl !== this.props.boundsUrl) {
      if (!isTourFolder) {
        this.filterListByMapBounds();
      } else {
        this.setListData(this.props.data);
      }
    }

    const { width, filterType } = this.state;
    if (prevState.filterType !== filterType) {
      this.filterListByFilter();
    }

    if (isBrowser && width > 768) {
      if (
        this.iScroll.current.scrollTop !== 0 &&
        prevState.type !== this.state.type
      ) {
        if (this.state.type === 'list') {
          this.iScroll.current.scrollTo(0, this.iScroll.current.scrollTop * 4);
        } else {
          this.iScroll.current.scrollTo(0, this.iScroll.current.scrollTop / 4);
        }
      }
      // if(this.selectedTour.current && prevState.type !== this.state.type)
      //   this.selectedTour.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      if (this.selectedEntity.current) {
        this.selectedEntity.current.parentElement.parentElement.scrollIntoView({
          behavior: 'instant',
          block: 'nearest',
        });
      }
    }
    if (
      prevProps.mapData.fetching !== this.props.mapData.fetching &&
      this.props.mapData.fetching === false
    ) {
      this.endLoading();
    }

    if (prevProps.historyData !== this.props.historyData) {
      this.setFilterType();
    }
  };

  endLoading = () => {
    this.setState({
      loadingState: false,
    });
  };

  setFilterType = () => {
    this.setState({
      filterType: this.props.historyData[SCOPE],
    });
  };

  // CHANGE TYPE LIST
  listTypeList = () => {
    this.setState({
      type: TYPE_LIST,
      classList: [ICON, SELECT_ICON],
      classBlock: [ICON],
      display: DISPLAY_NONE,
    });
  };

  listTypeBlock = () => {
    this.setState({
      type: TYPE_BLOCK,
      classBlock: [ICON, SELECT_ICON],
      classList: [ICON],
      display:
        Object.keys(this.props.resp.data).length === 0
          ? DISPLAY_NONE
          : DISPLAY_BLOCK,
    });
  }; //

  // PREVIEW LIST CLOSE & SHOW
  closePreviewer = () => {
    this.props.setPreviewTourData({});
    this.setState({
      display: DISPLAY_NONE,
    });
  };

  listPreviewShow = (e, item) => {
    const tourList = this.props.data;
    const hiperPrevTourData =
      tourList[tourList.findIndex((t) => t.id === item.id)];
    const mapPosition = {
      lat: hiperPrevTourData.lat,
      lng: hiperPrevTourData.lng,
    };

    store.dispatch((dispatch) => {
      dispatch({
        type: types.FETCH_PREVIEW_DATA_HIPERSET,
        payload: hiperPrevTourData,
      });

      dispatch({
        type: types.FETCH_PREVIEW_DATA,
        payload: API.way.tour
          .getOne('preview', { id: item.id })
          .then((resp) => resp.data.response),
      });
    });

    this.props.tourFullDataId({ id: item.id });
    this.props.tourFullMapPosition(mapPosition);
    this.setState({
      display: DISPLAY_BLOCK,
    });
  };
  //

  getPolySelectedTourAfterListClose = () => {
    if (this.props.resp.data.scope === pageScope.tour) {
      const previewJson = this.props.resp.data;

      if (Object.keys(previewJson).length !== 0) {
        const tourId = previewJson.id;
        store.dispatch((dispatch) => {
          dispatch({
            type: types.FETCH_PREVIEW_POLYLINE,
            payload: API.way.tour
              .getOne('aproxPoly', { with_start: 1, id: tourId })
              .then(({ data }) => {
                return data.response;
              }),
          });
        });
      }
    }
  };

  // BOOKMARK
  chooseBookmark = (el, id, item) => {
    item.bookmark = !item.bookmark;
    // this.setState({ jsonItem: item });
  };

  bookmark = (bookmarked) => {
    return bookmarked ? (
      <BookmarkShapeIcon className="bookmark" />
    ) : (
      <BookmarkIcon className="bookmark" />
    );
  };
  //

  // VIEW DATA TOUR FULL
  viewData = (e, item) => {
    this.props.tourFullDataId({ id: item.id });
    const tourList = this.props.data;
    const hiperPrevTourData =
      tourList[tourList.findIndex((t) => t.id === item.id)];
    const mapPosition = {
      lat: hiperPrevTourData.lat,
      lng: hiperPrevTourData.lng,
    };
    this.props.tourFullMapPosition(mapPosition);
  }; //

  // READ MORE CHANGE
  readMoreChange = () => {
    this.setState((state) => ({
      readMore: !state.readMore,
    }));
  };

  UNSAFE_componentWillUpdate() {
    if (this.state.readMore === false) {
      this.setReadMore();
    }
  }

  setReadMore = () => {
    this.setState({
      readMore: true,
    });
  };
  //
  // setSelectedPoint = (ref, item) => {
  //   if(this.props.resp.data.id === item.id && ref){
  //       this.selectedTour.current = ref
  //   }
  // }

  setDisplayPreview = (val) => {
    const display = val ? DISPLAY_BLOCK : DISPLAY_NONE;
    this.setState({ display });
  };

  render() {
    const getImageBig = getImgBig(this.props.resp.data);
    const getT = getTitle(this.props.resp.data);
    const getDes = getDescription(this.props.resp.data);
    const { label } = this.props;
    const { width } = this.state;
    const isMobile = width < 768;
    // const isTable = width <= 1024 && width >= 768;
    const isTable = width < 768;
    return isMobileOnly || isMobile ? (
      <TourListMobile
        data={this.props.data}
        history={this.props.history}
        dataMapFetching={this.props.dataMapFetching}
        scope={this.props.scope}
      />
    ) : (
      <ListTour
        tr={this.props.tr}
        getImageBig={getImageBig}
        getT={getT}
        getDes={getDes}
        label={label}
        data={this.props.data}
        tourFullDataId={this.props.tourFullDataId}
        previewJson={this.props.resp.data}
        tourFullMapPosition={this.props.tourFullMapPosition}
        classList={this.state.classList}
        classBlock={this.state.classBlock}
        display={this.state.display}
        type={this.state.type}
        loadingState={this.state.loadingState}
        readMore={this.state.readMore}
        listTypeList={this.listTypeList}
        listTypeBlock={this.listTypeBlock}
        showPreviewer={this.listPreviewShow}
        closePreviewer={this.closePreviewer}
        chooseBookmark={this.chooseBookmark}
        bookmark={this.bookmark}
        displayItems={this.displayItems}
        loadMoreItems={this.loadMoreItems}
        viewData={this.viewData}
        readMoreChange={this.readMoreChange}
        openModalTourDownload={this.props.openModalTourDownload}
        TourDownloadBasketData={this.props.TourDownloadBasketData}
        downloadTourGps={this.props.downloadTourGps}
        TourDownloadCoord={this.props.TourDownloadCoord}
        ref={this.iScroll}
        router={this.props.router}
        getPolySelectedTourAfterListClose={
          this.getPolySelectedTourAfterListClose
        }
        history={this.props.history}
        dataMapFetching={this.props.dataMapFetching}
        dataPreviewFetching={this.props.dataPreviewFetching}
        scope={this.props.scope}
        setDisplayPreview={this.setDisplayPreview}
        isTable={isTable}
        setSelectedEntity={this.setSelectedEntity}
        handleChange={this.handleChangeFilter}
        profile={this.props.profile}
        removeItemFromList={this.removeItemFromList}
        filterType={this.state.filterType}
      />
    );
  }
}

const mapStateToProps = (store) => {
  return {
    data: store.mapDataReducer.data,
    dataMapFetching: store.mapDataReducer.fetching,
    dataPreviewFetching: store.fetchPreviewData.fetching,
    resp: store.fetchPreviewData,
    mapData: store.mapDataReducer,
    downloadTourGps: store.tourDownload,
    router: store.router,
    scope: store.mapScopeReducer.scope,
    boundsUrl: store.mapPostition.boundsUrl,
    user: store.authentication.user,
    historyData: store.userHistoryReducer.data,
  };
};
function MatchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      tourFullDataId,
      tourFullMapPosition,
      openModalTourDownload,
      TourDownloadBasketData,
      TourDownloadCoord,
      setPreviewTourData,
      setHistoryScope,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  MatchDispatchToProps
)(withTranslations(List));

import React from 'react';
import { Card, Typography } from 'antd';
import Linkify from 'react-linkify';
import { getDescription, decodeSpecLetters } from '../../Utility/utility';
import { pageScope } from '../../../constants/mapConstants';

const { Paragraph } = Typography;

function PreviewBodyWrapper({ previewJson, fetching, render }) {
  const desc = decodeSpecLetters(getDescription(previewJson));
  const isClub =
    previewJson.scope === pageScope.club ||
    previewJson.scope === pageScope.bikerbed;

  //   function activateShare() {
  //     setTooltipVisible(false);
  //     handleShare(true);
  //   }

  return (
    <div
      className={
        isClub
          ? 'entityPreviewBodyWrapper club-preview-improvement'
          : 'entityPreviewBodyWrapper'
      }
    >
      <div className="tourPreviewBodyContent">
        {fetching === true ? (
          <Card bordered={false} loading className="descLoad" />
        ) : (
          <div
            className={
              isClub
                ? 'entityPreviewBodyContentDescription club-preview-improvement'
                : 'entityPreviewBodyContentDescription'
            }
          >
            <Paragraph ellipsis={{ rows: 7 }}>
              <Linkify>{desc}</Linkify>
            </Paragraph>
          </div>
        )}
      </div>

      <div className="actionsBtn">
        {typeof render === 'function' ? render() : ''}
      </div>
    </div>
  );
}

PreviewBodyWrapper.propTypes = {};

export default PreviewBodyWrapper;

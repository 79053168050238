import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Link, useHistory, useLocation } from 'react-router-dom';

import { Menu, Table, Input, Checkbox, Button, Popconfirm } from 'antd';

import { clubAPI } from 'app/API/club.api';

import withTranslations from 'app/components/HOGs/withTranslations';

import checkPrevLink from 'app/components/Utility/checkPrevUrlLink';
import { removeSpecChars } from 'app/components/Utility/utility';
import { openNotificationWithIcon } from 'app/components/Utility/notificationMessage';
import CloseButton from '../../../Utility/closeButton';

let timer = null;

const MenuManage = ({ tr, clubId, title }) => {
  const [current, setCurrent] = useState('4');

  const history = useHistory();

  const handleClick = (e) => {
    switch (e.key) {
      case '1':
        history.push({
          pathname: `/club/${removeSpecChars(title)}_${clubId}`,
          search: '',
        });
        return;
      case '2':
        setCurrent(e.key);
        history.push({
          pathname: `/club/manageteamadvanced/${clubId}`,
          search: '',
        });
        return;
      case '3':
        history.push({
          pathname: `/club/edit/${removeSpecChars(title)}_${clubId}`,
          search: '',
        });
        return;
      default:
        setCurrent(e.key);
        history.push({
          pathname: `/club/managerelations/${clubId}`,
          search: '',
        });
    }
  };

  const items = [
    {
      key: '1',
      label: tr.Home,
    },
    {
      key: '2',
      label: tr['Our team'],
    },
    {
      key: '3',
      label: tr.Edit,
    },
    {
      key: '4',
      label: tr['Bike club relations'],
    },
  ];

  return (
    <div className="manage_menu_layout">
      <Menu
        onClick={(e) => handleClick(e)}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
    </div>
  );
};

const Members = (props) => {
  const history = useHistory();

  const {
    tr,
    clubId,
    bikerbed,
    get_members,
    members,
    setMembers,
    pageMembers,
    totalPageMembers,
  } = props;

  useEffect(() => {
    get_members();
  }, []);

  const acceptClick = (item) => {
    if (!item.relation) {
      openNotificationWithIcon(
        'warning',
        tr[
          'There are not selected a bike club relation type. Make a selection.'
        ]
      );
      return;
    }

    const data = {
      club_id: +clubId,
      from_id: item.id,
      type: item.relation,
    };

    clubAPI
      .accept_relation(data)
      .then((res) => {
        const { code } = res;
        if (code === 200) {
          openNotificationWithIcon('success', tr.Success);
          get_members();
        }
      })
      .catch((err) => console.log(err));
  };

  const removeClick = (item) => {
    const data = {
      from_id: +clubId,
      to_id: item.id,
    };

    clubAPI
      .remove_relation(data)
      .then((res) => {
        const { code } = res;
        if (code === 200) {
          openNotificationWithIcon('success', tr.Success);
          get_members();
        }
      })
      .catch((err) => console.log(err));
  };

  const saveClick = (item) => {
    if (!item.relation) {
      openNotificationWithIcon(
        'warning',
        tr[
          'There are not selected a bike club relation type. Make a selection.'
        ]
      );
      return;
    }

    const getType = () => {
      if (item.relation.includes('parent')) {
        return 'parent';
      }

      if (item.relation.includes('branch')) {
        return 'branch';
      }

      if (item.relation.includes('friend')) {
        return 'friend';
      }

      return null;
    };

    const data = {
      from_id: clubId,
      to_id: item.id,
      type: getType(),
    };

    clubAPI.add_request(data).then((res) => {
      const { code } = res;
      if (code === 200) {
        openNotificationWithIcon('success', tr.Success);
        get_members();
      }
    });
  };

  const rejectClick = (item) => {
    const data = {
      club_id: +clubId,
      from_id: item.id,
    };

    clubAPI
      .decline_relation(data)
      .then((res) => {
        const { code } = res;
        if (code === 200) {
          openNotificationWithIcon('success', tr.Success);
          get_members();
        }
      })
      .catch((err) => console.log(err));
  };

  const pageChange = (page) => {
    get_members(page);
  };

  const onChange = (e) => {
    const val = e.target.value;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      const filters = { title: val };
      get_members(1, filters);
    }, 400);
  };

  const columns = bikerbed
    ? [
        {
          title: (
            <Input
              size="small"
              placeholder={tr['Write a search word']}
              allowClear
              onChange={onChange}
              className="manage_input"
            />
          ),
          dataIndex: 'title',
          key: 'title',
          width: '180px',
          ellipsis: true,
          render: (text, record) => {
            return (
              <div className="name_layout">
                <img
                  src={process.env.BACKEND_URL + record.logo_icon}
                  className="name_img"
                  alt=""
                />
                <span className="name_text">{text}</span>
              </div>
            );
          },
        },
        {
          title: tr.Country,
          dataIndex: 'country',
          key: 'country',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text) => text || '-',
        },
        {
          title: tr.Friend,
          dataIndex: 'friend',
          key: 'friend',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text, record, index) => (
            <Checkbox
              onChange={() => {
                const newData = members.map((item) => {
                  if (index === item.key) {
                    item.relation = item.relation?.includes('friend')
                      ? null
                      : 'friend';
                  }
                  return item;
                });
                setMembers([...newData]);
              }}
              checked={record.relation?.includes('friend')}
            />
          ),
        },
        {
          title: tr.Followers,
          dataIndex: 'count_followers',
          key: 'count_followers',
          align: 'center',
          width: '90px',
          ellipsis: true,
        },
        {
          title: tr.Tours,
          dataIndex: 'count_tours',
          key: 'count_tours',
          align: 'center',
          width: '90px',
          ellipsis: true,
        },
        {
          title: '',
          dataIndex: 'empty',
          key: 'empty',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: '300px',
          fixed: 'right',
          render: (text, record, index) => {
            return (
              <div className="actions">
                <Popconfirm
                  title={tr['Do you want to remove this bikerBed?']}
                  onConfirm={() => removeClick(members[index])}
                  okText={tr.Yes}
                  cancelText={tr.No}
                >
                  <Button type="primary" className="actions_btn">
                    {tr.Remove}
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  className="actions_btn"
                  onClick={() => {
                    history.push({
                      pathname: `/bikerbed/${removeSpecChars(
                        members[index].title || ''
                      )}_${members[index].id}`,
                    });
                  }}
                >
                  {tr.Visit}
                </Button>
              </div>
            );
          },
        },
      ]
    : [
        {
          title: (
            <Input
              size="small"
              placeholder={tr['Write a search word']}
              allowClear
              onChange={onChange}
              className="manage_input"
            />
          ),
          dataIndex: 'title',
          key: 'title',
          width: '180px',
          ellipsis: true,
          render: (text, record) => {
            return (
              <div className="name_layout">
                <img
                  src={process.env.BACKEND_URL + record.logo_icon}
                  className="name_img"
                  alt=""
                />
                <span className="name_text">{text}</span>
              </div>
            );
          },
        },
        {
          title: tr.Country,
          dataIndex: 'country',
          key: 'country',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text) => text || '-',
        },
        {
          title: tr.Parent,
          dataIndex: 'parent',
          key: 'parent',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text, record, index) => (
            <Checkbox
              onChange={() => {
                const newData = members.map((item) => {
                  if (index === item.key) {
                    item.relation = item.relation?.includes('parent')
                      ? null
                      : 'parent';
                  }
                  return item;
                });
                setMembers([...newData]);
              }}
              checked={record.relation?.includes('parent')}
            />
          ),
        },
        {
          title: tr.Branch,
          dataIndex: 'branch',
          key: 'branch',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text, record, index) => (
            <Checkbox
              onChange={() => {
                const newData = members.map((item) => {
                  if (index === item.key) {
                    item.relation = item.relation?.includes('branch')
                      ? null
                      : 'branch';
                  }
                  return item;
                });
                setMembers([...newData]);
              }}
              checked={record.relation?.includes('branch')}
            />
          ),
        },
        {
          title: tr.Friend,
          dataIndex: 'friend',
          key: 'friend',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text, record, index) => (
            <Checkbox
              onChange={() => {
                const newData = members.map((item) => {
                  if (index === item.key) {
                    item.relation = item.relation?.includes('friend')
                      ? null
                      : 'friend';
                  }
                  return item;
                });
                setMembers([...newData]);
              }}
              checked={record.relation?.includes('friend')}
            />
          ),
        },
        {
          title: tr.Members,
          dataIndex: 'count_members',
          key: 'count_members',
          align: 'center',
          width: '90px',
          ellipsis: true,
        },
        {
          title: tr.Tours,
          dataIndex: 'count_tours',
          key: 'count_tours',
          align: 'center',
          width: '90px',
          ellipsis: true,
        },
        {
          title: '',
          dataIndex: 'empty',
          key: 'empty',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: '300px',
          fixed: 'right',
          render: (text, record, index) => {
            const { request } = record;
            const { invintation } = record;

            return (
              <div className="actions">
                {request && !invintation ? (
                  <div className="request_text">{tr.Pending}</div>
                ) : (
                  <>
                    <Button
                      type="primary"
                      className="actions_btn"
                      onClick={() => {
                        if (invintation) {
                          acceptClick(members[index]);
                        } else {
                          saveClick(members[index]);
                        }
                      }}
                    >
                      {!invintation ? tr.Save : tr.Accept}
                    </Button>

                    <Popconfirm
                      title={
                        !invintation
                          ? tr['Do you want to remove this club?']
                          : tr['Do you want to reject this request?']
                      }
                      onConfirm={() => {
                        if (invintation) {
                          rejectClick(members[index]);
                        } else {
                          removeClick(members[index]);
                        }
                      }}
                      okText={tr.Yes}
                      cancelText={tr.No}
                    >
                      <Button type="primary" className="actions_btn">
                        {!invintation ? tr.Remove : tr.Decline}
                      </Button>
                    </Popconfirm>
                  </>
                )}
                <Button
                  type="primary"
                  className="actions_btn"
                  onClick={() => {
                    history.push({
                      pathname: `/club/${removeSpecChars(
                        members[index].title || ''
                      )}_${members[index].id}`,
                    });
                  }}
                >
                  {tr.Visit}
                </Button>
              </div>
            );
          },
        },
      ];

  const name = bikerbed
    ? tr['BikerBeds relations']
    : tr['Bike clubs relations'];

  return (
    <div className="members_layout">
      <div className="title">{name}</div>
      <div className="table">
        <Table
          columns={columns}
          dataSource={members}
          scroll={{ x: 1100 }}
          pagination={{
            hideOnSinglePage: true,
            current: pageMembers,
            total: totalPageMembers,
            onChange: pageChange,
          }}
        />
      </div>
    </div>
  );
};

const Users = (props) => {
  const history = useHistory();

  const {
    tr,
    clubId,
    bikerbed,
    get_users,
    get_members,
    users,
    setUsers,
    pageUsers,
    totalPageUsers,
  } = props;

  useEffect(() => {
    get_users();
  }, []);

  const onAdd = (item) => {
    if (!item.relation && !bikerbed) {
      openNotificationWithIcon(
        'warning',
        tr[
          'There are not selected a bike club relation type. Make a selection.'
        ]
      );
      return;
    }
    const data = {
      from_id: clubId,
      to_id: item.id,
      type: bikerbed ? 'friend' : item.relation,
    };

    clubAPI.add_request(data).then((res) => {
      const { code } = res;
      if (code === 200) {
        openNotificationWithIcon('success', tr.Success);
        get_users();
        get_members();
      }
    });
  };

  const pageChange = (page) => {
    get_users(page);
  };

  const onChange = (e) => {
    const val = e.target.value;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      const filters = { title: val };
      get_users(1, filters);
    }, 400);
  };

  const columns = bikerbed
    ? [
        {
          title: (
            <Input
              size="small"
              placeholder={tr['Write a search word']}
              allowClear
              onChange={onChange}
              className="manage_input"
            />
          ),
          dataIndex: 'title',
          key: 'title',
          width: '180px',
          ellipsis: true,
          render: (text, record) => {
            return (
              <div className="name_layout">
                <img
                  src={process.env.BACKEND_URL + record.logo_icon}
                  className="name_img"
                  alt=""
                />
                <span className="name_text">{text}</span>
              </div>
            );
          },
        },
        {
          title: tr.Country,
          dataIndex: 'country',
          key: 'country',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text) => text || '-',
        },
        {
          title: tr.Friend,
          dataIndex: 'friend',
          key: 'friend',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text, record, index) => (
            <Checkbox
              onChange={() => {
                const newData = users.map((item) => {
                  if (index === item.key) {
                    item.relation = item.relation?.includes('friend')
                      ? null
                      : 'friend';
                  }
                  return item;
                });
                setUsers([...newData]);
              }}
              checked={record.relation?.includes('friend')}
            />
          ),
        },
        {
          title: tr.Followers,
          dataIndex: 'count_followers',
          key: 'count_followers',
          align: 'center',
          width: '90px',
          ellipsis: true,
        },
        {
          title: tr.Tours,
          dataIndex: 'count_tours',
          key: 'count_tours',
          align: 'center',
          width: '90px',
          ellipsis: true,
        },
        {
          title: '',
          dataIndex: 'empty',
          key: 'empty',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: '300px',
          fixed: 'right',
          render: (text, record, index) => (
            <div className="actions">
              <Button
                type="primary"
                className="actions_btn"
                onClick={() => onAdd(users[index])}
              >
                {tr.Add}
              </Button>
              <Button
                type="primary"
                className="actions_btn"
                onClick={() => {
                  history.push({
                    pathname: `/bikerbed/${removeSpecChars(
                      users[index].title || ''
                    )}_${users[index].id}`,
                  });
                }}
              >
                {tr.Visit}
              </Button>
            </div>
          ),
        },
      ]
    : [
        {
          title: (
            <Input
              size="small"
              placeholder={tr['Write a search word']}
              allowClear
              onChange={onChange}
              className="manage_input"
            />
          ),
          dataIndex: 'title',
          key: 'title',
          width: '180px',
          ellipsis: true,
          render: (text, record) => {
            return (
              <div className="name_layout">
                <img
                  src={process.env.BACKEND_URL + record.logo_icon}
                  className="name_img"
                  alt=""
                />
                <span className="name_text">{text}</span>
              </div>
            );
          },
        },
        {
          title: tr.Country,
          dataIndex: 'country',
          key: 'country',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text) => text || '-',
        },
        {
          title: tr.Parent,
          dataIndex: 'parent',
          key: 'parent',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text, record, index) => (
            <Checkbox
              onChange={() => {
                const newData = users.map((item) => {
                  if (index === item.key) {
                    item.relation = item.relation?.includes('parent')
                      ? null
                      : 'parent';
                  }
                  return item;
                });
                setUsers([...newData]);
              }}
              checked={record.relation?.includes('parent')}
            />
          ),
        },
        {
          title: tr.Branch,
          dataIndex: 'branch',
          key: 'branch',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text, record, index) => (
            <Checkbox
              onChange={() => {
                const newData = users.map((item) => {
                  if (index === item.key) {
                    item.relation = item.relation?.includes('branch')
                      ? null
                      : 'branch';
                  }
                  return item;
                });
                setUsers([...newData]);
              }}
              checked={record.relation?.includes('branch')}
            />
          ),
        },
        {
          title: tr.Friend,
          dataIndex: 'friend',
          key: 'friend',
          align: 'center',
          width: '80px',
          ellipsis: true,
          render: (text, record, index) => (
            <Checkbox
              onChange={() => {
                const newData = users.map((item) => {
                  if (index === item.key) {
                    item.relation = item.relation?.includes('friend')
                      ? null
                      : 'friend';
                  }
                  return item;
                });
                setUsers([...newData]);
              }}
              checked={record.relation?.includes('friend')}
            />
          ),
        },
        {
          title: tr.Members,
          dataIndex: 'count_members',
          key: 'count_members',
          align: 'center',
          width: '90px',
          ellipsis: true,
        },
        {
          title: tr.Tours,
          dataIndex: 'count_tours',
          key: 'count_tours',
          align: 'center',
          width: '90px',
          ellipsis: true,
        },
        {
          title: '',
          dataIndex: 'empty',
          key: 'empty',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: '300px',
          fixed: 'right',
          render: (text, record, index) => (
            <div className="actions">
              <Button
                type="primary"
                className="actions_btn"
                onClick={() => onAdd(users[index])}
              >
                {tr.Add}
              </Button>
              <Button
                type="primary"
                className="actions_btn"
                onClick={() => {
                  history.push({
                    pathname: `/club/${removeSpecChars(
                      users[index].title || ''
                    )}_${users[index].id}`,
                  });
                }}
              >
                {tr.Visit}
              </Button>
            </div>
          ),
        },
      ];

  const name = bikerbed ? tr['BikerBed search'] : tr['Bike club search'];

  return (
    <div className="members_layout">
      <div className="title">{name}</div>
      <div className="table">
        <Table
          columns={columns}
          dataSource={users}
          scroll={{ x: 1100 }}
          pagination={{
            hideOnSinglePage: true,
            current: pageUsers,
            total: totalPageUsers,
            onChange: pageChange,
          }}
        />
      </div>
    </div>
  );
};

const TeamRelations = ({ clubId, tr }) => {
  const previousLocation = useSelector(
    (store) => store.locationUrl.previousLocation
  );
  const currentLocation = useSelector(
    (store) => store.locationUrl.currentLocation
  );

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const bikerbed = searchParams.get('bikerbed');

  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');

  const [pageMembers, setPageMembers] = useState(1);
  const [totalPageMembers, setTotalPageMembers] = useState(0);
  const [members, setMembers] = useState([]);

  const [pageUsers, setPageUsers] = useState(1);
  const [totalPageUsers, setTotalPageUsers] = useState(0);
  const [users, setUsers] = useState([]);

  const is_bikerbed = bikerbed && bikerbed === '1';

  const get_title = async () => {
    await clubAPI
      .get_title(clubId)
      .then((res) => {
        const { code, response } = res;
        if (code === 200) {
          setTitle(response.title);
        } else {
          checkPrevLink(previousLocation, currentLocation);
        }
      })
      .catch((err) => {
        console.log(err);
        checkPrevLink(previousLocation, currentLocation);
      });
  };

  const is_admin = () => {
    clubAPI
      .is_admin(clubId)
      .then((res) => {
        const { code, response } = res;
        if (code === 200 && response.access) {
          setLoading(false);
        } else {
          checkPrevLink(previousLocation, currentLocation);
        }
      })
      .catch((err) => {
        console.log(err);
        checkPrevLink(previousLocation, currentLocation);
      });
  };

  const get_members = (page = 1, filters = {}) => {
    const data = {
      club_id: clubId,
      page,
      bikerbed: bikerbed || 0,
      filters,
    };

    clubAPI.get_all(data).then((res) => {
      const { code, response } = res;
      if (code === 200) {
        const { entities, currentPage, countPage } = response;
        setMembers(
          entities.map((item, i) => {
            const data = {
              key: i,
              ...item,
            };
            return data;
          })
        );
        setPageMembers(currentPage);
        setTotalPageMembers(countPage);
      }
    });
  };

  const get_users = (page = 1, filters = {}) => {
    const data = {
      id: clubId,
      page,
      bikerbed: bikerbed || 0,
      filters,
    };

    clubAPI.get_not_related_clubs(data).then((res) => {
      const { code, response } = res;
      if (code === 200) {
        const { entities, currentPage, countPage } = response;
        setUsers(
          entities.map((item, i) => {
            const data = {
              key: i,
              ...item,
            };

            return data;
          })
        );
        setPageUsers(currentPage);
        setTotalPageUsers(countPage);
      }
    });
  };

  useEffect(() => {
    is_admin();
    get_title();
  }, []);

  useEffect(() => {
    get_users();
    get_members();
  }, [search]);

  return (
    <>
      <div className="curtain" />
      <div className="admin_layout">
        <div className="admin_green_line">
          <div className="label">
            <Link to="#">{tr['Bike club relations']}</Link>
          </div>
          <CloseButton
            close={() => checkPrevLink(previousLocation, currentLocation)}
          />
        </div>
        <div className="content_main_admin">
          {!loading && (
            <>
              <MenuManage
                tr={tr}
                clubId={clubId}
                title={title}
                bikerbed={is_bikerbed}
              />
              <Members
                tr={tr}
                clubId={clubId}
                bikerbed={is_bikerbed}
                get_members={get_members}
                members={members}
                setMembers={setMembers}
                pageMembers={pageMembers}
                totalPageMembers={totalPageMembers}
              />
              <Users
                tr={tr}
                clubId={clubId}
                bikerbed={is_bikerbed}
                get_users={get_users}
                get_members={get_members}
                users={users}
                setUsers={setUsers}
                pageUsers={pageUsers}
                totalPageUsers={totalPageUsers}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withTranslations(TeamRelations);

import React from 'react';
const axios = require('axios');
const url = process.env.API_URL+"/api";

export const friendshipAPI = {
    add: userToFriend,
    cancel: userToFriend,
    remove: userToFriend,
    request: friendResponse,
    driveWithFriends
}

function userToFriend(friendId){
    const requestOptions = {
        method: 'GET',
        withCredentials: true,
    };

    return axios(`${url}/profile.addOrRemoveFriend/?id=${friendId}`, requestOptions).then(handleResponse);
}

function friendResponse(friendId, type='accept'){
    const requestOptions = {
        method: 'GET',
        withCredentials: true,
    };

    return axios(`${url}/profile.friendResponse/?frid=${friendId}&type=${type}&type2=friend`, requestOptions).then(handleResponse);
}

function driveWithFriends(feuser_id, frid, type){
    const requestOptions = {
        method: 'GET',
        withCredentials: true,
    };

    return axios(`${url}/profile.driveWithFriends?feuser_id=${feuser_id}&type=${type}&frid=${frid}`, requestOptions).then(handleResponse);
}
const handleResponse = response => {
    return new Promise((resolve, reject) => {
        const data = response.data;
        if (response.status !== 200) {
            const error = (data && data.message) || response.statusText;
            return reject( error );
        }
        return resolve( data.body );
    });
}
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input, Form } from 'antd';

import withTranslations from 'app/components/HOGs/withTranslations';

import { decodeSpecLetters } from 'app/components/Utility/utility';
import { BikerClubIcon } from '../../MainMenu/svg';

const viewStatistic = (tr, total, tours, gps) => {
  return (
    <>
      {total !== null ? <div>{`${total} ${tr.VIEWS}`}</div> : null}
      {tours !== null ? <div>{`| ${tours} ${tr.TOURS}`}</div> : null}
      {gps !== null ? <div>{`| ${gps} ${tr.DOWNLOADS}`}</div> : null}
    </>
  );
};

const editName = (tr) => {
  return (
    <Form.Item
      name="name"
      rules={[
        {
          required: true,
          message: tr['Required fields are not filled in'],
        },
      ]}
    >
      <Input
        className="edit_name_input"
        placeholder={`${tr['Name of motorcycle club']}*`}
      />
    </Form.Item>
  );
};

const viewName = (tr, title, username, total, tours, gps) => {
  return (
    <>
      <div className="view_name">
        <div className="title">{decodeSpecLetters(title)}</div>
        <div className="info_creater">
          {`${tr['Created by'].toUpperCase()}: `}
          <span>{decodeSpecLetters(username)}</span>
        </div>
        <div className="info_view">{viewStatistic(tr, total, tours, gps)}</div>
      </div>
    </>
  );
};

const Title = ({ tr, form }) => {
  const edit = useSelector((store) => store.clubFullDataReducer.isEditMode);
  const title = useSelector((store) => store.clubFullDataReducer.data.title);
  const total = useSelector((store) => store.clubFullDataReducer.data.total);
  const tours = useSelector((store) => store.clubFullDataReducer.data.tours);
  const gps = useSelector((store) => store.clubFullDataReducer.data.gps);

  const username = useSelector(
    (store) => store.clubFullDataReducer.data.username
  );

  useEffect(() => {
    if (edit && form && title) form.setFieldsValue({ name: title });
  }, [title, edit]);

  const [height, width] = edit ? [25, 25] : [35, 35];

  return (
    <div className="title_layout">
      <BikerClubIcon height={height} width={width} />
      {edit
        ? editName(tr, form, title)
        : viewName(tr, title, username, total, tours, gps)}
    </div>
  );
};

export default withTranslations(Title);

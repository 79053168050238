export const applyingTypes = Object.freeze({
  TOUR_FOLDER: 1,
  PLACE_FOLDER: 2,
  DENMARK_FOLDER: 3,
  FRANCE_FOLDER: 4,
  GERMANY_FOLDER: 5,
});

export const applyingPrivate = (applying) =>
  applying === applyingTypes.TOUR_FOLDER ||
  applying === applyingTypes.PLACE_FOLDER;

export const applyingPublic = (applying) =>
  applying === applyingTypes.DENMARK_FOLDER ||
  applying === applyingTypes.FRANCE_FOLDER ||
  applying === applyingTypes.GERMANY_FOLDER;

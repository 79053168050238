import React from 'react';
// ANTD
import { BarsOutlined, AppstoreOutlined } from '@ant-design/icons';

const ListTypeIcon = (props) => {
  return (
    <div className="listType">
      <AppstoreOutlined
        className={props.classBlock}
        onClick={props.listTypeBlock}
        style={{ fontSize: 18, color: '#aa2493', marginRight: 4 }}
      />
      <BarsOutlined
        className={props.classList}
        onClick={props.listTypeList}
        style={{ fontSize: 18, color: '#aa2493' }}
      />
    </div>
  );
};

export default ListTypeIcon;

import pageCache from 'app/API/cache.service';

export const addedDreamFolder = (folders, userId) => {
  const admin = process.env.TOURSTART_ADMIN.includes(userId);

  const folderListDenmark = pageCache.get('dreamFolders')?.Denmark || [];
  const folderListFrance = pageCache.get('dreamFolders')?.France || [];
  const folderListGermany = pageCache.get('dreamFolders')?.Germany || [];

  if (admin) {
    const data = [
      ...folders,
      ...folderListDenmark,
      ...folderListFrance,
      ...folderListGermany,
    ];
    return data;
  }

  return folders;
};

import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import { pageScope } from 'app/constants/mapConstants';
import pageDataAPI from '../../../API/pageData.api';
import List from '../List/list.container';

const NafClubs = ({ match }) => {
  const { init, getNafClubs, scope } = useActions();

  useEffect(() => {
    getNafClubs();
    scope(pageScope.club);
    return () => {
      pageDataAPI.sourceCancel();
      init();
    };
  }, []);

  const getLabel = () => (
    <FormattedMessage id="NAF MC Clubs" defaultMessage="NAF MC Clubs" />
  );

  return (
    <Route
      path={`${match.url}/list`}
      render={() => <List label={getLabel()} />}
    />
  );
};

export default NafClubs;

import { counter } from '../action_types';

export const setMyTours = (payload) => ({
  type: counter.setMyTours,
  payload,
});

export const setMyPlaces = (payload) => ({
  type: counter.setMyPlaces,
  payload,
});

export const increaseCounter = (key, count = 1) => ({
  type: counter.increase,
  payload: { key, count },
});

export const decreaseCounter = (key, count = 1) => ({
  type: counter.decrease,
  payload: { key, count },
});

import * as types from '../action_types';

export const openModalTourDownload = (data) => {
  return {
    type: types.TOUR_DOWNLOAD_GPS_MODAL_OPEN,
    payload: data,
  };
};
export const openTourDownloadBasket = (data) => {
  return {
    type: types.TOUR_DOWNLOAD_GPS_BASKET_OPEN,
    payload: data,
  };
};
export const TourDownloadBasketData = (data) => {
  return {
    type: types.TOUR_DOWNLOAD_GPS_DATA,
    payload: data,
  };
};
export const TourBasketDataDelete = (data) => {
  return {
    type: types.TOUR_DOWNLOAD_GPS_DATA_DELETE_FROM_BASKET,
    payload: data,
  };
};
export const TourDownloadCoord = (data) => {
  return {
    type: types.TOUR_DOWNLOAD_GPS_DATA_COORD,
    payload: data,
  };
};
export const TourDownloadDataClean = () => {
  return {
    type: types.TOUR_DOWNLOAD_GPS_DATA_CLEAR,
  };
};
export const TourDownloadDataFromDB = (data) => {
  return {
    type: types.TOUR_DOWNLOAD_GPS_DATA_FROM_DB,
    payload: data,
  };
};

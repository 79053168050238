import React from "react";
import {  useIntl } from "react-intl";

const ActionStatus = (props) => {
  const { club, isBikerBed } = props;

  const intl = useIntl();
  const isBB = () => isBikerBed();

  const textTour = intl.formatMessage({id:"Tours",defaultMessage:"Tours"});
  const descMembers = intl.formatMessage(isBB() ? {id:"Followers",defaultMessage:"Followers"} : {id:"Members", defaultMessage:"Members"})
  const tours = club.count_tours && club.count_tours!=='undefined' ? `${textTour}: `+club.count_tours : `${textTour}: 0`;

  return (
    <div style={{display: 'flex', justifyContent:"flex-start", width: '90%',alignItems: 'flex-end', height: 20}}>
      <div 
        className="preview-card-small-info" 
        style={{fontSize: "12px", color: 'gray',fontWeight: 'bold', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginRight: 5}}>
          {`${descMembers}: ${isBB() ? club.count_followers : club.count_members}`}
      </div>
      <div 
        className="preview-card-small-info" 
        style={{fontSize: "12px", color: 'gray',fontWeight: 'bold', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
          {tours}
        </div>
    </div>
  );
};

export default ActionStatus;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Typography, Card, Tooltip, Button } from 'antd';

import { clubAPI } from 'app/API/club.api';

import tourPrevAPI from 'app/API/tour.prev.api';

import { pageScope } from 'app/constants/mapConstants';

import { FormattedMessage, useIntl } from 'react-intl';

import { LengthType } from 'app/constants/user.constants';
import parse from 'html-react-parser';
import {
  BikerClubIcon,
  BikerBedIcon,
  ToursIcon,
  TrackIcon,
} from '../../../Desctop/MainMenu/svg';

import { handleClick } from '../../../Desctop/List/listTrackItem/listTrackItem';

import {
  getEntitiyLink,
  getImgBig,
  decodeSpecLetters,
  ConvertDistance,
} from '../../../Utility/utility';

import { DeleteIcon } from '../../../Desctop/TourFullView/components/svg';
import { deleteTrack } from '../../../Desctop/Track/trackPreview/trackPreview';

const ListItemMobile = (props) => {
  const { user } = useSelector((store) => store.authentication);
  const intl = useIntl();
  const { scope, item } = props;
  const [data, setData] = useState(item);
  const { title, id } = data;
  const [desc, setDesc] = useState(null);

  const scopeMain = props.item.scope ? props.item.scope : props.scope;

  useEffect(() => {
    if (scopeMain === pageScope.club || scopeMain === pageScope.bikerbed) {
      getData(item.id);
    } else if (scopeMain === pageScope.tour) {
      getDescription(item.id);
    }
    return () => {
      setDesc(null);
      setData({});
    };
  }, []);

  const getData = async function (id) {
    const { desc } = await clubAPI.getDescriptionForMobileList(id, scopeMain);
    setData({ ...data, description: desc, scope: scopeMain });
  };

  const getDescription = async function (id) {
    const { desc } = await tourPrevAPI.getDescription(id);
    setDesc(desc);
  };

  const getItemDescription = () => {
    if (scopeMain === pageScope.tour) {
      return typeof desc === 'string' ? (
        <Typography.Paragraph ellipsis={{ rows: 4 }}>
          <div dangerouslySetInnerHTML={{ __html: desc }} />
        </Typography.Paragraph>
      ) : (
        <Card bordered={false} loading className="descLoadMobile" />
      );
    }
    return (
      <Typography.Paragraph ellipsis={{ rows: 4 }}>
        {decodeSpecLetters(data.description)}
      </Typography.Paragraph>
    );
  };

  const getExtInfo = () => {
    switch (scopeMain) {
      case pageScope.club:
      case pageScope.bikerbed:
        return <div />;
      case pageScope.tour:
      case pageScope.track:
        return (
          <ConvertDistance
            distance={item.distance}
            duration={item.duration}
            type={user.units ? user.units : LengthType.KM}
          />
        );
    }
  };
  const getIcon = () => {
    switch (scopeMain) {
      case pageScope.club:
        return <BikerClubIcon />;
      case pageScope.bikerbed:
        return <BikerBedIcon />;
      case pageScope.tour:
        return <ToursIcon />;
      case pageScope.track:
        return <TrackIcon />;
    }
  };

  const GetLink = (props) => {
    return scopeMain === pageScope.track ? (
      <div onClick={() => handleClick({ ...data, scope: scopeMain })}>
        {props.children}
      </div>
    ) : (
      <Link to={getEntitiyLink(scopeMain, title, id)}>{props.children}</Link>
    );
  };

  const getDeleteButton = () => {
    switch (scopeMain) {
      case pageScope.track:
        return (
          <div style={{ display: 'flex', position: 'absolute', right: 7 }}>
            <Tooltip
              title={<FormattedMessage id="Delete" defaultMessage="Delete" />}
            >
              <Button
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: 'transparent',
                  border: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 0,
                }}
                className="deleteTour"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTrack(id, intl);
                }}
              >
                <DeleteIcon style={{ width: 14, height: 14 }} />
              </Button>
            </Tooltip>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <GetLink>
      <div className="mobileItemLayout">
        <div className="mainImage">{getImgBig(data)}</div>
        <div className="mainInfo">
          <div className="icon">{getIcon()}</div>
          <div className="title">{decodeSpecLetters(title)}</div>
          <div className="desc">
            {getItemDescription()}
            {getDeleteButton()}
          </div>
          <div className="footerItem">
            {getExtInfo()}
            <FormattedMessage id="More" defaultMessage="More" />
          </div>
        </div>
      </div>
    </GetLink>
  );
};

export default ListItemMobile;

import React from 'react';

import cancel from 'static/img/cancel.png';

import { RightOutlined } from '@ant-design/icons';

import '../../styles/closeBtn.css';

const CloseButton = (props) => {
  const { close = () => false, profile } = props;

  return (
    <div
      className="closeBtnLayout"
      onClick={() => close()}
      style={{ marginTop: 2 }}
      aria-hidden
    >
      {!profile ? (
        <img src={cancel} alt="close" />
      ) : (
        <RightOutlined style={{ color: '#aa2493', fontSize: 12 }} />
      )}
    </div>
  );
};

export default CloseButton;

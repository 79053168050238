import store from 'app/store';
import * as types from 'actions/action_types';
import { API } from 'app/API/manager';
import { trackAPI } from 'app/API/track.api';
import { clubAPI } from 'app/API/club.api';
import { placeAPI } from 'app/API/place.service.api';
import { previewAPI } from '../../../API/preview.api';

export const prevDispatch = (scope, id, hiperData) => {
  store.dispatch((dispatch) => {
    dispatch({
      type: types.FETCH_PREVIEW_DATA_HIPERSET,
      payload: { ...hiperData, scope },
    });
    dispatch({
      type: types.FETCH_PREVIEW_DATA,
      payload: clubAPI.previewBeds(id).then((res) => {
        return { ...res.response, scope };
      }),
    });
    dispatch({
      type: types.FETCH_PREVIEW_POLYLINE_FULFILLED,
      payload: {},
    });
  });
};

export function mouseoverTourClbck(en) {
  const scope = en.scope() === 'end' ? 'tour' : en.scope();
  const tourId = en.getId();
  const tourList = store.getState().mapDataReducer.data;
  const hiperPrevTourData =
    tourList[tourList.findIndex((t) => t.id === tourId)];

  /* to browser loaded a picture before view of it */
  // let hint = document.createElement("link");
  // hint.rel = "prefetch";
  // hint.as = "image";
  // hint.href = process.env.BACKEND_URL+hiperPrevTourData.image;
  // document.head.appendChild(hint);
  // setTimeout( ()=>{ document.head.removeChild(hint) }, 1000);
  /*   ***   */

  setTimeout(() => {
    if (en.isMouseOver()) {
      const prevTour = store.getState().fetchPreviewData.data;
      if (!prevTour || prevTour.id !== tourId) {
        store.dispatch((dispatch) => {
          dispatch({
            type: types.FETCH_PREVIEW_DATA_HIPERSET,
            payload: { ...hiperPrevTourData, scope },
          });
          dispatch({
            type: types.FETCH_PREVIEW_DATA,
            payload: previewAPI.getData(scope, tourId).then((resp) => {
              if (!resp.data.response.image && hiperPrevTourData.image) {
                resp.data.response.image = hiperPrevTourData.image;
              }
              return { ...resp.data.response, scope };
            }),
          });
          dispatch({
            type: types.FETCH_PREVIEW_POLYLINE,
            payload: previewAPI.getPoly(scope, tourId).then((resp) => {
              const result = resp.data.response;
              return result;
            }),
          });
        });
      }
    }
  }, 400);
}
export function clickTourIconClbck(en) {
  const scope = en.scope();
  const tourId = en.getId();
  const tourList = store.getState().mapDataReducer.data;
  const hiperPrevTourData =
    tourList[tourList.findIndex((t) => t.id === tourId)];

  const prevTour = store.getState().fetchPreviewData.data;
  if (!prevTour || prevTour.id !== tourId) {
    store.dispatch((dispatch) => {
      dispatch({
        type: types.FETCH_PREVIEW_DATA_HIPERSET,
        payload: { ...hiperPrevTourData, scope },
      });
      dispatch({
        type: types.FETCH_PREVIEW_DATA,
        payload: API.way.tour.getOne('preview', { id: tourId }).then((resp) => {
          return { ...resp.data.response, scope };
        }),
      });
      dispatch({
        type: types.FETCH_PREVIEW_POLYLINE,
        payload: API.way.tour
          .getOne('aproxPoly', { with_start: 1, id: tourId })
          .then((resp) => {
            const result = resp.data.response;

            return result;
          }),
      });
    });
  }
}
export function prevTourClbk(scope, tourId) {
  const view = 'view';
  const prevTour = store.getState().fetchPreviewData.data;

  if (!prevTour || prevTour.id !== tourId) {
    store.dispatch((dispatch) => {
      dispatch({
        type: types.FETCH_PREVIEW_DATA_FULFILLED,
        payload: {},
      });
      dispatch({
        type: types.FETCH_PREVIEW_DATA,
        payload: API.way.tour.getOne('preview', { id: tourId }).then((resp) => {
          return { ...resp.data.response, scope, view };
        }),
      });
      dispatch({
        type: types.FETCH_PREVIEW_POLYLINE,
        payload: API.way.tour
          .getOne('aproxPoly', { with_start: 1, id: tourId })
          .then((resp) => resp.data.response),
      });
    });
  }
}

export function mouseoverClubClbck(en) {
  const scope = en.scope();
  const clubId = en.getId();
  const clubList = store.getState().mapDataReducer.data;
  const hiperPrevClubData =
    clubList[clubList.findIndex((t) => t.id === clubId)];
  const prevTour = store.getState().fetchPreviewData.data;
  if (!prevTour || prevTour.id !== clubId) {
    setTimeout(() => {
      if (en.isMouseOver()) {
        prevDispatch(scope, clubId, hiperPrevClubData);
      }
    }, 400);
  }
}
export function clickClubMarkerClbck(en) {
  const scope = en.scope();
  const clubId = en.getId();
  const clubList = store.getState().mapDataReducer.data;
  const hiperPrevClubData =
    clubList[clubList.findIndex((t) => t.id === clubId)];
  const prevTour = store.getState().fetchPreviewData.data;
  if (!prevTour || prevTour.id !== clubId) {
    prevDispatch(scope, clubId, hiperPrevClubData);
  }
}
export function prevClubClbck(scope, clubId) {
  const view = 'view';
  const prevTour = store.getState().fetchPreviewData.data;
  if (!prevTour || prevTour.id !== clubId) {
    store.dispatch((dispatch) => {
      dispatch({
        type: types.FETCH_PREVIEW_DATA_FULFILLED,
        payload: {},
      });
      dispatch({
        type: types.FETCH_PREVIEW_DATA,
        payload: clubAPI.previewClub(clubId).then((res) => {
          return { ...res.response, scope, view };
        }),
      });
      dispatch({
        type: types.FETCH_PREVIEW_POLYLINE_FULFILLED,
        payload: {},
      });
    });
  }
}

export function mouseoverBikerBedClbck(en) {
  const scope = en.scope();
  const bedId = en.getId();
  const bedList = store.getState().mapDataReducer.data;
  const hiperPrevBedData = bedList[bedList.findIndex((t) => t.id === bedId)];
  const prevBed = store.getState().fetchPreviewData.data;
  if (!prevBed || prevBed.id !== bedId) {
    setTimeout(() => {
      if (en.isMouseOver()) {
        prevDispatch(scope, bedId, hiperPrevBedData);
      }
    }, 400);
  }
}
export function clickBikerBedMarkerClbck(en) {
  const scope = en.scope();
  const bedId = en.getId();
  const bedList = store.getState().mapDataReducer.data;
  const hiperPrevBedData = bedList[bedList.findIndex((t) => t.id === bedId)];
  const prevBed = store.getState().fetchPreviewData.data;
  if (!prevBed || prevBed.id !== bedId) {
    prevDispatch(scope, bedId, hiperPrevBedData);
  }
}
export function prevBedClbck(scope, bedId) {
  const view = 'view';
  const prevBed = store.getState().fetchPreviewData.data;
  if (!prevBed || prevBed.id !== bedId) {
    store.dispatch((dispatch) => {
      dispatch({
        type: types.FETCH_PREVIEW_DATA_FULFILLED,
        payload: {},
      });
      dispatch({
        type: types.FETCH_PREVIEW_DATA,
        payload: clubAPI.previewBeds(bedId).then((res) => {
          return { ...res.response, scope, view };
        }),
      });
      dispatch({
        type: types.FETCH_PREVIEW_POLYLINE_FULFILLED,
        payload: {},
      });
    });
  }
}

export function mouseoverTrackClbck(en) {
  const scope = en.scope();
  const trackId = en.getId();
  const trackList = store.getState().mapDataReducer.data;
  const hiperPrevTrackData =
    trackList[trackList.findIndex((t) => t.id === trackId)];
  const prevTrack = store.getState().fetchPreviewData.data;

  if (!prevTrack || prevTrack.id !== trackId) {
    setTimeout(() => {
      if (en.isMouseOver()) {
        store.dispatch((dispatch) => {
          dispatch({
            type: types.FETCH_PREVIEW_DATA_FULFILLED,
            payload: { ...hiperPrevTrackData, scope },
          });
          dispatch({
            type: types.FETCH_PREVIEW_POLYLINE,
            payload: trackAPI
              .getAproxDataTrack(trackId)
              .then((res) => res.response),
          });
        });
      }
    }, 400);
  }
}
export function clickTrackMarkerClbck(en) {
  const scope = en.scope();
  const trackId = en.getId();
  const trackList = store.getState().mapDataReducer.data;
  const hiperPrevTrackData =
    trackList[trackList.findIndex((t) => t.id === trackId)];
  const prevTrack = store.getState().fetchPreviewData.data;

  if (!prevTrack || prevTrack.id !== trackId) {
    store.dispatch((dispatch) => {
      dispatch({
        type: types.FETCH_PREVIEW_DATA_FULFILLED,
        payload: { ...hiperPrevTrackData, scope },
      });
      dispatch({
        type: types.FETCH_PREVIEW_POLYLINE,
        payload: trackAPI
          .getAproxDataTrack(trackId)
          .then((res) => res.response),
      });
    });
  }
}

export function mouseoverPlaceClbck(en) {
  const scope = en.scope();
  const placeId = en.getId();
  // const placeList = store.getState().mapDataReducer.data;
  // const hiperPrevTourData =
  //   placeList[placeList.findIndex((t) => t.id === placeId)];

  setTimeout(() => {
    if (en.isMouseOver()) {
      const prevTour = store.getState().fetchPreviewData.data;
      if (!prevTour || prevTour.id !== placeId) {
        store.dispatch((dispatch) => {
          // dispatch({
          //   type: types.FETCH_PREVIEW_DATA_HIPERSET,
          //   payload: { ...hiperPrevTourData, scope },
          // });
          dispatch({
            type: types.FETCH_PREVIEW_DATA,
            payload: placeAPI.preview(placeId).then((res) => {
              return { ...res.response, scope };
            }),
          });
          dispatch({
            type: types.FETCH_PREVIEW_POLYLINE,
            payload: placeAPI.preview(placeId).then((res) => {
              return { ...res.response, scope };
            }),
          });
        });
      }
    }
  }, 400);

  // const scope = en.scope();
  // const placeId = en.getId();
  // const placeList = store.getState().mapDataReducer.data;
  // const hiperPrevPlaceData =
  //   placeList[placeList.findIndex((t) => t.id === placeId)];
  // const prevPlace = store.getState().fetchPreviewData.data;
  // if (!prevPlace || prevPlace.id !== placeId) {
  //   setTimeout(() => {
  //     if (en.isMouseOver()) {
  //       store.dispatch({
  //         type: types.FETCH_PREVIEW_DATA_FULFILLED,
  //         payload: { ...hiperPrevPlaceData, scope },
  //       });
  //     }
  //   }, 400);
  // }
}

import React, { useState, useEffect } from 'react';

import store from 'app/store';

import { Avatar, Spin, Checkbox, message } from 'antd';
import { LoadingOutlined, QuestionOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';

import defaultLogo from 'static/img/Tourstart_org.jpg';
import { FormattedMessage, useIntl } from 'react-intl';
import mini from '../../../static/img/miniature.png';
import review from '../../../static/img/preview/reviews.png';
import download from '../../../static/img/preview/download.png';
import pdfdownload from '../../../static/img/preview/pdfdownload.png';

import {
  ScenicSvg,
  SeasideSvg,
  StraightSvg,
  TwistedSvg,
  MountainSvg,
} from '../Desctop/MainMenu/svg';
import { openNotificationWithIcon } from './notificationMessage';

export const decodeSpecLetters = (str) =>
  str && typeof str === 'string' ? parse(str) : '';

export const getImgBig = (props, style = {}) => {
  const getImageBig =
    props.image || props.logo ? (
      <img
        src={process.env.BACKEND_URL + (props.image || props.logo)}
        alt=""
        style={style}
      />
    ) : (
      <img src={defaultLogo} alt="logo" style={style} />
    );
  return getImageBig;
};
export const getImgBigClub = (props) => {
  const getImageBig = props.logo ? (
    <img src={props.logo} alt="logo" />
  ) : (
    <Avatar
      shape="square"
      size={120}
      icon={<QuestionOutlined />}
      className="none_preview_image"
    />
  );
  return getImageBig;
};

export const getSmallImg = (props) => {
  if (props.images && props.images.length > 0) {
    const imgUrl = [];
    if (props.images.length > 3) {
      for (let i = 1; i <= 3; i++) {
        imgUrl.push(props.images[i]);
      }
      return imgUrl.map((items) => (
        <img src={process.env.BACKEND_URL + items} alt="" key={items} />
      ));
    }
    for (let i = 0; i < props.images.length; i++) {
      imgUrl.push(props.images[i]);
    }
    return imgUrl.map((items) => (
      <img src={process.env.BACKEND_URL + items} alt="" key={items} />
    ));
  }
  return false;
};

export const getSmallImages = (props, fetching) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  const getSmallImage =
    props.images && props.images.length > 1 ? (
      <div className="tourPreviewBodyImgSmall">{getSmallImg(props)}</div>
    ) : (
      <div style={{ width: 0 }} />
    );
  const img =
    fetching === true ? (
      <div
        style={{
          width: 150,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Spin indicator={antIcon} style={{ position: 'relative', left: 50 }} />
      </div>
    ) : (
      getSmallImage
    );
  return img;
};
export const getImageMobilePreview = (props) => {
  const imgUrl = [];
  if (props.images && props.images.length > 1) {
    if (props.images.length > 4) {
      for (let i = 0; i <= 3; i++) {
        imgUrl.push(props.images[i]);
      }
    } else {
      for (let i = 0; i < props.images.length; i++) {
        imgUrl.push(props.images[i]);
      }
    }
  } else if (props.images && props.images.length === 1) {
    imgUrl.push(
      props.images[0],
      '/uploaded_content/e/3/prev/e36845d7240ad22cd4c59fb587f4f7d6.jpg'
    );
  } else {
    imgUrl.push(
      '/uploaded_content/e/3/prev/e36845d7240ad22cd4c59fb587f4f7d6.jpg',
      '/uploaded_content/e/3/prev/e36845d7240ad22cd4c59fb587f4f7d6.jpg'
    );
  }
  return imgUrl;
};
export const getTitle = (props) => {
  const title = props.title ? (
    <span>{decodeSpecLetters(props.title).toUpperCase()}</span>
  ) : (
    <span>
      <FormattedMessage id="TOUR" defaultMessage="TOUR" />
    </span>
  );
  return title;
};
export const getDescription = (props) => {
  const description =
    props.description && props.description.length !== 0
      ? props.description
      : 'DESCRIPTION';
  return description;
};
export const getShortDescription = (props) => {
  const shortDescription = props.shortdescription
    ? props.shortdescription
    : 'DESCRIPTION';
  return shortDescription;
};

const getHourT = () => {
  return () => useIntl().formatMessage({ id: 'h', defaultMessage: 'h' });
};
const getMinT = () => {
  return () => useIntl().formatMessage({ id: 'min', defaultMessage: 'min' });
};

export const converte = (props) => {
  const dist = 'km';
  const hourT = getHourT();
  const minT = getMinT();
  let hour;

  const getUnit = () => {
    if ((!dist && dist === undefined) || !props || !props.distance) {
      return 0;
    }
    if (dist === 'km') {
      return Math.floor(props.distance / 1000);
    }
    return Math.round(props.distance * 0.000621371192);
  };

  const unitOfLenght = getUnit();

  if (props && props.duration) {
    const sec = props.duration;
    const h = (sec / 3600) ^ 0; // eslint-disable-line no-bitwise
    const m = ((sec - h * 3600) / 60) ^ 0; // eslint-disable-line no-bitwise
    hour = `${h} ${hourT()} ${m} ${minT()}`;
  } else {
    hour = `${0 + hourT()} ${0}${minT()}`;
  }
  const Measure = {
    unitOfLenght,
    hour,
  };
  return Measure;
};
export const getInfo = (props) => {
  const measure = converte(props);
  return measure;
};
export const downloadNumber = (props) => {
  return (
    <div className="downloadNumber">
      <div className="view">
        <img src={review} alt="view" style={{ width: 15, height: 11 }} />
        <span style={{ fontSize: 10 }}>{props.view}</span>
      </div>
      <div className="download">
        <img src={download} alt="download" style={{ width: 13, height: 15 }} />
        <span style={{ fontSize: 10 }}>{props.download}</span>
      </div>
      <div className="pdf">
        <img src={pdfdownload} alt="pdf" style={{ width: 13, height: 16 }} />
        <span style={{ fontSize: 10 }}>{props.pdf}</span>
      </div>
    </div>
  );
};

// Tour Type
export const detectArrowTourType = (trip) => {
  const resp = {
    isScenic: false,
    isMountain: false,
    isSeaside: false,
    isTwisted: false,
    isStraight: false,
  };
  let numbersArrowType = [];
  if (trip && trip !== '0') {
    const intChar = parseInt(trip);
    const bit = intChar.toString(2);
    numbersArrowType = bit.split('');
    numbersArrowType = numbersArrowType.reverse();

    if (numbersArrowType[0] && numbersArrowType[0] !== '0')
      resp.isScenic = true;
    if (numbersArrowType[1] && numbersArrowType[1] !== '0')
      resp.isMountain = true;
    if (numbersArrowType[2] && numbersArrowType[2] !== '0')
      resp.isSeaside = true;
    if (numbersArrowType[3] && numbersArrowType[3] !== '0')
      resp.isTwisted = true;
    if (numbersArrowType[4] && numbersArrowType[4] !== '0')
      resp.isStraight = true;
  }

  return resp;
};

export const detectArrowTypeCode = (trip) => {
  const data = [];
  let numbersArrowType = [];
  if (trip && trip !== '0') {
    const intChar = parseInt(trip);
    const bit = intChar.toString(2);
    numbersArrowType = bit.split('');
    numbersArrowType = numbersArrowType.reverse();

    if (numbersArrowType[0] && numbersArrowType[0] !== '0') data.push(1);
    if (numbersArrowType[1] && numbersArrowType[1] !== '0') data.push(2);
    if (numbersArrowType[2] && numbersArrowType[2] !== '0') data.push(4);
    if (numbersArrowType[3] && numbersArrowType[3] !== '0') data.push(8);
    if (numbersArrowType[4] && numbersArrowType[4] !== '0') data.push(16);
  }
  return data;
};
// eslint-disable-next-line
export const tourType = ({ arrow_type }) => {
  const { isScenic, isMountain, isSeaside, isTwisted, isStraight } =
    detectArrowTourType(arrow_type);
  return (
    <>
      <div>
        <Checkbox checked={isSeaside} value="checkedSea" disabled>
          <SeasideSvg />
        </Checkbox>
      </div>
      <div>
        <Checkbox checked={isMountain} value="checkedMount" disabled>
          <MountainSvg />
        </Checkbox>
      </div>
      <div>
        <Checkbox checked={isScenic} value="ckeckedScenic" disabled>
          <ScenicSvg />
        </Checkbox>
      </div>
      <div>
        <Checkbox checked={isStraight} value="checkedStraight" disabled>
          <StraightSvg />
        </Checkbox>
      </div>
      <div>
        <Checkbox checked={isTwisted} value="ckeckedTwisted" disabled>
          <TwistedSvg />
        </Checkbox>
      </div>
    </>
  );
};

// miniature tour
export const miniatureTour = () => {
  return (
    <div className="miniature">
      <img src={mini} alt="mini" />
    </div>
  );
};

// add to download
export const addToDownload = (
  e,
  basketData,
  itemData,
  openModalTourDownload,
  TourDownloadBasketData,
  TourDownloadCoord,
  messageText,
  scope
) => {
  console.log(itemData);
  const isHave = basketData.some((item) => +item.id === +itemData.id);
  if (isHave === false) {
    openModalTourDownload(true);
    TourDownloadBasketData({ ...itemData, scope });
    TourDownloadCoord([+e.pageX, +e.pageY]);
  } else {
    // <Alert message="Alert Message Text" type="info" closable/>
    message.config({
      top: 90,
      duration: 3,
    });
    const text = messageText
      ? messageText.tourAdded
      : 'Already added to download';
    message.warning(text);
  }
}; //

// WINDOW WIDTH RESIZER
export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return width;
}
//

// MEAS
export const meas = (distance, duration) => {
  const intl = useIntl();
  const dist = 'km';
  const distT = intl.formatMessage({ id: 'km', defaultMessage: 'km' });
  const hourT = intl.formatMessage({ id: 'h', defaultMessage: 'h' });
  const minT = intl.formatMessage({ id: 'min', defaultMessage: 'min' });
  let hour;
  const getUnit = () => {
    if (!dist) {
      return 0;
    }
    if (dist === 'km') {
      return Math.floor(distance / 1000);
    }
    return Math.round(distance * 0.000621371192);
  };

  const unitOfLenght = getUnit();

  if (duration) {
    const sec = duration;
    const h = (sec / 3600) ^ 0; // eslint-disable-line no-bitwise
    const m = ((sec - h * 3600) / 60) ^ 0; // eslint-disable-line no-bitwise

    hour = `${h} ${hourT} ${' '} ${m} ${minT}`;
  } else {
    hour = `${0} ${hourT} ${0} ${minT}`;
  }

  const type = <div>{`${unitOfLenght} ${distT} |${hour}`}</div>;

  return type;
};

export const converteDistanceByType = (type, distance) =>
  type === 'miles'
    ? Math.round(distance * 0.000621371192)
    : Math.floor(distance / 1000);

export const getTime = (duration, tr) => {
  const hourT = tr.h;
  const minT = tr.min;
  let hour;
  if (duration) {
    const sec = duration;
    const h = (sec / 3600) ^ 0; // eslint-disable-line no-bitwise
    const m = ((sec - h * 3600) / 60) ^ 0; // eslint-disable-line no-bitwise

    hour = `${h} ${hourT} ${' '} ${m} ${minT}`;
  } else {
    hour = `${0} ${hourT}`;
  }

  return hour;
};

export const ConvertDistance = (props) => {
  const { type, distance, duration, className, style } = props;
  const intl = useIntl();
  const stringT = {
    M: intl.formatMessage({ id: 'miles', defaultMessage: 'miles' }),
    KMS: intl.formatMessage({ id: 'km', defaultMessage: 'km' }),
    H: intl.formatMessage({ id: 'h', defaultMessage: 'h' }),
    MIN: intl.formatMessage({ id: 'min', defaultMessage: 'min' }),
    KM: intl.formatMessage({ id: 'kilometers', defaultMessage: 'kilometers' }),
  };
  const { H, MIN, KMS, M } = stringT;
  let hour;
  const unitOfLenght =
    type === M
      ? `${Math.round(distance * 0.000621371192)} ${M}`
      : `${Math.floor(distance / 1000)} ${KMS}`;

  if (duration) {
    const sec = duration;
    const h = (sec / 3600) ^ 0; // eslint-disable-line no-bitwise
    const m = ((sec - h * 3600) / 60) ^ 0; // eslint-disable-line no-bitwise

    hour = `${h} ${H} ${m} ${MIN}`;
  } else {
    hour = `0 ${H} 0 ${MIN}`;
  }

  return (
    <div style={style || {}} className={className}>
      {`${unitOfLenght} |${hour}`}
    </div>
  );
};
//

export const urlTitle = (props) => {
  if (props.title) {
    return props.title.replace(/[^\w\s]/gi, '').replace(/ /g, '_');
  }
  return '';
};

export const removeSpecChars = (string) => {
  if (!string) {
    return 'entity';
  }
  /* eslint-disable */
  if (typeof string.replaceArray !== 'function') {
    String.prototype.replaceArray = function (find, replace) {
      let replaceString = this;
      for (let i = 0; i < find.length; i++) {
        replaceString = replaceString.replace(find[i], replace);
      }
      return replaceString;
    };
  }
  /* eslint-enable */
  const stopSymbols = [
    'x27',
    'x22',
    'x60',
    '\t',
    '\n',
    '\r',
    "'",
    '"',
    ',',
    '.',
    '/',
    '«',
    '»',
    '#',
    ';',
    ':',
    '@',
    '~',
    '[',
    ']',
    '{',
    '}',
    '=',
    '-',
    '+',
    ')',
    '(',
    '*',
    '&',
    '^',
    '%',
    '$',
    '<',
    '>',
    '',
    '?',
    '!',
    '|',
    '\\',
  ];
  string = string.replaceArray(stopSymbols, '').replace(/\s/g, '_');
  return string || 'entity';
};

export const getUserLink = ({ name, id }) =>
  name && id ? `/member/profile/${removeSpecChars(name)}_${id}` : '/';

export const getTourLink = (title, id, shareKey) => {
  const key = shareKey ? `?share_key=${shareKey}` : '';
  return `/tour/${removeSpecChars(title)}_${id}${key}`;
};

export const getEntitiyLink = (scope, title, id, shareKey) => {
  const key = shareKey ? `?share_key=${shareKey}` : '';
  return `/${scope}/${removeSpecChars(title)}_${id}${key}`;
};

export const getAppTourLink = (id) =>
  `https://tourstart.org/tour/tourstart_${id}`;

export const getTourId = ({ location = {} }) =>
  location.pathname
    ? parseInt(
        location.pathname
          .replace(/[^\w\s]/gi, '')
          .replace(' ', '_')
          .split('_')
          .splice(-1)
          .pop()
      )
    : 0;

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          const [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
};

export const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const isEmptyArray = (arr) => arr.length === 0;

export const getEU = () => [
  'BE',
  'BG',
  'CZ',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'UK',
];

export const getAMC = () => [
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'EC',
  'FK',
  'GF',
  'GY',
  'GY',
  'PY',
  'PE',
  'SR',
  'UY',
  'VE',
  'AG',
  'AI',
  'AW',
  'BB',
  'BL',
  'BM',
  'BQ',
  'BS',
  'BZ',
  'CA',
  'CR',
  'CU',
  'CW',
  'DM',
  'DO',
  'GD',
  'GL',
  'GP',
  'GT',
  'HN',
  'HT',
  'JM',
  'KN',
  'KY',
  'LC',
  'MF',
  'MQ',
  'MS',
  'MX',
  'NI',
  'PA',
  'PM',
  'PR',
  'SV',
  'SX',
  'TC',
  'TT',
  'US',
  'VC',
  'VG',
  'VI',
];

export const isListExists = (sorce) =>
  sorce.location &&
  sorce.location.pathname &&
  sorce.location.pathname.indexOf('/list') > -1;

export const isImageExists = (item) => item.image && item.image !== '';

export const isWrongCoord = (point) => {
  if (
    ((typeof point.correctLat === 'number' &&
      typeof point.correctLng === 'number') ||
      (typeof point.correctLat === 'string' &&
        typeof point.correctLng === 'string')) &&
    !point.keppCurrentCoord
  ) {
    return true;
  }
  return false;
};

export const checkIfMainDomainAvilableInLink = (link) => {
  if (link.includes(process.env.BACKEND_URL)) {
    return link;
  }
  return process.env.BACKEND_URL + link;
};

export const isExistSub = (link) => link.split('.').length > 2;

export const getOSRMErrorMessage = () => {
  const mess = useIntl().formatMessage({
    id: 'Route mechanism restarts. This will take a few minutes',
    defaultMessage: 'Route mechanism restarts. This will take a few minutes',
  });

  openNotificationWithIcon('error', mess);
};

const getFoldersPlaces = () => {
  const { foldersPlace } = store.getState();
  return foldersPlace.map(({ tours }) => tours).flat();
};

export const filterFoldersPlaces = (places) => {
  const folderPlaces = getFoldersPlaces();
  return places.filter(({ id }) => !folderPlaces.includes(+id));
};

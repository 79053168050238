/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
export function getContainingTourFolders(folders, tourId) {
  return folders
    .map((item) => {
      const { tours, id } = item;
      if (tours.includes(+tourId)) {
        return `${id}`;
      }
    })
    .filter((id) => !!id);
}

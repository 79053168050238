import React from 'react';
//
import { useSelector } from 'react-redux';

// ANTD
import { Card, Button, Tooltip, Modal } from 'antd';
// UTILITY
import { changeTourMode } from 'actions/Map/tour.full.data.action';
import store, { history } from 'app/store';
import { FormattedMessage, useIntl } from 'react-intl';

import moment from 'moment';
import withTranslations from 'app/components/HOGs/withTranslations';
import { placeAPI } from 'app/API/place.service.api';
import { previewAPI } from 'app/API/preview.api';
import { applyingTypes } from 'app/constants/folder.constants';
import {
  getEntitiyLink,
  isImageExists,
  getImgBig,
} from '../../../Utility/utility';

import {
  PlaceTypes,
  PlaceIconType,
} from '../../../../constants/place.constant';

import { PlacesIcon } from '../../MainMenu/svg';
import { accessType as typer } from '../../../../constants/tourAccessibilityTypes.constants';
import { isTourPublic } from '../../../../constants/tourAccessibilityTypes.constants';
import * as types from '../../../../actions/action_types';
import CountryFlag from '../countryFlag';
import { ErrorBoundary } from '../../../Utility/errorBoundary';

import {
  PrivateTourIcon,
  DeleteIcon,
  EditIcon,
} from '../../TourFullView/components/svg';
import { pageScope, URL } from '../../../../constants/mapConstants';
import ItemTypeListLayout from '../listComponents/listItemLayout';

import pageCache from '../../../../API/cache.service';
import { deleteTourDay } from '../../../Utility/deleteTour';
import { StarFilledIcon } from '../../TourstartAcademy/academy.svg';
import FolderMenuButton from '../../Folders/FolderMenu';
import { getContainingTourFolders } from '../../Folders/folder.utils';

const { confirm } = Modal;

const TYPE_BLOCK = 'block';

let delay = null;
let actions = false;

export const AccessType = (item, style = {}) => {
  const { type } = item;
  return (
    !isTourPublic(type) && (
      <Tooltip
        title={<FormattedMessage id="Private" defaultMessage="Private" />}
      >
        <div style={style}>
          <PrivateTourIcon width="18" height="18" />
        </div>
      </Tooltip>
    )
  );
};

export const deleteAction = (item, intl, removeItemFromList) => {
  const deleteHandler = () => {
    // previewAPI.clearRequests();
    removeItemFromList(item.id);
    deleteTourDay({ data: item });
    store.dispatch((dispatch) => {
      dispatch({
        type: types.DELETE_MAP_ITEM,
        payload: item,
      });
      dispatch({ type: types.FETCH_PREVIEW_DATA_CLEANE });
      dispatch({ type: types.FETCH_PREVIEW_POLYLINE_CLEANE });
      pageCache.destroy(URL.TOUR.MY[0]);
    });
  };

  confirm({
    title: intl.formatMessage({
      id: 'Delete the tour?',
      defaultMessage: `Delete the tour?`,
    }),
    okText: intl.formatMessage({ id: 'Yes', defaultMessage: 'Yes' }),
    okType: 'danger',
    cancelText: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
    onOk() {
      deleteHandler();
    },
    onCancel() {
      return null;
    },
  });
};

const handleOnClick = (e, item = {}, intl, removeItemFromList) => {
  if (!actions) {
    if (delay) {
      clearTimeout(delay);
    } // Fix bug upload preview polyline after open tour;
    const targetClass = e.target.classList;
    if (targetClass.contains('editTour')) {
      e.preventDefault();
      history.push(
        getEntitiyLink('place', item.title, item.id, item.share_key)
      );
      setTimeout(() => {
        store.dispatch(changeTourMode(true));
      }, 1000);
    } else if (targetClass.contains('deleteTour')) {
      e.preventDefault();
      deleteAction(item, intl, removeItemFromList);
    } else if (targetClass.contains('changeFolder')) {
      e.preventDefault();
    } else if (
      targetClass.contains('changeFolder') ||
      e.target.parentNode.classList.contains('ant-checkbox-wrapper') ||
      e.target.parentNode.classList.contains('ant-checkbox') ||
      e.target.parentNode.classList.contains('folder-dropdown-item') ||
      targetClass.contains('folder-dropdown-item')
    ) {
      e.preventDefault();
    } else {
      clearTimeout(delay);
      store.dispatch({ type: types.FETCH_PREVIEW_DATA_CLEANE });
      previewAPI.clearRequests();
      history.push(
        getEntitiyLink('place', item.title, item.id, item.share_key)
      );
    }
  }
};

const scope = pageScope.place;

const handleMouseEnter = (props) => {
  const { item, previewJson } = props;

  if (delay) {
    clearTimeout(delay);
  }
  const tourId = item.id;

  delay = setTimeout(() => {
    const prevTour = previewJson;
    if (!prevTour || prevTour.id !== tourId) {
      store.dispatch((dispatch) => {
        dispatch({
          type: types.FETCH_PREVIEW_DATA,
          payload: placeAPI.preview(tourId).then((res) => {
            return { ...res.response, scope };
          }),
        });
        dispatch({
          type: types.FETCH_PREVIEW_POLYLINE,
          payload: placeAPI.preview(tourId).then((res) => {
            return { ...res.response, scope };
          }),
        });
      });
    }
  }, 500);
};

const handleMouseLeave = () => {
  if (delay) {
    clearTimeout(delay);
  }
};

const placePrivate = (accessType) => {
  return accessType === typer.DEFAULT_ONLY_FOR_ME_TYPE ||
    accessType === typer.MY_FRIENDS_AND_ME_TYPE ||
    accessType === typer.SELECT_FRIENDS_TYPE
    ? true
    : false;
};

const blockItem = (props, getDistAndDuration, intl, item) => {
  const imgStyle = isImageExists(item) ? {} : { objectPosition: 'bottom' };
  return (
    <Card
      bordered
      onClick={(e) => handleOnClick(e, item, intl)}
      onMouseEnter={() => handleMouseEnter(props)}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{ position: 'absolute', top: 150, right: 5 }}
        className="typeSvg"
      >
        <PlacesIcon />
      </div>

      <div className="ListBlockImg">{getImgBig(item, imgStyle)}</div>

      {item.real_country && (
        <ErrorBoundary errorView={<div style={{ display: 'none' }} />}>
          <CountryFlag country={item.real_country} />
        </ErrorBoundary>
      )}

      {item.star ? (
        <StarFilledIcon width="22px" height="22px" className="list_star" />
      ) : null}

      <div className="preview-card-info">
        <div
          className="preview-card-title"
          style={{
            fontSize: '14px',
            color: '#aa2493',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {item.title}
        </div>
        {getDistAndDuration()}
      </div>
    </Card>
  );
};

const hasUserPermition = (user, item) =>
  user.id && item.owner && user.id === item.owner;

const ListPlaceItem = (props) => {
  const { item, removeItemFromList, type } = props;
  const { category = null } = item.options;
  const intl = useIntl();
  const { user } = useSelector(({ authentication }) => authentication);

  const folders = useSelector(({ foldersPlace }) => foldersPlace);
  const selectedFolders = getContainingTourFolders(folders, item.id);

  const getDistAndDuration = () => (
    <div
      className="preview-card-small-info"
      style={{
        alignItems: 'center',
        fontSize: '12px',
        color: 'gray',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        lineHeight: '24px',
      }}
    >
      <span style={{ marginLeft: 3 }}>
        <img
          style={{ width: 14, height: 18, position: 'relative', bottom: 3 }}
          src={PlaceIconType[category]}
          alt=""
        />
        <span style={{ marginLeft: 3 }}>{PlaceTypes[category]}</span>
      </span>
    </div>
  );

  const getDateEdit = () => (
    <div className="edit">
      {item?.created_at && item?.created_at === item.updated_at
        ? `${intl.formatMessage({
            id: 'Created',
            defaultMessage: 'Created',
          })} ${moment(item.created_at).format('DD.MM.YYYY HH:MM')}`
        : `${intl.formatMessage({
            id: 'Edited',
            defaultMessage: 'Edited',
          })} ${moment(item.updated_at).format('DD.MM.YYYY HH:MM')}`}
    </div>
  );

  const folderMenuAction = (
    <div className="list_item_action_buttons">
      <FolderMenuButton
        tourId={item.id}
        folders={folders}
        selectedFolders={selectedFolders}
        applying={applyingTypes.PLACE_FOLDER}
      />
    </div>
  );

  const selectItem = (target) => {
    const isTargetItem =
      target.classList.contains('list_item_layout') ||
      target.parentNode.classList.contains('list_item_layout');

    if (isTargetItem) {
      handleMouseEnter(props);
    }
  };

  const getAction = () => (
    <div
      className="list_item_action_buttons"
      onMouseEnter={() => {
        clearTimeout(delay);
        actions = true;
      }}
      onMouseLeave={(e) => {
        selectItem(e.relatedTarget.parentNode);
        actions = false;
      }}
    >
      <FolderMenuButton
        tourId={item.id}
        folders={folders}
        selectedFolders={selectedFolders}
        applying={applyingTypes.PLACE_FOLDER}
      />

      <Tooltip title={<FormattedMessage id="Edit" defaultMessage="Edit" />}>
        <Button className="list_action_button editTour">
          <EditIcon style={{ width: 16, height: 16 }} />
        </Button>
      </Tooltip>
      <Tooltip title={<FormattedMessage id="Delete" defaultMessage="Delete" />}>
        <Button className="list_action_button deleteTour">
          <DeleteIcon style={{ width: 16, height: 16 }} />
        </Button>
      </Tooltip>
      {placePrivate(item.access) && (
        <Tooltip
          title={<FormattedMessage id="Private" defaultMessage="Private" />}
        >
          <Button style={{ paddingTop: 2 }} className="list_action_button">
            <PrivateTourIcon width="16" height="16" />
          </Button>
        </Tooltip>
      )}
    </div>
  );

  return type === TYPE_BLOCK ? (
    blockItem(props, getDistAndDuration, intl, item)
  ) : (
    <ItemTypeListLayout
      item={item}
      type="place"
      title={item.title}
      star={item.star}
      iconType={<PlacesIcon />}
      meas={getDistAndDuration()}
      edit={item.updated_at ? getDateEdit() : null}
      action={hasUserPermition(user, item) ? getAction() : folderMenuAction}
      handleMouseEnter={() => handleMouseEnter(props)}
      handleMouseLeave={() => handleMouseLeave()}
      handleOnClick={(e) => handleOnClick(e, item, intl, removeItemFromList)}
    />
  );
};

export default withTranslations(ListPlaceItem);

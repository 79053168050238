import React, { useRef, useState } from 'react';
import './members.css';
import { Link } from 'react-router-dom';
import { Input, Button, Empty, Spin, List, Card, Tooltip } from 'antd';

import store, { history } from 'app/store';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
// IMAGE
import default_logo from 'static/img/Tourstart_org.jpg';
import { friendshipAPI } from 'app/API/friendship.api';
import {
  SearchOutlined,
  UserDeleteOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import parse from 'html-react-parser';
import withTranslations from 'app/components/HOGs/withTranslations';
import { MemberIcon } from '../MainMenu/svg';

import { openNotificationWithIcon } from '../../Utility/notificationMessage';
import { checkIfMainDomainAvilableInLink } from '../../Utility/utility';
import { fixHttps } from '../../Utility/coreFunctions';

import { loginModalOpen } from '../../../actions/Login/login.action';
import CloseButton from '../../Utility/closeButton';
import { StarFilledIcon } from '../TourstartAcademy/academy.svg';

const MEMBER = 'member';
const FRIEND = 'friend';
const CLUB = 'club';
const STAR = 'star';

const ADD = 'add';
const CANCEL = 'cancel';
const REMOVE = 'delete';
const CLICK = 'click';

const listText = {
  emptyText: (
    <Empty
      description={<FormattedMessage id="No Data" defaultMessage="No Data" />}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  ),
};

const GetLoader = () => {
  return (
    <div
      className="loader"
      key={0}
      style={{
        width: '100%',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Loading ...
    </div>
  );
};

export const content = (props, getListItem) => {
  return (
    <div className="contentMainMember">
      <InfiniteScroll
        pageStart={1}
        loadMore={(page) => props.loadMore(page)}
        hasMore={props.hasMore}
        loader={<GetLoader />}
        useWindow={false}
      >
        <List
          dataSource={props.data}
          grid={{ gutter: 10, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6 }}
          renderItem={(item) => (
            <List.Item key={item.id}>{getListItem(item)}</List.Item>
          )}
          loading={props.handlerLoading}
          locale={listText}
        >
          {props.loadingFirst && (
            <div className="demo-loading-container">
              <Spin />
            </div>
          )}
        </List>
      </InfiniteScroll>
    </div>
  );
};

const Members = (props) => {
  const { tr, club } = props;
  const [isFocus, setIsFocus] = useState(false);

  const searchField = useRef(null);

  const setRequest = (item, status) => {
    const { updateData, page, searchText, getFriendList } = props;
    const { id } = item;

    switch (status) {
      case ADD:
        friendshipAPI
          .add(id)
          .then(() => {
            openNotificationWithIcon('success', tr.Sent);
            updateData(page, searchText);
          })
          .catch((err) => console.log(err));
        break;
      case CANCEL:
        friendshipAPI
          .cancel(id, '')
          .then(() => {
            openNotificationWithIcon('success', tr.Cancelled);
            updateData(page, searchText);
          })
          .catch((err) => console.log(err));
        break;
      case REMOVE:
        friendshipAPI
          .request(id, status)
          .then(() => {
            getFriendList(searchText);
          })
          .catch((err) => console.log(err));
        break;
      default:
        break;
    }
  };

  const handleClick = (e, item, scope) => {
    e.stopPropagation();
    const { isLoggedIn, profile, closeMemberList } = props;

    if (scope === ADD || scope === CANCEL) {
      if (isLoggedIn) {
        if (scope === ADD) {
          setRequest(item, ADD);
        } else if (scope === CANCEL) {
          setRequest(item, CANCEL);
        }
      } else {
        store.dispatch(loginModalOpen(true));
      }
      return;
    }

    if (scope === REMOVE) {
      setRequest(item, REMOVE);
      return;
    }

    if (scope === CLICK) {
      if (profile) {
        closeMemberList();
      } else {
        history.push(item.url);
      }
    }
  };

  const getStatusFriend = (item) => {
    const { friend, request, id } = item;
    const { authUserData } = props;

    if (authUserData && authUserData.id === id) {
      return (
        <Button
          className="memberBtn"
          style={{ margin: '20px 0px 10px 0px', fontWeight: 'bold' }}
        >
          {tr['Your profile']}
        </Button>
      );
    }
    if (!friend && !request) {
      return (
        <Button
          className="connectBtn add"
          style={{ margin: '20px 0px 10px 0px', fontWeight: 'bold' }}
          onClick={(e) => handleClick(e, item, ADD)}
        >
          {tr.Connect}
        </Button>
      );
    }
    if (request && !friend) {
      return (
        <Button
          className="connectBtn cancel"
          style={{ margin: '20px 0px 10px 0px', fontWeight: 'bold' }}
          onClick={(e) => handleClick(e, item, CANCEL)}
        >
          {tr['Cancel request']}
        </Button>
      );
    }
    if (!request && friend) {
      return (
        <Button
          className="memberBtn"
          style={{ margin: '20px 0px 10px 0px', fontWeight: 'bold' }}
        >
          {tr.Friends}
        </Button>
      );
    }
    return null;
  };

  const getMemberDeleteButton = (item) => {
    const style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '20px 0px 10px 10px',
      fontWeight: 'bold',
      minWidth: '30px',
      padding: '0px',
    };

    return (
      <Tooltip title={tr['Remove friendship']}>
        <Button
          className="remove_friendship"
          style={style}
          onClick={(e) => handleClick(e, item, REMOVE)}
        >
          <UserDeleteOutlined />
        </Button>
      </Tooltip>
    );
  };

  const getListItem = (item) => {
    const getPhoto = () => {
      if (club) {
        return item.avatar && item.avatar !== ''
          ? checkIfMainDomainAvilableInLink(fixHttps(item.avatar))
          : default_logo;
      }

      return item.photo && item.photo !== ''
        ? checkIfMainDomainAvilableInLink(fixHttps(item.photo))
        : default_logo;
    };

    return (
      <Card bordered onClick={(e) => handleClick(e, item, CLICK)}>
        {item.star ? (
          <StarFilledIcon width="40px" height="40px" className="member_star" />
        ) : null}
        <div style={{ position: 'absolute', top: 159, right: 10 }}>
          <MemberIcon />
        </div>
        <div className="MemberListBlockImg" style={{ width: '100%' }}>
          <div className="listBlockImgSlider">
            <img src={getPhoto()} alt="member" draggable="false" />
          </div>
        </div>
        <div style={{ width: '100%', padding: '0px 10px' }}>
          <div
            style={{
              fontSize: '16px',
              color: '#aa2493',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '90%',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            {parse(item.name ? item.name : '')}
          </div>
          {item.email ? (
            <div
              style={{
                fontSize: '14px',
                color: 'gray',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {item.email}
            </div>
          ) : null}
          <div
            style={{
              fontSize: '14px',
              color: 'gray',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: 21,
              maxWidth: '100%',
            }}
          >
            {`${tr['Member since']}: `}
            {moment(item.date).format('MMM D, YYYY')}
          </div>
          <div
            style={{
              fontSize: '14px',
              color: 'gray',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: 21,
              maxWidth: '100%',
            }}
          >
            {item.bike_name ? item.bike_name : ''}
          </div>
          <div style={{ display: 'flex' }}>
            {getStatusFriend(item)}
            {props.scope === FRIEND ? getMemberDeleteButton(item) : null}
          </div>
        </div>
      </Card>
    );
  };

  const resetSearch = () => {
    props.onChangeSearch('');
    setTimeout(() => {
      setIsFocus(true);
      searchField.current.focus();
    }, 210);
  };

  const getClose = () => {
    return props.profile || props.club ? (
      <CloseButton close={() => props.closeMemberList()} />
    ) : (
      <Link to="/">
        <CloseButton />
      </Link>
    );
  };

  const getClass = (className) =>
    props.profile ? `${className} profile ` : className;

  const GetFilters = () => {
    const { isLoggedIn, profile, club } = props;

    const getClass = (scope) => (scope === props.scope ? 'active' : '');

    return (
      <>
        <Button
          className={getClass(MEMBER)}
          onClick={() => props.changeScope(MEMBER)}
        >
          {tr.Members}
        </Button>
        {(isLoggedIn || profile) && (
          <Button
            className={getClass(FRIEND)}
            onClick={() => props.changeScope(FRIEND)}
          >
            {tr.Friends}
          </Button>
        )}
        {club && (
          <Button
            className={getClass(CLUB)}
            onClick={() => props.changeScope(CLUB)}
          >
            {tr.Club}
          </Button>
        )}
        <Button
          className={getClass(STAR)}
          onClick={() => props.changeScope(STAR)}
        >
          <StarFilledIcon width="20px" height="20px" />
        </Button>
      </>
    );
  };

  return (
    <>
      <div className={getClass('curtain')} />
      <div className={getClass('MemberLayout')}>
        <div className="memberGreenLine">
          <div className="member">
            <GetFilters />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!club && (
              <div className="button" style={{ marginRight: 20 }}>
                <Input
                  ref={searchField}
                  className="memberSearch"
                  placeholder={tr.SEARCH}
                  size="small"
                  value={props.searchText}
                  onChange={(e) => props.onChangeSearch(e.target.value)}
                  style={{ width: 200 }}
                  prefix={() => (
                    <SearchOutlined
                      className="filterRedo"
                      style={{ fontSize: 15, color: 'rgba(0, 0, 0, 0.25)' }}
                    />
                  )}
                  suffix={
                    isFocus && (
                      <Button
                        size="small"
                        shape="circle"
                        icon={<ReloadOutlined />}
                        style={{
                          border: 'none',
                          position: 'relative',
                          left: 7,
                        }}
                        onClick={resetSearch}
                      />
                    )
                  }
                  onFocus={() => setIsFocus(true)}
                  onBlur={() => setTimeout(() => setIsFocus(false), 200)}
                />
              </div>
            )}
            {getClose()}
          </div>
        </div>
        {content(props, getListItem)}
      </div>
    </>
  );
};

export default withTranslations(Members);

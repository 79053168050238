import { history } from 'app/store';
import { loginIsLogin, loginModalOpen } from 'app/actions/Login/login.action';
import { setStateUserEnter } from 'app/actions/Users/user.enter.action';
import pageCache from 'app/API/cache.service';
import { URL } from 'app/constants/mapConstants';
import { userConstants } from '../../constants/user.constants';
import userAPI from '../../API/user.service.api';
import { LETTER_SENT, START_LOGIN } from '../../constants/enter.user.constants';

import { getUserHistory } from './user.history.action';

function autologin(callback) {
  return (dispatch) => {
    function success(user) {
      return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure() {
      return { type: userConstants.LOGIN_FAILURE };
    }

    userAPI
      .login({ apiMethod: 'autologin' })
      .then(
        (data) => {
          callback();
          if (data && data.code === 200) {
            dispatch(success(data.response));

            dispatch(loginIsLogin(true));
            dispatch(loginModalOpen(false));
            dispatch(setStateUserEnter({ name: START_LOGIN }));
            dispatch(getUserHistory(data.response.id));
          } else {
            dispatch(failure());
          }
        },
        (error) => {
          console.info(`autologin ${error}`);
          if (process.env.NODE_ENV !== 'production') {
            dispatch(success(UserInfo.user));
            dispatch(loginIsLogin(true));
            dispatch(loginModalOpen(false));
            dispatch(setStateUserEnter({ name: START_LOGIN }));
          }
        }
      )
      .catch((error) => {
        console.info(error);
        callback();
      });
  };
}

function logout() {
  return (dispatch) => {
    userAPI
      .logout()
      .then(() => {
        dispatch({ type: userConstants.LOGOUT });
        history.push('/tour');
        pageCache.destroy(URL.CLUB.MY[0]);
        pageCache.destroy(URL.TOUR.MY[0]);
        pageCache.destroy(URL.BIKERBED.MY[0]);
        pageCache.destroy(URL.TOUR.FRIENDS[0]);
      })
      .then(() => {
        userAPI.logoutJWT();
      });
  };
}

function sendConfirmLetter(email) {
  return (dispatch) => {
    userAPI.sendConfirmLetter(email);
    dispatch(setStateUserEnter({ name: LETTER_SENT }));
  };
}

function goToStateByName(name, fn, params) {
  return (dispatch) => {
    if (name) dispatch(setStateUserEnter({ name }));
    else fn(dispatch, params);
  };
}

// function checkingEmail(email, prevState) {
//   return (dispatch) => {
//     dispatch(setStateUserEnter({ name: 'checkingEmail' }));
//     userAPI.checkUserByEmail(email).then(
//       (resp) => {
//         if (resp.code === 200) {
//           const { exist = 0, islegalon = 0 } = resp.response;
//           let state = {};
//           if (exist) {
//             state = {
//               name: PASSWORD,
//               isLegalOn: islegalon,
//             };
//           } else if (prevState === SUGGESTION) {
//             userAPI.sendConfirmLetter(email);
//             state = { name: LETTER_SENT };
//           } else {
//             state = { name: SUGGESTION };
//           }
//           dispatch(setStateUserEnter(state));
//         } else {
//           const state = {
//             name: 'start',
//             error: resp.message,
//           };
//           dispatch(setStateUserEnter(state));
//         }
//       },
//       (error) => {
//         dispatch(setStateUserEnter({ error }));
//       }
//     );
//   };
// }

export const userActions = {
  logout,
  autologin,
  sendConfirmLetter,
  goToStateByName,
};

// function register(user) {
//     return dispatch => {
//         dispatch(request(user));

//         userAPI.register(user)
//             .then(
//                 user => {
//                     dispatch(success());
//                     history.push('/login');
//                     dispatch(alertActions.success('Registration successful'));
//                 },
//                 error => {
//                     dispatch(failure(error));
//                     dispatch(alertActions.error(error));
//                 }
//             );
//     };

//     function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
//     function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
//     function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
// }

import * as types from '../action_types';

export const setProInitState = () => {
  return {
    type: types.PRO_DATA_INIT,
  };
};

export const setProDataPrice = (data) => {
  return {
    type: 'PRO_DATA_PRICE',
    payload: data,
  };
};

export const setProDataSelectedCurrency = (data) => {
  return {
    type: types.PRO_DATA_CHANGE_SELECTED_CURRENCY,
    payload: data,
  };
};

export const setProDataVoucherData = (data) => {
  return {
    type: types.PRO_DATA_CHANGE_VOUCHER_DATA,
    payload: data,
  };
};


import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { isMobile } from 'app/middleware/hooks';

function withMobileDetecting(WrappedComponent) {
  return (props) => {
    const isWidthLess768 = isMobile(768);
    return (
      <WrappedComponent
        isMobileDesign={isMobileOnly || isWidthLess768}
        {...props}
      />
    );
  };
}

export default withMobileDetecting;

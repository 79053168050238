import React from 'react';

import { useSelector } from 'react-redux';

import { Button, Upload, message } from 'antd';

import { FormattedMessage } from 'react-intl';

import withTranslations from 'app/components/HOGs/withTranslations';

import { clubAPI } from 'app/API/club.api';

import store from 'app/store';
import * as types from 'app/actions/action_types';

import CoverDefaultImage from 'static/img/Small.svg';

import ClubRequest from './BikeClubRequest';

const Cover = (props) => {
  const { tr } = props;

  const edit = useSelector((store) => store.clubFullDataReducer.isEditMode);

  const background = useSelector(
    (store) => store.clubFullDataReducer.data.background
  );

  const logo = useSelector((store) => store.clubFullDataReducer.data.logo);

  const editCover = (tr, background) => {
    const handleChange = (info) => {
      if (info.file.status === 'uploading') {
        return;
      }
      if (info.file.status === 'done' && info.file.response.code === 200) {
        message.success(
          `${info.file.name} ${tr['file uploaded successfully']}.`
        );

        if (info.file.response.response && info.file.response.response.id) {
          store.dispatch({
            type: types.CLUB_SET_COVER,
            payload: {
              id: info.file.response.response.id,
              url: info.file.response.response.original,
            },
          });
        }
      } else if (
        info.file.status === 'error' ||
        info.file.response.code === 400
      ) {
        if (info.file.response.code === 400) {
          message.error(info.file.response.message);
        } else {
          message.error(`${info.file.name} ${tr['file upload failed']}.`);
        }
      }
    };
    return (
      <>
        {background ? (
          <img
            src={
              typeof background === 'string'
                ? background
                : `${`${process.env.BACKEND_URL + background.url}`}`
            }
            alt=""
            className="coverEditImage"
          />
        ) : (
          <img src={CoverDefaultImage} alt="" className="coverEditImage" />
        )}
        <Upload
          name="avatar"
          listType="picture-card"
          className="cover-uploader"
          showUploadList={false}
          action={clubAPI.getChangedUploadCover()}
          onChange={handleChange}
        >
          <Button type="primary" className="uploadCoverBtn">
            <span>
              <FormattedMessage
                id="Upload cover"
                defaultMessage="Upload cover"
              />
            </span>
          </Button>
        </Upload>
      </>
    );
  };

  const viewCover = (background) => {
    return (
      <>
        <div className="cover_shadow" />

        <img
          src={background || CoverDefaultImage}
          alt=""
          className="cover_edit_image"
          style={{ objectFit: background ? 'cover' : 'initial' }}
        />

        <ClubRequest />
      </>
    );
  };

  const editLogo = (tr, logo) => {
    const handleChange = (info) => {
      if (info.file.status === 'done') {
        message.success(
          `${info.file.name} ${tr['file uploaded successfully']}`
        );
        if (info.file.response.response && info.file.response.response.id) {
          store.dispatch({
            type: types.CLUB_SET_LOGO,
            payload: {
              id: info.file.response.response.id,
              url: info.file.response.response.original,
            },
          });
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} ${tr['file upload failed']}.`);
      }
    };

    const getImg = () => {
      if (logo) {
        return (
          <img
            src={
              typeof logo === 'string'
                ? logo
                : `${process.env.BACKEND_URL + logo.url}`
            }
            alt=""
            className="avatar"
          />
        );
      }

      return <img src={CoverDefaultImage} alt="" className="avatar_default" />;
    };

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        onChange={handleChange}
        action={clubAPI.getChangedUploadLogo()}
        withCredentials
      >
        {getImg()}
      </Upload>
    );
  };

  return (
    <div className="header_layout">
      <div className="cover_layout">
        {edit ? editCover(tr, background) : viewCover(background)}
      </div>
      <div className="logo_layout">
        {edit ? (
          editLogo(tr, logo)
        ) : (
          <img
            src={logo || CoverDefaultImage}
            alt=""
            className="avatar"
            style={{ objectFit: logo ? 'cover' : 'initial' }}
          />
        )}
      </div>
    </div>
  );
};

export default withTranslations(Cover);

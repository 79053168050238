import React, {useEffect} from 'react';
//ROUTER
import { Link } from 'react-router-dom';
//IMAGE
import cancel from "static/img/cancel.png";

import CloseButton from '../../../Utility/closeButton';

import { RightOutlined } from '@ant-design/icons';

const ListCloseIcon = (props) => {
    const {profile} = props;

    const url = props.router.location.pathname
        .split("/")
        .slice(0, -1)
        .join("/");

    return(
        profile ? 
            <div className="close_btn_profile">
                <CloseButton close={() => profile()}  profile={profile}/>
            </div>
            :
            <Link to={`${url}`}>
                <CloseButton onClick={() => props.getPolySelectedTourAfterListClose()}/>
            </Link>
    )
}
export default ListCloseIcon;
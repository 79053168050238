export const mergeObjects = (obj1, obj2) => {
  let key;
  for (key in obj2) {
    if (obj2.hasOwnProperty(key) === false) continue;
    if (obj2[key]) obj1[key] = obj2[key];
  }
  return obj1;
};

const httpToHttps = (url) => url.replace(/^http:\/\//i, 'https://');

const httpsToHttp = (url) => url.replace(/^https:\/\//i, 'http://');

const isHttpsEnable = () => window.location.protocol === 'https:';

export const fixHttps = (url = '') =>
  isHttpsEnable() ? httpToHttps(url) : httpsToHttp(url);

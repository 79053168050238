import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Input, Button } from 'antd';

import '../../styles/modalPreviewVideo.css';

import { useSelector } from 'react-redux';

import { RightOutlined, LeftOutlined, CloseOutlined } from '@ant-design/icons';
import { openNotificationWithIcon } from './notificationMessage';
import { clubAPI } from '../../API/club.api';

const { TextArea } = Input;

const addFileId = (ids, id) => {
  if (ids) {
    id += `,${ids}`;
  }
  return id;
};

export const ModalUploadVideo = ({
  visible = false,
  setVisible,
  setVideosData,
  videosData,
  setImgIds,
  imgIds,
  tr,
}) => {
  const [inputValue, setInputValue] = useState(null);

  const videoAreaRef = useRef(null);

  const { data } = useSelector((store) => store.clubFullDataReducer);

  const getVideo = () => {
    if (inputValue) {
      const str_srcField = inputValue;
      const str = str_srcField.toLowerCase();
      const regexp = new RegExp();
      regexp.compile(
        /(youtube\.com\/(v\/([\w-]+)|watch\?v=([\w-]+)|embed\/([\w-]+)))|(youtu\.be\/([\w-]+))/gi
      );

      const m = regexp.exec(str);
      if (!m) {
        openNotificationWithIcon('warning', tr['Cannot load']);
        return;
      }
      const base = window.btoa(str_srcField);
      clubAPI.uploadClubVideo(base, data.id).then((res) => {
        const { code, response } = res;
        if (code === 200) {
          const { id, source } = response;
          setImgIds(addFileId(imgIds, id));
          setVideosData([{ id, source }, ...videosData]);
          setVisible(false);
          setInputValue(null);
        }
      });
    } else {
      videoAreaRef.current.focus();
      openNotificationWithIcon('warning', tr['Paste the code or link']);
      setInputValue(null);
    }
  };

  return (
    <Modal
      title={false}
      visible={visible}
      closable
      footer={false}
      destroyOnClose
      centered
      onCancel={() => setVisible(false)}
      zIndex={1052}
      width={400}
      wrapClassName="uploadVideoModalWrap"
      closeIcon={<CloseOutlined />}
    >
      <div className="uploadVideoModal">
        <div className="title">
          <h1>{tr['Add YouTube video']}</h1>
        </div>
        <div className="input">
          <TextArea
            placeholder="YouTube video - embedd code or link"
            onChange={(e) => setInputValue(e.target.value)}
            ref={videoAreaRef}
          />
          <Button type="primary" size="small" onClick={() => getVideo()}>
            {tr.Upload}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const ModalPreviewVideo = ({
  visible = false,
  setVisible,
  video_id,
  videosData,
  index = 0,
}) => {
  const [inw, setI] = useState(index);

  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;
    if (keyCode === 39) {
      setI((inw) => {
        if (inw === videosData.length - 1) {
          return 0;
        }
        return inw + 1;
      });
    } else if (keyCode === 37) {
      setI((inw) => {
        if (inw === 0) {
          return videosData.length - 1;
        }
        return inw - 1;
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const setPrevPic = () => {
    if (inw === 0) {
      setI(videosData.length - 1);
    } else {
      setI(inw - 1);
    }
  };

  const setNextPic = () => {
    if (inw === videosData.length - 1) {
      setI(0);
    } else {
      setI(inw + 1);
    }
  };

  return (
    <Modal
      title={false}
      visible={visible}
      closable
      footer={false}
      destroyOnClose
      centered
      onCancel={() => setVisible(false)}
      zIndex={1002}
      width="70%"
      style={{ width: '70%', height: '70%' }}
      wrapClassName="PreviewVideos"
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
      closeIcon={<CloseOutlined style={{ fontSize: '40px', color: '#fff' }} />}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          borderRadius: 15,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {inw === 0 ? null : (
          <LeftOutlined
            className="prevImgArrow left"
            onClick={() => setPrevPic()}
          />
        )}
        <iframe
          title={video_id}
          width="600"
          height="400"
          style={{ borderRadius: 15 }}
          src={`https://www.youtube.com/embed/${videosData[inw].source}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        {inw === videosData.length - 1 ? null : (
          <RightOutlined
            className="prevImgArrow right"
            onClick={() => setNextPic()}
          />
        )}
      </div>
    </Modal>
  );
};

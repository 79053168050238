import PropTypes from 'prop-types';

export const RouterProps = {
	action: PropTypes.string,
	location: PropTypes.shape({	
		hash: PropTypes.string,
		key:  PropTypes.string,
		pathname:  PropTypes.string,
		search: PropTypes.string,
		state: PropTypes.any,
	})
}

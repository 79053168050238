import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'react-redux';
import { loginModalOpen } from 'app/actions/Login/login.action';
import pageCache from 'app/API/cache.service';
import { URL } from 'app/constants/mapConstants';
import { requestClub } from '../../../../constants/club.constants';

import store, { history } from '../../../../store';
import { clubAPI } from '../../../../API/club.api';
import {
  cachHandler,
  openNotificationWithIcon,
} from '../../../Utility/notificationMessage';

const clearPageCache = () => {
  pageCache.destroy(URL.CLUB.ALL[0]);
  pageCache.destroy(URL.CLUB.MY[0]);
  pageCache.destroy(URL.CONTENT.BEST[0]);
};

export const joinClub = (isLoggedIn, id, req, resReq) => {
  if (isLoggedIn) {
    clubAPI
      .joinToClub(id)
      .then((res) => {
        const { code, message } = res;
        if (code === 200) {
          openNotificationWithIcon('success', message);
          req(id, resReq);
          clearPageCache();
        } else {
          cachHandler(res);
        }
      })
      .catch((err) => cachHandler(err));
  } else {
    store.dispatch(loginModalOpen(true));
  }
};

export const leaveClub = (isLoggedIn, id, req, resReq) => {
  if (isLoggedIn) {
    clubAPI
      .leaveClub(id)
      .then((res) => {
        const { code, state } = res;
        if (code === 200) {
          openNotificationWithIcon('success', state);
          req(id, resReq);
          clearPageCache();
        } else {
          cachHandler(res);
        }
      })
      .catch((err) => cachHandler(err));
  } else {
    store.dispatch(loginModalOpen(true));
  }
};

export const getRequestInfo = (id, setRequestState) => {
  clubAPI
    .getRequestInfo(id)
    .then((res) => {
      const { code, response } = res;
      if (code === 200) {
        setRequestState(response.request_state);
      } else {
        console.log(res);
      }
    })
    .catch((err) => cachHandler(err));
};

const urlTitle = (title) => {
  if (title) {
    return title.replace(/[^\w\s]/gi, '').replace(/ /g, '_');
  }
  return '';
};

const ClubRequest = () => {
  const id = useSelector((store) => store.clubFullDataReducer.data.id);
  const title = useSelector((store) => store.clubFullDataReducer.data.title);
  const isLoggedIn = useSelector((store) => store.authentication.isLoggedIn);
  const isAdmin = useSelector(
    (store) => store.clubFullDataReducer.data.isAdmin
  );
  const [requestState, setRequestState] = useState(false);

  useEffect(() => {
    if (id) getRequestInfo(id, setRequestState);
  }, [id, isAdmin]);

  switch (requestState) {
    case requestClub.CONFIRMED:
      if (isAdmin) {
        return (
          <Button
            type="primary"
            className="join_btn"
            onClick={() => history.push(`/club/edit/${urlTitle(title)}_${id}`)}
          >
            <span>
              <FormattedMessage id="Edit" defaultMessage="Edit" />
            </span>
          </Button>
        );
      }
      return (
        <Button
          type="primary"
          className="join_btn"
          onClick={() => {
            leaveClub(isLoggedIn, id, getRequestInfo, setRequestState);
          }}
        >
          <span>
            <FormattedMessage
              id="Leave Bike Club"
              defaultMessage="Leave Bike Club"
            />
          </span>
        </Button>
      );

    case requestClub.USER_REQUEST:
      return (
        <div className="confirmAwaitLayout">
          <span>
            <FormattedMessage
              id="Await confirmation"
              defaultMessage="Await confirmation"
            />
          </span>
          <Button
            type="primary"
            className="join_btn"
            onClick={() => {
              leaveClub(isLoggedIn, id, getRequestInfo, setRequestState);
            }}
            style={{ position: 'relative!important' }}
          >
            <span>
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </span>
          </Button>
        </div>
      );
    default:
      return (
        <Button
          type="primary"
          className="join_btn"
          onClick={() => {
            joinClub(isLoggedIn, id, getRequestInfo, setRequestState);
          }}
        >
          <span>
            <FormattedMessage id="Join" defaultMessage="Join" />
          </span>
        </Button>
      );
  }
};

export default ClubRequest;

import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';

import { Menu, Table, Select, Checkbox, Button, Popconfirm, Input } from 'antd';

import { clubAPI } from 'app/API/club.api';

import withTranslations from 'app/components/HOGs/withTranslations';

import checkPrevLink from 'app/components/Utility/checkPrevUrlLink';
import { removeSpecChars } from 'app/components/Utility/utility';
import { openNotificationWithIcon } from 'app/components/Utility/notificationMessage';
import CloseButton from '../../../Utility/closeButton';

import './admin.scss';

const { Option } = Select;

let timer = null;
const MenuManage = ({ tr, clubId, title }) => {
  const [current, setCurrent] = useState('2');

  const history = useHistory();

  const handleClick = (e) => {
    switch (e.key) {
      case '1':
        history.push({ pathname: `/club/${removeSpecChars(title)}_${clubId}` });
        return;
      case '2':
        setCurrent(e.key);
        history.push({ pathname: `/club/manageteamadvanced/${clubId}` });
        return;
      case '3':
        history.push({
          pathname: `/club/edit/${removeSpecChars(title)}_${clubId}`,
        });
        return;
      default:
        setCurrent(e.key);
        history.push({ pathname: `/club/managerelations/${clubId}` });
    }
  };

  const items = [
    {
      key: '1',
      label: tr.Home,
    },
    {
      key: '2',
      label: tr['Our team'],
    },
    {
      key: '3',
      label: tr.Edit,
    },
    {
      key: '4',
      label: tr['Bike club relations'],
    },
  ];

  return (
    <div className="manage_menu_layout">
      <Menu
        onClick={(e) => handleClick(e)}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
    </div>
  );
};

const Members = (props) => {
  const history = useHistory();

  const {
    tr,
    clubId,
    title,
    get_members,
    members,
    setMembers,
    pageMembers,
    totalPageMembers,
  } = props;

  const statuses = {
    confirmed: 'Confirmed',
    request: 'Pending',
    userrequest: 'Pending',
  };

  useEffect(() => {
    get_members();
  }, []);

  const saveClick = (confirmed, item) => {
    const data = {
      club_id: +clubId,
      user_id: item.id,
      position: item.role,
      isadmin: item.admin,
      update: confirmed,
    };

    clubAPI
      .accept(data)
      .then((res) => {
        const { code } = res;
        if (code === 200) {
          openNotificationWithIcon('success', tr.Success);
          get_members();
        }
      })
      .catch((err) => console.log(err));
  };

  const removeClick = (item) => {
    const data = {
      club_id: +clubId,
      user_id: item.id,
    };

    clubAPI
      .reject(data)
      .then((res) => {
        const { code } = res;
        if (code === 200) {
          openNotificationWithIcon('success', tr.Success);
          get_members();
        }
      })
      .catch((err) => console.log(err));
  };

  const pageChange = (page) => {
    get_members(page);
  };

  const onChange = (e) => {
    const val = e.target.value;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      const filters = { name: val };
      get_members(1, filters);
    }, 400);
  };

  const columns = [
    {
      title: (
        <Input
          size="small"
          placeholder={tr['Write a search word']}
          allowClear
          onChange={onChange}
          className="manage_input"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '180px',
      ellipsis: true,
      render: (text, record) => {
        return (
          <div className="name_layout">
            <img
              src={process.env.BACKEND_URL + record.avatar}
              className="name_img"
              alt=""
            />
            <span className="name_text">{text}</span>
          </div>
        );
      },
    },
    {
      title: tr.Country,
      dataIndex: 'country',
      key: 'country',
      align: 'center',
      width: '80px',
      ellipsis: true,
      render: (text) => text || '-',
    },
    {
      title: tr.Position,
      dataIndex: 'Position',
      key: 'position',
      align: 'center',
      width: '160px',
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <Select
            value={`${record.role}`}
            style={{ width: 120 }}
            size="small"
            onChange={(val) => {
              const newData = members.map((item) => {
                console.log(record, item);
                if (index === item.key) {
                  item.role = +val;
                }
                return item;
              });
              setMembers([...newData]);
            }}
          >
            <Option className="position_option" value="0">{` `}</Option>
            <Option className="position_option" value="1">
              Director
            </Option>
            <Option className="position_option" value="2">
              Asst. Director
            </Option>
            <Option className="position_option" value="3">
              Treasurer
            </Option>
            <Option className="position_option" value="4">
              TourMaster
            </Option>
            <Option className="position_option" value="5">
              Webmaster
            </Option>
            <Option className="position_option" value="6">
              Member
            </Option>
            <Option className="position_option" value="7">
              Member of the board
            </Option>
            <Option className="position_option" value="8">
              Secretary
            </Option>
            <Option className="position_option" value="9">
              Activities Officer
            </Option>
            <Option className="position_option" value="10">
              Membership Officer
            </Option>
            <Option className="position_option" value="11">
              Charity Committee Officer
            </Option>
            <Option className="position_option" value="12">
              Road Captain
            </Option>
          </Select>
        );
      },
    },
    {
      title: tr.Tours,
      dataIndex: 'count_tours',
      key: 'count_tours',
      align: 'center',
      width: '90px',
      ellipsis: true,
    },
    {
      title: tr.Status,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '140px',
      ellipsis: true,
      render: (text) => {
        return statuses[text];
      },
    },
    {
      title: tr.Administrator,
      dataIndex: 'admin',
      key: 'admin',
      align: 'center',
      width: '140px',
      ellipsis: true,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => {
            const newData = members.map((item) => {
              if (index === item.key) {
                item.admin = +e.target.checked;
              }
              return item;
            });
            setMembers([...newData]);
          }}
          checked={record.admin}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'empty',
      key: 'empty',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '300px',
      fixed: 'right',
      render: (text, record, index) => {
        const confirmed = record.status === 'confirmed';
        const request = record.status === 'request';

        return (
          <div className="actions">
            {!request && (
              <Button
                type="primary"
                className="actions_btn"
                onClick={() => saveClick(confirmed, members[index])}
              >
                {confirmed ? tr.Save : tr.Accept}
              </Button>
            )}
            <Popconfirm
              title={
                confirmed
                  ? tr['Do you want to remove this member?']
                  : tr['Do you want to reject this request?']
              }
              onConfirm={() => removeClick(members[index])}
              okText={tr.Yes}
              cancelText={tr.No}
            >
              <Button type="primary" className="actions_btn">
                {confirmed ? tr.Remove : tr.Decline}
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              className="actions_btn"
              onClick={() => {
                history.push({
                  pathname: `/member/profile/${removeSpecChars(
                    members[index].name || ''
                  )}_${members[index].id}`,
                });
              }}
            >
              {tr.Visit}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="members_layout">
      <div className="title">
        <span>{`${title} - `}</span>
        {tr['Members of our Bike Club']}
      </div>
      <div className="table">
        <Table
          columns={columns}
          dataSource={members}
          scroll={{ x: 1100 }}
          pagination={{
            hideOnSinglePage: true,
            current: pageMembers,
            total: totalPageMembers,
            onChange: pageChange,
          }}
        />
      </div>
    </div>
  );
};

const Users = (props) => {
  const history = useHistory();

  const {
    tr,
    clubId,
    get_users,
    get_members,
    users,
    setUsers,
    pageUsers,
    totalPageUsers,
  } = props;

  useEffect(() => {
    get_users();
  }, []);

  const onAdd = (item) => {
    const data = {
      club_id: +clubId,
      user_id: item.id,
      position: item.role,
      admin: item.admin,
    };

    clubAPI.add(data).then((res) => {
      const { code } = res;
      if (code === 200) {
        openNotificationWithIcon('success', tr.Success);
        get_users();
        get_members();
      }
    });
  };

  const pageChange = (page) => {
    get_users(page);
  };

  const onChange = (e) => {
    const val = e.target.value;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      const filters = { name: val };
      get_users(1, filters);
    }, 400);
  };

  const columns = [
    {
      title: (
        <Input
          size="small"
          placeholder={tr['Write a search word']}
          allowClear
          onChange={onChange}
          className="manage_input"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      width: '180px',
      render: (text, record) => {
        return (
          <div className="name_layout">
            <img
              src={process.env.BACKEND_URL + record.avatar}
              className="name_img"
              alt=""
            />
            <span className="name_text">{text}</span>
          </div>
        );
      },
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      align: 'center',
      width: '80px',
      ellipsis: true,
      render: (text) => text || '-',
    },
    {
      title: 'Set position',
      dataIndex: '',
      key: 'position',
      align: 'center',
      width: '160px',
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <Select
            value="0"
            style={{ width: 120 }}
            size="small"
            onChange={(val) => {
              const newData = users.map((item) => {
                console.log(record, item);
                if (index === item.key) {
                  item.role = +val;
                }
                return item;
              });
              setUsers([...newData]);
            }}
          >
            <Option className="position_option" value="0">{` `}</Option>
            <Option className="position_option" value="1">
              Director
            </Option>
            <Option className="position_option" value="2">
              Asst. Director
            </Option>
            <Option className="position_option" value="3">
              Treasurer
            </Option>
            <Option className="position_option" value="4">
              TourMaster
            </Option>
            <Option className="position_option" value="5">
              Webmaster
            </Option>
            <Option className="position_option" value="6">
              Member
            </Option>
            <Option className="position_option" value="7">
              Member of the board
            </Option>
            <Option className="position_option" value="8">
              Secretary
            </Option>
            <Option className="position_option" value="9">
              Activities Officer
            </Option>
            <Option className="position_option" value="10">
              Membership Officer
            </Option>
            <Option className="position_option" value="11">
              Charity Committee Officer
            </Option>
            <Option className="position_option" value="12">
              Road Captain
            </Option>
          </Select>
        );
      },
    },
    {
      title: 'Tours',
      dataIndex: 'count_tours',
      key: 'count_tours',
      align: 'center',
      width: '90px',
      ellipsis: true,
    },
    {
      title: 'Administrator',
      dataIndex: 'admin',
      key: 'admin',
      align: 'center',
      width: '140px',
      ellipsis: true,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => {
            const newData = users.map((item) => {
              if (index === item.key) {
                item.admin = +e.target.checked;
              }
              return item;
            });
            setUsers([...newData]);
          }}
          checked={record.admin}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'empty',
      key: 'empty',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '300px',
      fixed: 'right',
      render: (text, record, index) => (
        <div className="actions">
          <Button
            type="primary"
            className="actions_btn"
            onClick={() => onAdd(users[index])}
          >
            {tr.Add}
          </Button>
          <Button
            type="primary"
            className="actions_btn"
            onClick={() => {
              history.push({
                pathname: `/member/profile/${removeSpecChars(
                  users[index].name || ''
                )}_${users[index].id}`,
              });
            }}
          >
            {tr.Visit}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="members_layout">
      <div className="title">{tr['Tourstart members']}</div>
      <div className="table">
        <Table
          columns={columns}
          dataSource={users}
          scroll={{ x: 1100 }}
          pagination={{
            hideOnSinglePage: true,
            current: pageUsers,
            total: totalPageUsers,
            onChange: pageChange,
          }}
        />
      </div>
    </div>
  );
};

const ManageTeamAdvanced = ({ page, clubId, tr }) => {
  const previousLocation = useSelector(
    (store) => store.locationUrl.previousLocation
  );
  const currentLocation = useSelector(
    (store) => store.locationUrl.currentLocation
  );

  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');

  const [pageMembers, setPageMembers] = useState(1);
  const [totalPageMembers, setTotalPageMembers] = useState(0);
  const [members, setMembers] = useState([]);

  const [pageUsers, setPageUsers] = useState(1);
  const [totalPageUsers, setTotalPageUsers] = useState(0);
  const [users, setUsers] = useState([]);

  const get_title = async () => {
    await clubAPI
      .get_title(clubId)
      .then((res) => {
        const { code, response } = res;
        if (code === 200) {
          setTitle(response.title);
        } else {
          checkPrevLink(previousLocation, currentLocation);
        }
      })
      .catch((err) => {
        console.log(err);
        checkPrevLink(previousLocation, currentLocation);
      });
  };

  const is_admin = () => {
    clubAPI
      .is_admin(clubId)
      .then((res) => {
        const { code, response } = res;
        if (code === 200 && response.access) {
          setLoading(false);
        } else {
          checkPrevLink(previousLocation, currentLocation);
        }
      })
      .catch((err) => {
        console.log(err);
        checkPrevLink(previousLocation, currentLocation);
      });
  };

  const get_members = (page = 1, filters = {}) => {
    const data = {
      club_id: clubId,
      page,
      filters,
    };

    clubAPI.get_members(data).then((res) => {
      const { code, response } = res;
      if (code === 200) {
        const { entities, currentPage, countPage } = response;
        setMembers(
          entities.map((item, i) => {
            const data = {
              key: i,
              ...item,
            };

            return data;
          })
        );
        setPageMembers(currentPage);
        setTotalPageMembers(countPage);
      }
    });
  };

  const get_users = (page = 1, filters = {}) => {
    const data = {
      page,
      filters,
    };

    clubAPI.get_users(data).then((res) => {
      const { code, response } = res;
      if (code === 200) {
        const { entities, currentPage, countPage } = response;
        setUsers(
          entities.map((item, i) => {
            const data = {
              key: i,
              ...item,
            };

            return data;
          })
        );
        setPageUsers(currentPage);
        setTotalPageUsers(countPage);
      }
    });
  };

  useEffect(() => {
    is_admin();
    get_title();
  }, []);

  return (
    <>
      <div className="curtain" />
      <div className="admin_layout">
        <div className="admin_green_line">
          <div className="label">
            <Link to="#">{tr['Our team']}</Link>
          </div>
          <CloseButton
            close={() => checkPrevLink(previousLocation, currentLocation)}
          />
        </div>
        <div className="content_main_admin">
          {!loading && (
            <>
              <MenuManage tr={tr} clubId={clubId} page={page} title={title} />
              <Members
                tr={tr}
                clubId={clubId}
                title={title}
                get_members={get_members}
                members={members}
                setMembers={setMembers}
                pageMembers={pageMembers}
                totalPageMembers={totalPageMembers}
              />
              <Users
                tr={tr}
                clubId={clubId}
                title={title}
                get_users={get_users}
                get_members={get_members}
                users={users}
                setUsers={setUsers}
                pageUsers={pageUsers}
                totalPageUsers={totalPageUsers}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withTranslations(ManageTeamAdvanced);

import React from 'react';

// PROP TYPES
import PropTypes from 'prop-types';
import { List, Empty } from 'antd';

import { DownloadTourGpsProps } from '../../PropType/propTypesDownloadTourGps';
import { RouterProps } from '../../PropType/propTypesRouter';

// CSS
import './list.css';

import ListClubItem from './listClubItem/listClubItem';
import SortComponent from './listComponents/sort';
import ListTypeIcon from './listComponents/listTypeIcon';
import ListCloseIcon from './listComponents/listCloseIcon';
import ListTourItem from './listTourItem/listTourItem';
import ListTrackItem from './listTrackItem/listTrackItem';
import ListPlaceItem from './listPlaceItem/listPlaceItem';
import TourListMobile from '../../Mobile/List/listMobile';
import { pageScope } from '../../../constants/mapConstants';

// CONSTANT
const TYPE_BLOCK = 'block';

const listItemByType = (props, item) => {
  const scope = item.scope ? item.scope : props.scope;
  switch (scope) {
    case pageScope.club:
    case pageScope.bikerbed:
      return <ListClubItem {...props} item={item} />;
    case pageScope.tour:
      return <ListTourItem {...props} item={item} />;
    case pageScope.track:
      return <ListTrackItem {...props} item={item} />;
    case pageScope.place:
      return <ListPlaceItem {...props} item={item} />;
    default:
      return <ListTourItem {...props} item={item} />;
  }
};

const listText = ({ tr }) => {
  const data = {
    emptyText: (
      <Empty
        description={tr['Zoom out to see tours']}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    ),
  };
  return data;
};

// LIST RENDER
const listType = (props) => {
  if (props.type === TYPE_BLOCK) {
    return (
      <List
        grid={{ gutter: 10, column: 2 }}
        dataSource={props.displayItems()}
        className="listBlockType"
        loading={props.dataMapFetching}
        locale={listText(props)}
        renderItem={(item) => {
          return (
            <List.Item
              style={{ width: 176 }}
              key={item.id}
              draggable
              className={
                +item.id === +props.previewJson.id ? 'selectedListItem' : ''
              }
            >
              <div ref={(link) => props.setSelectedEntity(link, item)}>
                {listItemByType(props, item)}
              </div>
            </List.Item>
          );
        }}
      />
    );
  }

  if (props.isTable === true) {
    return (
      <TourListMobile
        data={props.data}
        history={props.history}
        dataMapFetching={props.dataMapFetching}
        isTable={props.isTable}
        scope={props.scope}
      />
    );
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={props.displayItems()}
      className="listTypeList"
      loading={props.dataMapFetching}
      locale={listText(props)}
      renderItem={(item) => {
        return (
          <List.Item
            key={item.title + item.id}
            className={
              +item.id === +props.previewJson.id ? 'selectedListItem' : ''
            }
          >
            {listItemByType(props, item)}
          </List.Item>
        );
      }}
    />
  );
}; //

const ListTour = React.forwardRef((props, ref) => {
  const url = props.router.location.pathname.split('/').slice(0, -1).join('/');

  return (
    <>
      <div className="listLayout">
        <div className="listGreenLine">
          <div className="label">{props.label}</div>
          <div className="rightIcon">
            <SortComponent
              handleChange={props.handleChange}
              scope={props.scope}
              filterType={props.filterType}
            />
            <ListTypeIcon {...props} />
            <ListCloseIcon {...props} />
          </div>
        </div>
        <div className="listContent">
          <div className="infinite-container" ref={ref}>
            {listType(props, url)}
          </div>
        </div>
      </div>
    </>
  );
});

ListTour.displayName = 'ListTour';

ListTour.propTypes = {
  getImageBig: PropTypes.object,
  getT: PropTypes.object,
  getDes: PropTypes.string,
  router: PropTypes.shape(RouterProps),
  tourFullDataId: PropTypes.func,
  tourFullMapPosition: PropTypes.func,
  classList: PropTypes.array,
  classBlock: PropTypes.array,
  display: PropTypes.string,
  type: PropTypes.string,
  loadingState: PropTypes.bool,
  readMore: PropTypes.bool,
  listTypeList: PropTypes.func,
  listTypeBlock: PropTypes.func,
  showPreviewer: PropTypes.func,
  closePreviewer: PropTypes.func,
  chooseBookmark: PropTypes.func,
  bookmark: PropTypes.func,
  displayItems: PropTypes.func,
  loadMoreItems: PropTypes.func,
  viewData: PropTypes.func,
  readMoreChange: PropTypes.func,
  TourDownloadBasketData: PropTypes.func,
  TourDownloadCoord: PropTypes.func,
  downloadTourGps: PropTypes.shape(DownloadTourGpsProps),
  openModalTourDownload: PropTypes.func,
  previewJson: PropTypes.object,
  getPolySelectedTourAfterListClose: PropTypes.func,
  removeItemFromList: PropTypes.func,
};
export default ListTour;

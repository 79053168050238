/* eslint-disable */
/* eslint-disable prettier/prettier */
import * as React from 'react';
import Icon from '@ant-design/icons';

export const ProfileAddressIcon = (props) => (
  <Icon {...props} component={() => <AddressSvg {...props} />} />
);

export const AddressSvg = () => {
  return (
    <svg viewBox="0 0 18.69816 18.7" className="address_svg">
      <path
        className="cls-1"
        style={{
          fill: '#e6e7e7',
          stroke: '#333',
          strokeMiterlimit: 10,
          strokeWidth: 1.3,
        }}
        d="M355.90744,903.736l-0.00124-.00124-7.34258-7.3423a1.65671,1.65671,0,0,0-2.34352-.00014l-7.33873,7.33859-0.00742.00755a1.65635,1.65635,0,0,0,1.10275,2.82321q0.02534,0.00247.05109,0.00247h0.29265v5.40349a1.94154,1.94154,0,0,0,1.9395,1.93922h2.87265a0.52739,0.52739,0,0,0,.52734-0.52734v-4.23633a0.88576,0.88576,0,0,1,.88481-0.88481h1.69437a0.88576,0.88576,0,0,1,.88481.88481v4.23633a0.5273,0.5273,0,0,0,.52734.52734h2.87265a1.94154,1.94154,0,0,0,1.9395-1.93922v-5.40349h0.27136A1.65722,1.65722,0,0,0,355.90744,903.736Z"
        transform="translate(-338.04274 -895.55685)"
      />
    </svg>
  );
};

export const ProfileWorkAddressIcon = (props) => (
  <Icon {...props} component={() => <WorkAddressSvg {...props} />} />
);

export const WorkAddressSvg = () => {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.5 18"
      className="address_svg"
    >
      <g>
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M1033.1492,478.195a0.63235,0.63235,0,0,0-.65917-0.6h-5.27361a0.63227,0.63227,0,0,0-.65891.6v0.6h6.59169v-0.6Z"
          transform="translate(-1018.60323 -476.39495)"
        />
        <rect
          className="cls-2"
          style={{ fill: '#fff' }}
          x="9.27262"
          y="9.59998"
          width="3.95501"
          height="1.19997"
        />
        <path
          className="cls-3"
          style={{ fill: '#e6e7e7' }}
          d="M1022.8492,485.99493h3.70832v-0.6a0.63138,0.63138,0,0,1,.65891-0.6H1032.49a0.63146,0.63146,0,0,1,.65917.6v0.6h3.70832a0.65543,0.65543,0,0,0,.62486-0.41024l2.04743-5.58972H1020.1769l2.04743,5.58972A0.65543,0.65543,0,0,0,1022.8492,485.99493Z"
          transform="translate(-1018.60323 -476.39495)"
        />
        <path
          className="cls-3"
          style={{ fill: '#e6e7e7' }}
          d="M1036.85752,487.1949h-3.70832v0.6a0.63155,0.63155,0,0,1-.65917.6001h-5.27361a0.63148,0.63148,0,0,1-.65891-0.6001v-0.6h-3.70832a1.96773,1.96773,0,0,1-1.87563-1.23072l-1.05174-2.87191v9.50261a0.6322,0.6322,0,0,0,.65891.6001H1039.126a0.63237,0.63237,0,0,0,.65891-0.6001v-9.50261l-1.05174,2.87191A1.96773,1.96773,0,0,1,1036.85752,487.1949Z"
          transform="translate(-1018.60323 -476.39495)"
        />
        <path
          className="cls-4"
          style={{ ill: '#333' }}
          d="M1040.44851,478.79513l-0.00288-.00012h-5.97809v-0.6A1.89714,1.89714,0,0,0,1032.49,476.395h-5.27361a1.89706,1.89706,0,0,0-1.97725,1.80007v0.6h-5.97678a0.63325,0.63325,0,0,0-.65917.6v13.19989a1.89714,1.89714,0,0,0,1.97751,1.80007H1039.126a1.89706,1.89706,0,0,0,1.97725-1.80007V479.40524A0.61291,0.61291,0,0,0,1040.44851,478.79513Zm-13.891-.6001a0.63227,0.63227,0,0,1,.65891-0.6H1032.49a0.63235,0.63235,0,0,1,.65917.6v0.6h-6.59169v-0.6Zm12.9723,1.8-2.04743,5.58972a0.65543,0.65543,0,0,1-.62486.41024h-3.70832v-0.6a0.63146,0.63146,0,0,0-.65917-0.6h-5.27361a0.63138,0.63138,0,0,0-.65891.6v0.6h-3.70832a0.65543,0.65543,0,0,1-.62486-0.41024l-2.04743-5.58972h19.35291Zm-7.69895,6v1.2h-3.955v-1.2h3.955Zm7.954,6.59994a0.63237,0.63237,0,0,1-.65891.6001h-18.54525a0.6322,0.6322,0,0,1-.65891-0.6001v-9.50261l1.05174,2.87191a1.96773,1.96773,0,0,0,1.87563,1.23072h3.70832v0.6a0.63148,0.63148,0,0,0,.65891.6001H1032.49a0.63155,0.63155,0,0,0,.65917-0.6001v-0.6h3.70832a1.96773,1.96773,0,0,0,1.87563-1.23072l1.05174-2.87191v9.50261Z"
          transform="translate(-1018.60323 -476.39495)"
        />
      </g>
    </svg>
  );
};

export const ProfileMailIcon = (props) => (
  <Icon {...props} component={() => <MailSvg {...props} />} />
);

export const MailSvg = () => {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.11977 16.03779"
    >
      <path
        className="cls-1"
        style={{ fill: '#e6e7e7' }}
        d="M311.44159,1194.29546h0Z"
        transform="translate(-305.0493 -1179.21397)"
      />
      <g>
        <g>
          <polygon
            className="cls-2"
            style={{ fill: '#fff' }}
            points="2.149 5.426 1.297 6.171 2.149 6.916 2.149 5.426"
          />
          <polygon
            className="cls-2"
            style={{ fill: '#fff' }}
            points="13.97 5.426 13.97 6.916 14.823 6.171 13.97 5.426"
          />
          <polygon
            className="cls-3"
            style={{ fill: '#e6e7e7' }}
            points="1.075 7.305 1.075 14.434 5.151 10.869 1.075 7.305"
          />
          <polygon
            className="cls-3"
            style={{ fill: '#e6e7e7' }}
            points="6.133 11.339 1.835 15.098 14.285 15.098 9.987 11.339 6.133 11.339"
          />
          <polygon
            className="cls-3"
            style={{ fill: '#e6e7e7' }}
            points="15.045 14.434 15.045 7.305 10.969 10.869 15.045 14.434"
          />
          <path
            className="cls-4"
            style={{ fill: '#333' }}
            d="M321.01152,1185.05248l-1.99188-1.74178v-3.62688a0.507,0.507,0,0,0-.53739-0.46985H307.736a0.50707,0.50707,0,0,0-.53739.46985v3.62688l-1.99188,1.74178-0.00525.00515a0.44248,0.44248,0,0,0-.15213.32638v8.4582a1.52272,1.52272,0,0,0,1.61187,1.40955H319.557a1.52264,1.52264,0,0,0,1.612-1.40955v-8.4573l-0.00016-.0009A0.4454,0.4454,0,0,0,321.01152,1185.05248Zm-1.99188-.4128,0.85216,0.74509-0.85216.74509v-1.49019Zm-10.74646-1.13416v-3.35185H317.945v6.9159l-2.90912,2.54392h-3.85342l-2.90928-2.54392v-3.56405Zm-1.07463,1.13416v1.49019l-0.85216-.74509Zm-1.07463,9.00786v-7.12846l4.07605,3.56426Zm0.76,0.66453,4.29852-3.75887h3.85342l4.29852,3.75887H306.88395Zm13.21033-.66453-4.07605-3.56419,4.07605-3.56426v7.12846Z"
            transform="translate(-305.0493 -1179.21397)"
          />
        </g>
        <path
          className="cls-2"
          style={{ fill: '#fff' }}
          d="M315.03589,1189.6135l2.90912-2.54392v-6.9159h-9.67183v6.9159l2.90928,2.54392h3.85342Z"
          transform="translate(-305.0493 -1179.21397)"
        />
      </g>
    </svg>
  );
};

export const ProfileBirthdayIcon = (props) => (
  <Icon {...props} component={() => <BirthdaySvg {...props} />} />
);

export const BirthdaySvg = () => {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.82748 18.3"
    >
      <g id="Calendar">
        <rect
          className="cls-1"
          style={{
            fill: '#e6e7e7',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: '#333',
            strokeWidth: 0.9,
          }}
          x="0.25"
          y="1.52459"
          width="17.32748"
          height="16.52541"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M925.76964,2445.83386a0.2909,0.2909,0,0,0-.113-0.02261h-1.894a0.2909,0.2909,0,0,0-.113.02261,0.2859,0.2859,0,0,0-.17612.26528v1.88563a0.28595,0.28595,0,0,0,.17612.26478,0.29091,0.29091,0,0,0,.113.02261h1.894a0.29091,0.29091,0,0,0,.113-0.02261,0.28595,0.28595,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,925.76964,2445.83386Zm-0.32853,1.93537h-1.46249v-1.45555h1.46249v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M929.50376,2445.83386a0.2909,0.2909,0,0,0-.113-0.02261h-1.894a0.2909,0.2909,0,0,0-.113.02261,0.2859,0.2859,0,0,0-.17612.26528v1.88563a0.28595,0.28595,0,0,0,.17612.26478,0.29091,0.29091,0,0,0,.113.02261h1.894a0.29091,0.29091,0,0,0,.113-0.02261,0.28595,0.28595,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,929.50376,2445.83386Zm-0.32853,1.93537h-1.46249v-1.45555h1.46249v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M933.03378,2445.83386a0.2909,0.2909,0,0,0-.113-0.02261h-1.894a0.2909,0.2909,0,0,0-.113.02261,0.2859,0.2859,0,0,0-.17612.26528v1.88563a0.28595,0.28595,0,0,0,.17612.26478,0.29091,0.29091,0,0,0,.113.02261h1.894a0.29091,0.29091,0,0,0,.113-0.02261,0.28595,0.28595,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,933.03378,2445.83386Zm-0.32853,1.93537h-1.46248v-1.45555h1.46248v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M921.88636,2452.64381a0.29091,0.29091,0,0,0-.113-0.02261h-1.894a0.29091,0.29091,0,0,0-.113.02261,0.28591,0.28591,0,0,0-.17612.26528v1.88563a0.28594,0.28594,0,0,0,.17612.26478,0.2909,0.2909,0,0,0,.113.02261h1.894a0.2909,0.2909,0,0,0,.113-0.02261,0.28594,0.28594,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,921.88636,2452.64381Zm-0.32853,1.93537h-1.46249v-1.45555h1.46249v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M925.76964,2452.64381a0.29091,0.29091,0,0,0-.113-0.02261h-1.894a0.29091,0.29091,0,0,0-.113.02261,0.28591,0.28591,0,0,0-.17612.26528v1.88563a0.28594,0.28594,0,0,0,.17612.26478,0.2909,0.2909,0,0,0,.113.02261h1.894a0.2909,0.2909,0,0,0,.113-0.02261,0.28594,0.28594,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,925.76964,2452.64381Zm-0.32853,1.93537h-1.46249v-1.45555h1.46249v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M929.50376,2452.64381a0.29091,0.29091,0,0,0-.113-0.02261h-1.894a0.29091,0.29091,0,0,0-.113.02261,0.28591,0.28591,0,0,0-.17612.26528v1.88563a0.28594,0.28594,0,0,0,.17612.26478,0.2909,0.2909,0,0,0,.113.02261h1.894a0.2909,0.2909,0,0,0,.113-0.02261,0.28594,0.28594,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,929.50376,2452.64381Zm-0.32853,1.93537h-1.46249v-1.45555h1.46249v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M921.88636,2449.23858a0.29091,0.29091,0,0,0-.113-0.02261h-1.894a0.29091,0.29091,0,0,0-.113.02261,0.28591,0.28591,0,0,0-.17612.26528v1.88563a0.28594,0.28594,0,0,0,.17612.26478,0.2909,0.2909,0,0,0,.113.02261h1.894a0.2909,0.2909,0,0,0,.113-0.02261,0.28594,0.28594,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,921.88636,2449.23858Zm-0.32853,1.93537h-1.46249v-1.45555h1.46249v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M925.76964,2449.23858a0.29091,0.29091,0,0,0-.113-0.02261h-1.894a0.29091,0.29091,0,0,0-.113.02261,0.28591,0.28591,0,0,0-.17612.26528v1.88563a0.28594,0.28594,0,0,0,.17612.26478,0.2909,0.2909,0,0,0,.113.02261h1.894a0.2909,0.2909,0,0,0,.113-0.02261,0.28594,0.28594,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,925.76964,2449.23858Zm-0.32853,1.93537h-1.46249v-1.45555h1.46249v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M929.50376,2449.23858a0.29091,0.29091,0,0,0-.113-0.02261h-1.894a0.29091,0.29091,0,0,0-.113.02261,0.28591,0.28591,0,0,0-.17612.26528v1.88563a0.28594,0.28594,0,0,0,.17612.26478,0.2909,0.2909,0,0,0,.113.02261h1.894a0.2909,0.2909,0,0,0,.113-0.02261,0.28594,0.28594,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,929.50376,2449.23858Zm-0.32853,1.93537h-1.46249v-1.45555h1.46249v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M933.03378,2449.23858a0.29091,0.29091,0,0,0-.113-0.02261h-1.894a0.29091,0.29091,0,0,0-.113.02261,0.28591,0.28591,0,0,0-.17612.26528v1.88563a0.28594,0.28594,0,0,0,.17612.26478,0.2909,0.2909,0,0,0,.113.02261h1.894a0.2909,0.2909,0,0,0,.113-0.02261,0.28594,0.28594,0,0,0,.17612-0.26478v-1.88563A0.2859,0.2859,0,0,0,933.03378,2449.23858Zm-0.32853,1.93537h-1.46248v-1.45555h1.46248v1.45555Z"
          transform="translate(-917.62126 -2438.99537)"
        />
        <line
          className="cls-3"
          style={{
            fill: 'none',
            strokeMiterlimit: 10,
            stroke: '#333',
            strokeWidth: 0.9,
          }}
          x1="0.25"
          y1="3.9728"
          x2="17.57748"
          y2="3.9728"
        />
        <rect
          className="cls-4"
          style={{
            fill: '#333',
            strokeMiterlimit: 10,
            stroke: '#e6e7e7',
            strokeWidth: 0.9,
          }}
          x="4.1543"
          y="0.15028"
          width="0.85687"
          height="2.74946"
          rx="0.15708"
          ry="0.15708"
        />
        <rect
          className="cls-4"
          style={{
            fill: '#333',
            strokeMiterlimit: 10,
            stroke: '#e6e7e7',
            strokeWidth: 0.9,
          }}
          x="13.00079"
          y="0.05"
          width="0.85687"
          height="2.74946"
          rx="0.15708"
          ry="0.15708"
        />
      </g>
    </svg>
  );
};

export const ProfileGpsIcon = (props) => (
  <Icon {...props} component={() => <GpsSvg {...props} />} />
);

export const GpsSvg = () => {
  return (
    <svg
      id="GPS"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.55712 19"
      width="21px"
      height="20px"
      className="gps_svg"
    >
      <rect
        className="cls-1"
        style={{
          fill: '#e6e7e7',
          stroke: '#333',
          strokeLinejoin: 'round',
          strokeWidth: 1.4,
        }}
        x="0.5"
        y="0.5"
        width="24.55712"
        height="18"
      />
      <g>
        <path
          className="cls-2"
          style={{ fill: '#fff', stroke: '#333' }}
          d="M1009.6582,2496.5625a0.20992,0.20992,0,0,1-.11719-0.03613l-4.06543-2.82129-4.04492,2.793a0.2007,0.2007,0,0,1-.11426.03613,0.20792,0.20792,0,0,1-.19238-0.28516l4.13574-9.93262a0.20227,0.20227,0,0,1,.18555-0.12207,0.20536,0.20536,0,0,1,.19043.127l4.20605,9.96191a0.19908,0.19908,0,0,1-.042.22168A0.21342,0.21342,0,0,1,1009.6582,2496.5625Z"
          transform="translate(-992.70514 -2481.84907)"
        />
        <path
          className="cls-3"
          style={{ fill: '#333' }}
          d="M1005.44751,2486.51733l4.09991,9.709-3.78687-2.62817-0.28436-.19751-0.28485.19678-3.77881,2.60938,4.035-9.68945m-0.003-.573a0.4505,0.4505,0,0,0-.41534.27686l-4.13593,9.93188a0.45738,0.45738,0,0,0,.42285.63062,0.45062,0.45062,0,0,0,.2561-0.07959l3.90326-2.69531,3.92285,2.72266a0.45316,0.45316,0,0,0,.54376-0.01831c0.01215-.01025.024-0.02075,0.03485-0.03174a0.45294,0.45294,0,0,0,.09582-0.49634l-4.20642-9.96118a0.458,0.458,0,0,0-.41937-0.27954h-0.00244Z"
          transform="translate(-992.70514 -2481.84907)"
        />
      </g>
    </svg>
  );
};

export const ProfileBikeIcon = (props) => (
  <Icon {...props} component={() => <BikeSvg {...props} />} />
);

export const BikeSvg = () => {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.0783 18.4"
      width="21px"
      height="20px"
      className="bike_svg"
    >
      <g id="MC">
        <path
          className="cls-1"
          style={{
            fill: '#e6e7e7',
            stroke: '#333',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 1.4,
          }}
          d="M1019.30423,2528.49984a3.89914,3.89914,0,0,0-.74906.07155l-0.40053-.82422,0.00069-.00028a2.25425,2.25425,0,0,0,.60624-0.20938,2.02855,2.02855,0,0,0,.4526-0.378,2.181,2.181,0,0,0,.33827-0.44178,2.09814,2.09814,0,0,0,.24876-1.1021,2.80012,2.80012,0,0,0-.09949-0.63675c-0.682-2.31847-7.31358-6.73853-7.68714-6.6692a2.14083,2.14083,0,0,0,.23663.8991,2.525,2.525,0,0,0,1.06494.98812c1.36023,0.81784,2.10354,2.85815,1.73941,3.21258-0.32635.31754-1.961-.35027-2.69591-0.50474a2.0047,2.0047,0,0,0-.43416-0.04188,1.73718,1.73718,0,0,0-.26513.02385,2.97456,2.97456,0,0,0-1.28826.63536l-0.03155.02219a3.3901,3.3901,0,0,0-1.00989.984,1.73013,1.73013,0,0,0-.21132.44789,1.009,1.009,0,0,1-.1076.24183,2.42236,2.42236,0,0,1-1.13455.92129,2.95363,2.95363,0,0,1-1.14918.27456,4.26993,4.26993,0,0,1-1.36161-.31837,9.83677,9.83677,0,0,1-1.74287-.847l-0.09166-.0563-7.6255-.01026-0.051.32309a1.58751,1.58751,0,0,0,.07585.81756c0.11294,0.29868,2.53825.94292,3.588,1.02722a7.49509,7.49509,0,0,1,2.31916.568q0.82242,1.24257,1.64477,2.48486-0.798.07654-1.596,0.15281a4.03679,4.03679,0,0,0-3.45226-2.098,3.90451,3.90451,0,1,0-.01054,7.809,4.08787,4.08787,0,0,0,3.88766-3.63938l1.038-.07155a2.401,2.401,0,0,0,.19316,1.012,2.3732,2.3732,0,0,0,.54176.77985,2.34456,2.34456,0,0,0,1.11569.57767l0.0373,0.00777,6.42162,0.06684,0.10254-.07488a9.45192,9.45192,0,0,0,1.77234-1.67978,4.96158,4.96158,0,0,0,.85868-1.4166,2.364,2.364,0,0,0,.137-0.65782,2.26126,2.26126,0,0,1,.09637-0.51971,3.65171,3.65171,0,0,1,.82332-1.49037,6.16715,6.16715,0,0,1,.74206-0.65976c0.02649-.02.053-0.03938,0.077-0.05685l0.44754,1.03305A3.90364,3.90364,0,1,0,1019.30423,2528.49984Z"
          transform="translate(-994.32518 -2518.10886)"
        />
      </g>
    </svg>
  );
};

export const ProfileMediaIcon = (props) => (
  <Icon {...props} component={() => <MediaSvg {...props} />} />
);

export const MediaSvg = () => {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.00702 16"
      width="22px"
      height="22px"
    >
      <g id="Picture_-_camera" data-name="Picture - camera">
        <ellipse
          className="cls-1"
          style={{ fill: '#fff' }}
          cx="10.00386"
          cy="8.5714"
          rx="3.75143"
          ry="3.42856"
        />
        <path
          className="cls-2"
          style={{ fill: '#e6e7e7' }}
          d="M321.03,979.72232h-3.12616a0.634,0.634,0,0,1-.55933-0.31573l-1.07831-1.96918h-6.72986l-1.07648,1.96924a0.63468,0.63468,0,0,1-.55865.31573l-3.12128.00385a0.60086,0.60086,0,0,0-.624.57123v10.28381a0.60048,0.60048,0,0,0,.62524.57123h16.25555a0.60161,0.60161,0,0,0,.62592-0.57178V980.293A0.60759,0.60759,0,0,0,321.03,979.72232Zm-8.12079,9.71552a4.59,4.59,0,1,1,5.002-4.57141A4.80678,4.80678,0,0,1,312.90926,989.43783Z"
          transform="translate(-302.90541 -976.29501)"
        />
        <path
          className="cls-3"
          style={{ fill: '#333' }}
          d="M312.90926,980.295a4.59,4.59,0,1,0,5.002,4.57147A4.80675,4.80675,0,0,0,312.90926,980.295Zm0,8a3.44246,3.44246,0,1,1,3.75146-3.42853A3.60481,3.60481,0,0,1,312.90926,988.295Z"
          transform="translate(-302.90541 -976.29501)"
        />
        <path
          className="cls-3"
          style={{ fill: '#333' }}
          d="M322.90572,980.2919a1.80267,1.80267,0,0,0-1.87567-1.712H318.2903l-1.07831-1.96918a0.63391,0.63391,0,0,0-.55927-0.31573h-7.50287a0.6332,0.6332,0,0,0-.55927.31573l-1.07709,1.96973-2.73541.0033a1.80286,1.80286,0,0,0-1.87268,1.71375v10.28381a1.80223,1.80223,0,0,0,1.87573,1.71368H321.0367a1.80275,1.80275,0,0,0,1.87573-1.71484Zm-1.243,10.28882a0.60161,0.60161,0,0,1-.62592.57178H304.7812a0.60048,0.60048,0,0,1-.62524-0.57123V980.29745a0.60086,0.60086,0,0,1,.624-0.57123l3.12128-.00385a0.63468,0.63468,0,0,0,.55865-0.31573l1.07648-1.96924h6.72986l1.07831,1.96918a0.634,0.634,0,0,0,.55933.31573H321.03a0.60759,0.60759,0,0,1,.63263.57068v10.28772Z"
          transform="translate(-302.90541 -976.29501)"
        />
      </g>
    </svg>
  );
};

export const ProfileInfoIcon = (props) => (
  <Icon {...props} component={() => <InfoSvg {...props} />} />
);

export const InfoSvg = () => {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.83813 18.5"
      width="22px"
      height="22px"
    >
      <g id="Info">
        <path
          className="cls-1"
          style={{
            fill: '#e6e7e7',
            stroke: '#333',
            strokeMiterlimit: 10,
            strokeWidth: 1.2,
          }}
          d="M349.78246,2448.07977c0.01748-.90131,1.46813-2.01389,1.68443-2.177a7.5299,7.5299,0,0,1,3.24174-1.62087,2.91277,2.91277,0,0,1,2.04993.143,1.84833,1.84833,0,0,1,.81044.85811,1.94369,1.94369,0,0,1,.04767,1.23949,78.07246,78.07246,0,0,1-2.84447,7.35752,0.96164,0.96164,0,0,0-.06356.84219,0.65866,0.65866,0,0,0,.61975.23836,1.24091,1.24091,0,0,0,.6992-0.57207s0.00476-.00559,1.25538-1.35076a0.8121,0.8121,0,0,1,.55619-0.33371,0.46279,0.46279,0,0,1,.39727.19069,1.15555,1.15555,0,0,1-.42905.96938,10.85462,10.85462,0,0,1-2.0976,1.95458,8.22318,8.22318,0,0,1-2.14527,1.19182,3.78345,3.78345,0,0,1-1.90691.23836,1.77142,1.77142,0,0,1-1.09647-.47673,1.92473,1.92473,0,0,1-.33371-1.52553,16.76937,16.76937,0,0,1,2.28829-6.19745c0.18609-.33557.32933-0.69349,0.50851-1.03288a2.40425,2.40425,0,0,0,.5085-1.462,0.63893,0.63893,0,0,0-.25425-0.46081,0.55448,0.55448,0,0,0-.42905-0.03175,1.814,1.814,0,0,0-.90578.6515,13.87968,13.87968,0,0,1-1.23949,1.28716c-0.58884.50373-.75118,0.46351-0.81044,0.42906A0.4127,0.4127,0,0,1,349.78246,2448.07977Z"
          transform="translate(-349.53204 -2439.03589)"
        />
        <circle
          className="cls-1"
          style={{
            fill: '#e6e7e7',
            stroke: '#333',
            strokeMiterlimit: 10,
            strokeWidth: 1.2,
          }}
          cx="7.75623"
          cy="2.0819"
          r="1.8319"
        />
      </g>
    </svg>
  );
};

export const ProfileFilterIcon = (props) => (
  <Icon {...props} component={() => <FilterSvg {...props} />} />
);

export const FilterSvg = () => {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.36145 11.83191"
      width="22px"
      height="22px"
    >
      <g>
        <path
          className="cls-1"
          style={{
            fill: '#e6e7e7',
            stroke: '#333',
            strokeMiterlimit: 10,
            strokeWidth: 0.7,
          }}
          d="M972.62408,1546.13062c-2.49512,0-5.18011.44189-5.18011,1.4126a0.87134,0.87134,0,0,0,.37238.56854,42.676,42.676,0,0,1,3.05865,4.01325,1.66089,1.66089,0,0,1,.33765.99933v3.1319c0,0.48535.73242,0.7063,1.41266,0.7063s1.41266-.22095,1.41266-0.7063v-3.1319a1.65567,1.65567,0,0,1,.25568-0.87146h1.62744a0.9397,0.9397,0,0,0,.84283-0.52008l0.47046-.9422a0.93693,0.93693,0,0,0-.80682-1.358,13.98278,13.98278,0,0,1,1.00543-1.31952,0.87122,0.87122,0,0,0,.37244-0.56854C977.8042,1546.57373,975.11914,1546.13062,972.62408,1546.13062Zm3.29572,5.18134H974.995l0.71753-.9422h0.679Zm-3.29572-3.297a12.85569,12.85569,0,0,1-3.85065-.47046,12.81924,12.81924,0,0,1,3.85065-.47046q0.35962,0,.68964.01129a11.70539,11.70539,0,0,1,3.16095.45917A12.8621,12.8621,0,0,1,972.62408,1548.015Z"
          transform="translate(-966.94397 -1545.63062)"
        />
        <path
          className="cls-2"
          style={{ fill: '#e6e7e7' }}
          d="M972.61723,1546.58127a12.81924,12.81924,0,0,0-3.85065.47046,15.99351,15.99351,0,0,0,7.70123,0,11.7054,11.7054,0,0,0-3.16095-.45917Q972.97682,1546.58158,972.61723,1546.58127Z"
          transform="translate(-966.94397 -1545.63062)"
        />
        <polygon
          className="cls-2"
          style={{ fill: '#e6e7e7' }}
          points="8.638 5.751 9.563 5.751 10.034 4.809 9.355 4.809 8.638 5.751"
        />
      </g>
    </svg>
  );
};

export const ProfileFriendToursIcon = (props) => (
  <Icon {...props} component={() => <FriendToursSvg {...props} />} />
);

export const FriendToursSvg = () => {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.63491 18"
      width="22px"
      height="22px"
    >
      <g>
        <path
          className="cls-1"
          style={{ fill: '#e6e7e7' }}
          d="M321.46585,905.96253a0.70283,0.70283,0,0,0-.07137-0.54595,0.4747,0.4747,0,0,0-.48949-0.24874c-2.48633.44848-8.06461,0.11345-12.30863-.24661a4.63451,4.63451,0,0,0-4.76121,3.63523l-1.25009,5.68214H303.963l0.93-4.42778a0.38608,0.38608,0,0,1,.42238-0.32437,0.40894,0.40894,0,0,1,.38509.36752l0.44315,4.38464h5.91277l0.54169-5.88507a0.409,0.409,0,0,1,.44475-0.37018,14.63954,14.63954,0,0,0,8.15143-1.65969A0.56908,0.56908,0,0,0,321.46585,905.96253Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M321.57984,907.04485a1.37634,1.37634,0,0,0,.67485-0.86553,1.51991,1.51991,0,0,0-.15979-1.18511,1.29681,1.29681,0,0,0-1.33585-.6317c-1.86848.33929-6.16257,0.24767-12.09345-.2562a5.50853,5.50853,0,0,0-5.62461,4.25521l-1.36407,6.19826a0.40882,0.40882,0,0,0,.39948.49695h2.21842a0.40981,0.40981,0,0,0,.40054-0.32491l0.45647-2.17261,0.21518,2.12947a0.40945,0.40945,0,0,0,.40693.36805h6.65579a0.40922,0.40922,0,0,0,.40746-0.37178l0.53849-5.85471A15.55386,15.55386,0,0,0,321.57984,907.04485Zm-8.53705.9385a0.409,0.409,0,0,0-.44475.37018l-0.54169,5.88507h-5.91277l-0.44315-4.38464a0.40894,0.40894,0,0,0-.38509-0.36752,0.38608,0.38608,0,0,0-.42238.32437l-0.93,4.42778h-1.37792l1.25009-5.68214a4.63451,4.63451,0,0,1,4.76121-3.63523c4.244,0.36006,9.8223.69509,12.30863,0.24661a0.4747,0.4747,0,0,1,.48949.24874,0.70283,0.70283,0,0,1,.07137.54595,0.56908,0.56908,0,0,1-.27164.36113A14.63954,14.63954,0,0,1,313.04278,907.98335Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-1"
          style={{ fill: '#e6e7e7' }}
          d="M314.73869,910.295l-0.37977,3.94362h5.04191v-2.83947a0.409,0.409,0,0,1,.78617-0.15819l1.256,2.99766h1.91695l-1.53665-5.72262A17.10806,17.10806,0,0,1,314.73869,910.295Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M324.28828,914.54167l-1.81468-6.75806a0.40994,0.40994,0,0,0-.24608-0.27484,0.39914,0.39914,0,0,0-.36752.03515c-3.03548,1.91642-7.44942,1.942-7.49362,1.942a0.4085,0.4085,0,0,0-.40693.36965l-0.45753,4.75269a0.40939,0.40939,0,0,0,.40693.44848h5.901a0.40917,0.40917,0,0,0,.40906-0.40906v-1.21334l0.57471,1.37153a0.40847,0.40847,0,0,0,.3771.25087h2.72229A0.40968,0.40968,0,0,0,324.28828,914.54167Zm-2.84533-.30307-1.256-2.99766a0.409,0.409,0,0,0-.78617.15819v2.83947h-5.04191l0.37977-3.94362a17.10806,17.10806,0,0,0,7.08456-1.779l1.53665,5.72262h-1.91695Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-1"
          style={{ fill: '#e6e7e7' }}
          d="M305.52945,902.25594l0.46978,0.8602,5.40517-.59708a0.41912,0.41912,0,0,0,.16831-0.05326,0.41183,0.41183,0,0,0,.13422-0.115,0.45573,0.45573,0,0,0,.09747-0.34461l-0.033-.22424-0.06-.14521-2.50944.10047a0.72945,0.72945,0,0,1-.38509-0.09321,0.71794,0.71794,0,0,1-.28123-0.28123l-0.50068-.9156a0.71217,0.71217,0,0,1-.04155-0.59282,0.74066,0.74066,0,0,1,.11452-0.2008l0.02716-.03515a0.68114,0.68114,0,0,1,.16032-0.13156l0.04261-.0277a0.403,0.403,0,0,1,.074-0.03515l2.066-.8146,0.00493-.00193a3.08036,3.08036,0,0,0-2.049-.87512h-0.00053c-0.04634-.00213-0.09268-0.0032-0.13848-0.0032a3.24849,3.24849,0,0,0-2.27188.92019l-0.0032.00266A3.261,3.261,0,0,0,305.52945,902.25594Zm0.579-1.83546a0.93785,0.93785,0,0,1,1.28844.29122,0.95436,0.95436,0,0,1,.09374.11092l0.00027,0.0014,0.00107,0.00133a1.01665,1.01665,0,0,1-.4072,1.38105,1.0142,1.0142,0,0,1-.77551.08522c-0.0261-.00766-0.04534-0.0271-0.07044-0.03662a1.00529,1.00529,0,0,1-.223-0.116,0.95164,0.95164,0,0,1-.09294-0.07177,0.99548,0.99548,0,0,1-.21385-0.25593l-0.00586-.007-0.0002-.001-0.00193-.00273A1.0199,1.0199,0,0,1,306.10842,900.42048Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <polygon points="9.497 1.298 9.5 1.304 9.501 1.304 9.497 1.298" />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M305.47086,903.555a0.47956,0.47956,0,0,0,.42877.24767l0.03569-.0016,5.54312-.60987a1.12358,1.12358,0,0,0,.42291-0.13209,1.13333,1.13333,0,0,0,.58377-1.12812c-0.00905-.08469-0.02557-0.18429-0.04261-0.28389l-1.25489-3.25492-0.02-.03189-0.00393-.00539-0.122.05966,0.12144-.06072-0.02663-.04314a3.75633,3.75633,0,0,0-2.67276-1.25062,3.32034,3.32034,0,0,0-.47777.01332,1.278,1.278,0,0,0-.1486.017c-0.11771.01332-.2317,0.03356-0.35793,0.05646-0.01917.00479-.03835,0.008-0.05752,0.01119-0.0245.00479-.049,0.00905-0.074,0.01491a3.36915,3.36915,0,0,0-.46872.14754,3.66788,3.66788,0,0,0-.4293.19921l-0.03675.017c-0.016.00852-.03143,0.01864-0.04687,0.02876l-0.02983.01811c-0.0996.05912-.19654,0.12144-0.29668,0.19068-0.03888.02557-.0799,0.0538-0.10972,0.0783a3.71753,3.71753,0,0,0-.37764.31106,3.93611,3.93611,0,0,0-.61466,4.41553Zm0.55214-4.90576a3.24849,3.24849,0,0,1,2.27188-.92019c0.04581,0,.09215.00107,0.13848,0.0032h0.00053a3.08036,3.08036,0,0,1,2.049.87512l-0.00493.00193-2.066.8146a0.403,0.403,0,0,0-.074.03515l-0.04261.0277a0.68114,0.68114,0,0,0-.16032.13156l-0.02716.03515a0.74066,0.74066,0,0,0-.11452.2008,0.71217,0.71217,0,0,0,.04155.59282l0.50068,0.9156a0.71794,0.71794,0,0,0,.28123.28123,0.72945,0.72945,0,0,0,.38509.09321l2.50944-.10047,0.06,0.14521,0.033,0.22424a0.45573,0.45573,0,0,1-.09747.34461,0.41183,0.41183,0,0,1-.13422.115,0.41912,0.41912,0,0,1-.16831.05326l-5.40517.59708-0.46978-.8602a3.261,3.261,0,0,1,.49036-3.604Zm3.15872,2.413a0.04518,0.04518,0,0,1-.04527-0.02344l-0.50014-.9156a0.04773,0.04773,0,0,1-.0016-0.04474,0.04645,0.04645,0,0,1,.02077-0.02077l-0.06551-.11984,0.07617,0.11665,2.1103-.8341,0.67751,1.75183Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-3"
          style={{ fill: '#fff' }}
          d="M308.66613,900.05456l-0.07617-.11665,0.06551,0.11984a0.04645,0.04645,0,0,0-.02077.02077,0.04773,0.04773,0,0,0,.0016.04474l0.50014,0.9156a0.04518,0.04518,0,0,0,.04527.02344l2.27222-.09-0.67751-1.75183Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-3"
          style={{}}
          d="M306.4344,901.01543a0.34278,0.34278,0,0,0-.13975.46293,0.33384,0.33384,0,0,0,.20207.16079,0.34468,0.34468,0,0,0,.40247-0.49082c-0.003-.00546-0.00932-0.00746-0.01258-0.01265a0.331,0.331,0,0,0-.161-0.1281,0.35188,0.35188,0,0,0-.12823-0.03369A0.33575,0.33575,0,0,0,306.4344,901.01543Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M305.70262,901.8028l0.00047,0.00146,0.00586,0.007a0.99548,0.99548,0,0,0,.21385.25593,0.95164,0.95164,0,0,0,.09294.07177,1.00529,1.00529,0,0,0,.223.116c0.0251,0.00952.04434,0.029,0.07044,0.03662a1.02407,1.02407,0,0,0,1.26607-.69242,1.00918,1.00918,0,0,0-.08309-0.77338l-0.001-.00113-0.0006-.00206a0.95436,0.95436,0,0,0-.09374-0.11092,1.0098,1.0098,0,0,0-1.69591,1.08884Zm1.184-.66712c0.00326,0.00519.00959,0.00719,0.01258,0.01265a0.34468,0.34468,0,0,1-.40247.49082,0.33384,0.33384,0,0,1-.20207-0.16079,0.34278,0.34278,0,0,1,.13975-0.46293,0.33575,0.33575,0,0,1,.163-0.04155,0.35188,0.35188,0,0,1,.12823.03369A0.331,0.331,0,0,1,306.8866,901.13567Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-1"
          style={{ fill: '#e6e7e7' }}
          d="M317.38215,898.03375a3.10052,3.10052,0,0,0-1.88925,1.18351L317.673,899.703a0.6022,0.6022,0,0,1,.08469.0245l0.03515,0.01811a0.65017,0.65017,0,0,1,.18.10706l0.03888,0.03728a0.67493,0.67493,0,0,1,.138.17417,0.70715,0.70715,0,0,1,.04953.58909l-0.35154.98218a0.71211,0.71211,0,0,1-.23376.32164,0.72705,0.72705,0,0,1-.25154.1046,0.69467,0.69467,0,0,1-.10859.04514l-0.001.00013-0.00486.002-2.49319.29242-0.04055.18376,0.00586,0.19974a0.44783,0.44783,0,0,0,.14594.31851,0.43335,0.43335,0,0,0,.15446.09587,0.41738,0.41738,0,0,0,.17044.02663l5.43606-.25407,0.33023-.92252a3.26018,3.26018,0,0,0-1.04769-3.48342,3.20135,3.20135,0,0,0-.783-0.40746A3.2676,3.2676,0,0,0,317.38215,898.03375Zm1.38551,3.0966a0.95765,0.95765,0,0,1,.027-0.18735v-0.00053l0.00146-.00246,0.00013-.00073a0.99629,0.99629,0,0,1,.19062-0.31372c0.007-.00806.00959-0.01991,0.01678-0.0277a1.02077,1.02077,0,0,1,1.67334.2514,1.00564,1.00564,0,0,1,.03888.77658l-0.00107.00146-0.00053.00226a0.9928,0.9928,0,0,1-.18669.31126c-0.01991.02324-.04394,0.04081-0.06591,0.06212a0.99994,0.99994,0,0,1-.2287.16865c-0.01438.00766-.0237,0.02144-0.03855,0.02843-0.01172.00553-.0251,0.00326-0.037,0.00832A0.95852,0.95852,0,0,1,318.76766,901.13035Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M319.41255,902.24262a0.95346,0.95346,0,0,0,.745-0.03429c0.01185-.00506.02523-0.0028,0.037-0.00832,0.01485-.007.02417-0.02077,0.03855-0.02843a0.99994,0.99994,0,0,0,.2287-0.16865c0.022-.02131.046-0.03888,0.06591-0.06212a0.9928,0.9928,0,0,0,.18669-0.31126l0.0002-.0014,0.0014-.00233a1.016,1.016,0,0,0-.61786-1.29909,1.02434,1.02434,0,0,0-1.09436.27111c-0.00719.00779-.00979,0.01964-0.01678,0.0277a0.99629,0.99629,0,0,0-.19062.31372l-0.00007.00047-0.00153.00273V900.943a0.95765,0.95765,0,0,0-.027.18735A0.91267,0.91267,0,0,0,319.41255,902.24262Zm0.66726-.84236a0.33523,0.33523,0,0,1-.1751.19042,0.34559,0.34559,0,0,1-.4596-0.163,0.35877,0.35877,0,0,1-.00293-0.04827,0.32725,0.32725,0,0,1-.01139-0.21106,0.34687,0.34687,0,0,1,.32531-0.22324,0.337,0.337,0,0,1,.11345.01971,0.347,0.347,0,0,1,.19594.1763,0.35877,0.35877,0,0,1,.00293.04827A0.32706,0.32706,0,0,1,320.07981,901.40026Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-2"
          style={{ fill: '#333' }}
          d="M314.40473,903.60669a1.14007,1.14007,0,0,0,.75954.29561q0.03036,0,.06072-0.0016l5.57827-.26206a0.481,0.481,0,0,0,.41386-0.31585l0.37444-1.04663a3.93443,3.93443,0,0,0-1.297-4.26586,3.58328,3.58328,0,0,0-.41279-0.24395,1.46521,1.46521,0,0,0-.13263-0.066q-0.15739-.0767-0.31851-0.14062l-0.03249-.01332c-0.017-.00746-0.03409-0.01491-0.0474-0.01917a0.33151,0.33151,0,0,0-.05326-0.01491,2.78419,2.78419,0,0,0-.41705-0.11931l-0.04048-.01332a3.554,3.554,0,0,0-.47191-0.06711c-0.04581-.00426-0.05167-0.00107-0.16458-0.00692-0.11079-.0016-0.21945-0.00426-0.3265.00107a1.23387,1.23387,0,0,0-.14647.00586,3.87538,3.87538,0,0,0-.4831.06232,3.75231,3.75231,0,0,0-2.44479,1.65223l-0.017.03995,0.11279,0.07663h0l-0.11385-.0745-0.7361,3.38063-0.01545.0767c-0.00107.1012-.00213,0.20187,0.00266,0.28443A1.13155,1.13155,0,0,0,314.40473,903.60669Zm5.50477-5.04085a3.26018,3.26018,0,0,1,1.04769,3.48342l-0.33023.92252-5.43606.25407a0.41738,0.41738,0,0,1-.17044-0.02663,0.43335,0.43335,0,0,1-.15446-0.09587,0.44783,0.44783,0,0,1-.14594-0.31851l-0.00586-.19974,0.04055-.18376,2.49319-.29242,0.00486-.002,0.001-.00013a0.69467,0.69467,0,0,0,.10859-0.04514,0.72705,0.72705,0,0,0,.25154-0.1046,0.71211,0.71211,0,0,0,.23376-0.32164l0.35154-.98218a0.70715,0.70715,0,0,0-.04953-0.58909,0.67493,0.67493,0,0,0-.138-0.17417l-0.03888-.03728a0.65017,0.65017,0,0,0-.18-0.10706l-0.03515-.01811a0.6022,0.6022,0,0,0-.08469-0.0245l-2.18007-.48576a3.10052,3.10052,0,0,1,1.88925-1.18351,3.2676,3.2676,0,0,1,1.74438.12464A3.20135,3.20135,0,0,1,319.9095,898.56585Zm-2.39738,1.7981,0.06012-.1271h0.00007l-0.01864.15273a0.04342,0.04342,0,0,1,.00213.03782l-0.35207.98218a0.05,0.05,0,0,1-.041.03036l-2.25837.26578,0.39575-1.83652Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <path
          className="cls-3"
          style={{ fill: '#fff' }}
          d="M317.1627,901.43994a0.05,0.05,0,0,0,.041-0.03036l0.35207-.98218a0.04342,0.04342,0,0,0-.00213-0.03782l0.01864-.15273h-0.00007l-0.06012.1271,0.00266,0.0006-0.0032.00053,0.00053-.00113-2.212-.49475-0.39575,1.83652Z"
          transform="translate(-301.66737 -897.05672)"
        />
        <polygon points="15.847 3.308 15.845 3.307 15.844 3.308 15.847 3.308" />
        <path
          className="cls-3"
          style={{ fill: '#fff' }}
          d="M319.86955,900.96483a0.337,0.337,0,0,0-.11345-0.01971,0.34687,0.34687,0,0,0-.32531.22324,0.32725,0.32725,0,0,0,.01139.21106,0.35877,0.35877,0,0,0,.00293.04827,0.34559,0.34559,0,0,0,.4596.163,0.33523,0.33523,0,0,0,.1751-0.19042,0.32706,0.32706,0,0,0-.01138-0.21086,0.35877,0.35877,0,0,0-.00293-0.04827A0.347,0.347,0,0,0,319.86955,900.96483Z"
          transform="translate(-301.66737 -897.05672)"
        />
      </g>
    </svg>
  );
};

export const ViewAllIcon = (props) => (
  <Icon {...props} component={() => <ViewAllSvg {...props} />} />
);

export const ViewAllSvg = () => {
  return (
    <svg
      id="BikerBed"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 11"
      width="14px"
      height="14px"
    >
      <g id="Preview">
        <path
          className="cls-1"
          style={{
            fill: '#333',
            stroke: '#333',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="M322.87189,2398.33443c-5.3467-.20858-8.7868,4.215-9.37189,4.99286,0.58509,0.77788,4.02519,5.20144,9.37189,4.99286,4.87455-.19015,7.95667-4.09884,8.62811-4.99286C330.82856,2402.43328,327.74644,2398.52459,322.87189,2398.33443Z"
          transform="translate(-313 -2397.82729)"
        />
        <circle
          className="cls-2"
          style={{
            fill: 'none',
            stroke: '#e6e7e7',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          cx="9.5"
          cy="5.2245"
          r="2.6033"
        />
      </g>
    </svg>
  );
};

/* eslint-disable no-useless-escape */
import axios from 'axios';
import mapUtils from 'app/libs/Utils/mapUtils';

let _source;

export const isLatLon = (val) => {
  const regexExp =
    /^((\-?|\+?)?\d+(\.\d+)?)(,|\s)\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;

  return regexExp.test(val);
};

export const getLatLon = (val) => {
  const strArr = val.split(/[, ]+/);

  return { lat: +strArr[0], lng: +strArr[1] };
};

export const getAddressFromLatLon = (val, callback, scope) => {
  const { lat, lng } = getLatLon(val);
  mapUtils.getTourstartGeocoder({ lat, lng }, (e) => {
    const suggestions = mapUtils.formateAddressFromGeocoder(e.data);
    if (suggestions.length > 0) {
      callback(suggestions, scope);
    } else {
      callback(
        [{ value: 'No address found', data: 555, disabled: true }],
        scope
      );
    }
  });
};

export const getTourPointAddress = (val, callback, scope) => {
  // cancel the previous request
  if (typeof _source !== typeof undefined) {
    _source.cancel('Operation canceled due to new request.');
  }
  if (!val) return;

  // save the new request for cancellation
  _source = axios.CancelToken.source();
  axios
    .get(
      `${process.env.API_URL}/api/services.getNominatimAutocomplete?query=${val}`,
      // cancel token used by axios
      { cancelToken: _source.token }
    )
    .then((resp) => {
      if (
        resp.data &&
        resp.data.suggestions &&
        resp.data.suggestions.length > 0
      ) {
        callback(resp.data.suggestions, scope);
      } else if (isLatLon(val)) {
        getAddressFromLatLon(val, callback, scope);
      } else {
        callback(
          [{ value: 'No address found', data: 555, disabled: true }],
          scope
        );
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.error('Request canceled', error);
      } else {
        console.error(error);
      }
    });
};

import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import * as clubActions from 'app/actions/Club/club_full_view.action';
import * as cssActions from 'app/actions/Core/css.effect.action';
import * as tourActions from 'app/actions/FullTour/full_tour_view.action';
import * as languageActions from 'app/actions/Language/language.action';
import * as loginActions from 'app/actions/Login/login.action';
import * as displayPointMapActions from 'app/actions/Map/display.point.action';
import * as mapActions from 'app/actions/Map/map.scope.action';
import * as movePointMapActions from 'app/actions/Map/move.point.action';
import * as mapPreviewActions from 'app/actions/Map/preview-marker-data.action';
import * as previewPolyMapActions from 'app/actions/Map/preview-polyline.action';
import * as tourMapActions from 'app/actions/Map/tour.full.data.action';
import * as counterMenuActions from 'app/actions/Menu/counter.action';
import * as folderAsyncMenuActions from 'app/actions/Menu/folders-async.actions';
import * as foldersMenuActions from 'app/actions/Menu/folders.actions';
import * as mainMenuActions from 'app/actions/Menu/main_menu.action';
import * as placeActions from 'app/actions/Place/place.action';
import * as proActions from 'app/actions/Pro/tourstart_pro.action';
import * as searchActions from 'app/actions/Search/search.action';
import * as tourDownloadActions from 'app/actions/TourDownload/tourDownloadGps.action';
import * as enterUserActions from 'app/actions/Users/user.enter.action';
import * as historyUserActions from 'app/actions/Users/user.history.action';
import * as signUserActions from 'app/actions/Users/user.sign.action';
import * as userActions from 'app/actions/Users/users.action';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

const allActionCreators = {
  ...clubActions,
  ...cssActions,
  ...tourActions,
  ...languageActions,
  ...loginActions,
  ...displayPointMapActions,
  ...mapActions,
  ...movePointMapActions,
  ...mapPreviewActions,
  ...previewPolyMapActions,
  ...tourMapActions,
  ...counterMenuActions,
  ...folderAsyncMenuActions,
  ...foldersMenuActions,
  ...mainMenuActions,
  ...placeActions,
  ...proActions,
  ...searchActions,
  ...tourDownloadActions,
  ...enterUserActions,
  ...historyUserActions,
  ...signUserActions,
  ...userActions,
  showLoading,
  hideLoading,
};

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(allActionCreators, dispatch);
};

import React from 'react';

import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import withTranslations from 'app/components/HOGs/withTranslations';

const Admin = ({ tr, divider }) => {
  const id = useSelector((store) => store.clubFullDataReducer.data.id);

  const isAdmin = useSelector(
    (store) => store.clubFullDataReducer.data.isAdmin
  );

  return isAdmin ? (
    <>
      <div className="administration_layout">
        <div className="administration_item">
          <Link to={`/club/manageteamadvanced/${id}`}>{tr.Administration}</Link>
        </div>
      </div>
      {divider}
    </>
  ) : null;
};

export default withTranslations(Admin);

import React, { useEffect, useState, Suspense } from 'react';

import { Drawer, Modal } from 'antd';

import { FormattedMessage } from 'react-intl';

import checkPrevLink from 'app/components/Utility/checkPrevUrlLink';

import { useSelector } from 'react-redux';

import { pageScope } from 'app/constants/mapConstants';

import MembersContainer from '../../Members/members.container';
import List from '../../List/list.container';

const VIEW = 'view';
const LIST = 'list';

const CustomMemberList = (props) => {
  const { openedScope } = props;
  return (
    <Modal
      title={false}
      visible={openedScope === pageScope.member}
      mask
      maskClosable
      closable={false}
      footer={null}
      centered
      wrapClassName="club_modal"
      zIndex={1002}
    >
      <MembersContainer {...props} />
    </Modal>
  );
};

const DrawerBikeClub = ({ children, openedScope, setOpenedScope }) => {
  const { previousLocation, currentLocation } = useSelector(
    (store) => store.locationUrl
  );

  const members =
    useSelector((store) => store.clubFullDataReducer.data.members) || [];

  const [state, setState] = useState(VIEW);

  const getLabel = () => {
    switch (openedScope) {
      case pageScope.club:
        return <FormattedMessage id="Bike Clubs" defaultMessage="Bike Clubs" />;
      case pageScope.bikerbed:
        return <FormattedMessage id="BikerBeds" defaultMessage="BikerBeds" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (currentLocation.split('/').slice(-1)[0] === 'list') {
      setState(LIST);
    } else {
      setState(VIEW);
    }
    if (openedScope === pageScope.club || openedScope === pageScope.bikerbed) {
      setState(LIST);
    }
  }, [currentLocation, openedScope]);

  return (
    <>
      <Drawer
        placement="right"
        width={350}
        mask={false}
        closable
        visible={state !== LIST}
        className="bikerClubDrawer"
        getContainer={false}
        onClose={() => checkPrevLink(previousLocation, currentLocation)}
      >
        {children}
      </Drawer>

      {state === LIST ? (
        <Suspense>
          <List
            label={getLabel()}
            profile={() => {
              setOpenedScope(null);
              setState(VIEW);
            }}
          />
        </Suspense>
      ) : null}

      {openedScope === pageScope.member ? (
        <CustomMemberList
          club
          dataClub={members}
          openedScope={openedScope}
          closeMemberList={() => setOpenedScope(null)}
        />
      ) : null}
    </>
  );
};

export default DrawerBikeClub;

/* eslint-disable */
/* eslint-disable prettier/prettier */
import * as React from "react";
import Icon from '@ant-design/icons';


export const NotCompletedIcon = props => <Icon {...props} component={()=><NotCompletedSvg {...props} />} />;

export const NotCompletedSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
        viewBox="0 0 13.33858 13.33858"
        width={width}
        height={height}
    >
        <g id="Tick_off_Progress_bar_Line_Separator" data-name="Tick off, Progress bar &amp; Line Separator">
            <circle className="cls-1" style={{fill: 'none', stroke: "#91177e", strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2}} cx="6.66929" cy="6.66929" r="5.66929"/>
        </g>
    </svg>
  )
};


export const NotCompletedFilledIcon = props => <Icon {...props} component={()=><NotCompletedFilledSvg {...props} />} />;

export const NotCompletedFilledSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
        viewBox="0 0 13.33858 13.33858"
        width={width}
        height={height}
    >
        <g id="Tick_off_Progress_bar_Line_Separator" data-name="Tick off, Progress bar &amp; Line Separator">
            <g>
            <g>
                <circle className="cls-1" style={{fill: '#d6af36'}} cx="6.66929" cy="6.66933" r="5.16919"/>
                <path className="cls-1" style={{fill: '#d6af36'}} d="M14.63037,258.02385a4.66931,4.66931,0,1,1-4.66928,4.66931,4.67456,4.67456,0,0,1,4.66928-4.66931m0-1a5.66931,5.66931,0,1,0,5.6693,5.66931,5.66936,5.66936,0,0,0-5.6693-5.66931h0Z" transform="translate(-7.96109 -256.0239)"/>
            </g>
            <circle className="cls-2" style={{fill: 'none', stroke: "#91177e", strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2}} cx="6.66929" cy="6.66929" r="5.66929"/>
            </g>
        </g>
    </svg>
  )
};



export const CompletedIcon = props => <Icon {...props} component={()=><CompletedSvg {...props} />} />;

export const CompletedSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
        viewBox="0 0 13.53134 13.67043"
        width={width}
        height={height}
    >
    <g id="Tick_off_Progress_bar_Line_Separator" data-name="Tick off, Progress bar &amp; Line Separator">
        <path className="cls-1" style={{fill: 'none', stroke: '#91177e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2}} d="M8.86471,229.13382l5.15271,5.15272L20.396,222.61611" transform="translate(-7.86471 -221.61611)"/>
    </g>
  </svg>
  )
};

export const StarIcon = props => <Icon {...props} component={()=><StarSvg {...props} />} />;

export const StarSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
      viewBox="0 0 19.17278 18.2344"
      width={width}
      height={height}
    >
      <g id="Control_buttons" data-name="Control buttons">
        <polygon className="cls-1" style={{fill: '#dfe0e1', stroke: '#a9a9a9' }} points="9.586 3.68 14.847 0.914 13.842 6.772 18.098 10.92 12.217 11.775 9.586 17.105 6.956 11.775 1.074 10.92 5.33 6.772 4.326 0.914 9.586 3.68"/>
      </g>
    </svg>
  )
};

export const StarFilledIcon = props => <Icon {...props} component={()=><StarFilledSvg {...props} />} />;

export const StarFilledSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
      viewBox="0 0 19.17278 18.2344"
      width={width}
      height={height}
    >
      <g id="Control_buttons" data-name="Control buttons">
        <polygon className="cls-1" style={{fill: '#d5af36', stroke: '#91177e'}} points="9.586 3.68 14.847 0.914 13.842 6.772 18.098 10.92 12.217 11.775 9.586 17.105 6.956 11.775 1.074 10.92 5.33 6.772 4.326 0.914 9.586 3.68"/>
      </g>
    </svg>
  )
};

export const MotorcyclistAndRouteIcon = props => <Icon {...props} component={()=><MotorcyclistAndRouteSvg {...props} />} />;

export const MotorcyclistAndRoutedSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
      viewBox="0 0 19.17278 18.2344"
      width={width}
      height={height}
    >
      <g id="Control_buttons" data-name="Control buttons">
        <polygon className="cls-1" style={{fill: '#d5af36', stroke: '#91177e'}} points="9.586 3.68 14.847 0.914 13.842 6.772 18.098 10.92 12.217 11.775 9.586 17.105 6.956 11.775 1.074 10.92 5.33 6.772 4.326 0.914 9.586 3.68"/>
      </g>
    </svg>
  )
};


export const CloseIcon = props => <Icon {...props} component={()=><CloseSvg {...props} />} />;

export const CloseSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
      viewBox="0 0 19.99923 19.99923"
      width={width}
      height={height}
    >
      <g id="Control_buttons" data-name="Control buttons">
        <line className="cls-1" style={{fill: 'none', stroke: "#91177e", strokeLinecap: "round", strokeWidth: 1.5}} x1="0.75" y1="19.24923" x2="19.24923" y2="0.75"/>
        <line className="cls-1" style={{fill: 'none', stroke: "#91177e", strokeLinecap: "round", strokeWidth: 1.5}} x1="19.24923" y1="19.24923" x2="0.75" y2="0.75"/>
      </g>
    </svg>
  )
}

export const MaximizeIcon = props => <Icon {...props} component={()=><MaximizeSvg {...props} />} />;

export const MaximizeSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
      viewBox="0 0 22.77871 16.62583"
      width={width}
      height={height}
      focusable="false"
      className="" 
      data-icon="block"
      aria-hidden="true"
      fill="currentColor"
    >
      <g id="Control_buttons" data-name="Control buttons">
        <rect className="cls-1" style={{fill: 'none', stroke: "#91177e", strokeWidth: 1.5}} x="0.75" y="0.75" width="21.27871" height="15.12583" rx="1.41732" ry="1.41732"/>
      </g>
    </svg>
  )
}

export const MinimizeIcon = props => <Icon {...props} component={()=><MinimizeSvg {...props} />} />;

export const MinimizeSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
      viewBox="0 0 27.66186 1.5"
      width={width}
      height={height}
    >
      <g id="Control_buttons" data-name="Control buttons">
        <line className="cls-1" style={{fill: "none", stroke: '#91177e', strokeLinecap: "round", strokeWidth: 1.5}} x1="0.75" y1="0.75" x2="26.91186" y2="0.75"/>
      </g>
    </svg>
  )
}

export const DownloadIcon = props => <Icon {...props} component={()=><DownloadSvg {...props} />} />;

export const DownloadSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
      viewBox="0 0 14.75 14.51824"
      width={width}
      height={height}
      className="download"
    >
    <g id="Share">
      <g id="Download_1" data-name="Download 1">
        <polygon className="cls-1" style={{fill: '#e6e7e7'}} points="7.375 9.218 10.626 6.009 8.166 6.009 8.165 6.009 8.165 1.015 6.585 1.015 6.585 6.01 4.124 6.01 7.375 9.218"/>
        <path className="cls-2" style={{fill: '#343434'}} d="M261.06562,610.65332a0.89069,0.89069,0,0,0,1.24951,0l3.52783-3.48193a0.88933,0.88933,0,0,0-.59668-1.55127h-1.752v-4.105a0.89086,0.89086,0,0,0-.89014-0.88965h-1.83105a0.89044,0.89044,0,0,0-.88965.88965v4.105h-1.75a0.88932,0.88932,0,0,0-.625,1.522Zm-0.165-4.01807v-4.99512h1.57959v4.99414h2.46143l-3.251,3.20947-3.25146-3.2085h2.46143Z" transform="translate(-254.31537 -600.62549)"/>
      </g>
      <rect className="cls-3" style={{fill: '#e6e7e7', stroke: '#343434', strokeLinecap: 'round', strokeLinejoin: 'round'}} x="0.5" y="11.50786" width="13.75" height="2.51038"/>
    </g>
    </svg>
  )
}

export const ShareIcon = props => <Icon {...props} component={()=><ShareSvg {...props} />} />;

export const ShareSvg = ({width='22px', height='22px'}) => {
  return (
    <svg
     viewBox="0 0 19.90311 25"
     width={width}
     height={height}
    >
      <g id="Share_the_entire_training_course" data-name="Share the entire training course">
        <g>
          <path className="cls-1" style={{fill: "#e6e7e7"}} d="M1052.00607,611.75055h-6.2851a0.97064,0.97064,0,0,1-.73669-0.33669,0.97072,0.97072,0,0,0,.73669.33676h6.2851a0.97041,0.97041,0,0,0,.73654-0.33676,1.16916,1.16916,0,0,0,.23308-0.368,1.16941,1.16941,0,0,1-.23315.368A0.97006,0.97006,0,0,1,1052.00607,611.75055Z" transform="translate(-1038.91196 -590.76369)"/>
          <polygon className="cls-1" style={{fill: "#e6e7e7"}} points="5.761 10.661 7.011 10.661 7.011 11.911 7.011 19.737 12.91 19.737 12.892 11.911 12.892 10.661 14.142 10.661 18.52 10.661 9.952 1.382 1.382 10.661 5.761 10.661"/>
          <path className="cls-2" style={{fill: "#343434"}} d="M1048.1269,591.10045l-8.904,9.64256a1.1788,1.1788,0,0,0,0,1.59538,0.9707,0.9707,0,0,0,.7367.33676h4.71382v7.9409a1.1375,1.1375,0,0,0,.3109.79781,0.97064,0.97064,0,0,0,.73669.33669h6.2851a0.97006,0.97006,0,0,0,.73646-0.33669,1.13913,1.13913,0,0,0,.31113-0.79781v-7.941h4.71375a0.97027,0.97027,0,0,0,.73654-0.33676,1.17816,1.17816,0,0,0,0-1.59538l-8.90366-9.64249A0.97417,0.97417,0,0,0,1048.1269,591.10045Zm9.30542,10.32463h-5.62866v1.25l0.01877,7.82555h-5.899v-9.07547h-5.629l8.56926-9.27994Z" transform="translate(-1038.91196 -590.76369)"/>
        </g>
        <path className="cls-2" style={{fill: "#343434"}} d="M1058.76125,607.3247v6.36011a2.08152,2.08152,0,0,1-2.08007,2.07889h-15.63593a2.0814,2.0814,0,0,1-2.07948-2.07889v-6.43153a0.60442,0.60442,0,0,1,1.20885,0v6.43153a0.87135,0.87135,0,0,0,.87063.87h15.63593a0.87148,0.87148,0,0,0,.87122-0.87V607.3247A0.60442,0.60442,0,0,1,1058.76125,607.3247Z" transform="translate(-1038.91196 -590.76369)"/>
      </g>
    </svg>
  )
}

export const MailIcon = props => <Icon {...props} component={()=><MailSvg {...props} />} />;

export const MailSvg = ({width='22px', height='22px'}) => {
  return (
    <svg 
      viewBox="0 0 19.93066 16.0918"
      width={width}
      height={height}
    >
      <g id="Write_to_us" data-name="Write to us">
        <g>
          <path className="cls-1" style={{fill: "#e6e7e7"}} d="M437.20471,611.56738l-2.28613,2.59473a0.97145,0.97145,0,0,1-.73633.34277h-0.002a0.97952,0.97952,0,0,1-.73926-0.3418l-2.18262-2.48047-5.38184,6.16895H442.5094Z" transform="translate(-424.21545 -602.86328)"/>
          <polygon className="cls-3" style={{fill: "#fff"}} points="18.935 1.94 13.687 7.905 18.935 14.119 18.935 1.94"/>
          <polygon className="cls-1" style={{fill: "#e6e7e7"}} points="9.965 10.546 18.269 1.104 1.66 1.104 9.965 10.546"/>
          <polygon className="cls-3" style={{fill: "#fff"}} points="0.996 14.15 6.347 8.023 0.996 1.942 0.996 14.15"/>
          <path className="cls-2" d="M443.64807,602.86328H424.7135a0.52774,0.52774,0,0,0-.498.55176v14.98828a0.52743,0.52743,0,0,0,.498.55176h18.93457a0.52743,0.52743,0,0,0,.498-0.55176V603.415A0.52774,0.52774,0,0,0,443.64807,602.86328Zm-18.43652,14.15039v-12.2085l5.35059,6.08154Zm8.96875-3.60449-8.30469-9.44238H442.484Zm-2.92187-1.72656,2.18262,2.48047a0.97952,0.97952,0,0,0,.73926.3418h0.002a0.97145,0.97145,0,0,0,.73633-0.34277l2.28613-2.59473,5.30469,6.28418H425.87658Zm11.8916-6.87939v12.1792l-5.248-6.21387Z" transform="translate(-424.21545 -602.86328)"/>
        </g>
      </g>
    </svg>
  )
}


export const ButtonStarIcon = props => <Icon {...props} component={()=><ButtonStarSvg {...props} />} />;

export const ButtonStarSvg = ({width='24px', height='24px'}) => {
  return (
    <svg 
      viewBox="0 0 65.83291 65.83291"
      width={width}
      height={height}
    >
      <circle className="cls-1" style={{fill: "#fff",  stroke: "#333", strokeMiterlimit: 10, strokeWidth: 2 }} cx="32.91646" cy="32.91646" r="32.41646"/>
      <polygon id="Star" style={{stroke: "#333", fill: "#e6e7e7", strokeWidth: 2 }} className="cls-2" points="33.066 24.469 43.429 19.022 41.449 30.56 49.833 38.732 38.247 40.416 33.066 50.914 27.885 40.416 16.299 38.732 24.683 30.56 22.704 19.022 33.066 24.469"/>
      <circle className="cls-3" style={{stroke: "#333", strokeMiterlimit: 10, fill: "none", strokeWidth: 1.5}} cx="32.91646" cy="32.96763" r="20"/>
    </svg>
  )
}
import pageCache from 'app/API/cache.service';
import _ from 'lodash';
import { applyingTypes } from 'app/constants/folder.constants';
import * as API from '../../API/folders.api';
import {
  getFolders,
  addFolder,
  removeFolder,
  getTours,
  updateFolder,
  updateFolders,
  getPlaceFolders,
  addPlaceFolder,
  updatePlaceFolder,
  removePlaceFolder,
  updatePlaceFolders,
} from './folders.actions';
import { setMyTours, setMyPlaces } from './counter.action';

export function fetchFolders(userId) {
  return (dispatch) => {
    if (userId) {
      API.getFolders(userId)
        .then((res) => res.data)
        .then((data) => {
          const { folders = {}, count = {} } = data;
          const { tour, place } = folders;
          const { myTours, myPlaces } = count;
          dispatch(getFolders(tour));
          dispatch(getPlaceFolders(place));
          dispatch(setMyTours(myTours));
          dispatch(setMyPlaces(myPlaces));
        })
        .catch((err) => {
          console.log(err);
          dispatch(getFolders([]));
          dispatch(setMyTours(0));
          dispatch(setMyPlaces(0));
        });
    } else {
      dispatch(getFolders([]));
      dispatch(setMyTours('loading'));
      dispatch(setMyPlaces('loading'));
    }
  };
}

export function createFolder(userId, folderAttr) {
  return (dispatch) => {
    API.createFolder(userId, folderAttr)
      .then((res) => res.data)
      .then((folders) => {
        if (folders.applying === applyingTypes.TOUR_FOLDER) {
          dispatch(addFolder(folders));
        } else {
          dispatch(addPlaceFolder(folders));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function deleteFolder(folderId, applying) {
  return (dispatch) => {
    API.deleteFolder(folderId)
      .then((res) => res.data)
      .then((folderId) => {
        if (applying === applyingTypes.TOUR_FOLDER) {
          dispatch(removeFolder(folderId));
        } else {
          dispatch(removePlaceFolder(folderId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function renameFolder(folderId, newTitle) {
  return (dispatch) => {
    API.renameFolder(folderId, newTitle)
      .then((res) => res.data)
      .then((folder) => {
        if (folder.applying === applyingTypes.TOUR_FOLDER) {
          dispatch(updateFolder(folder));
        } else {
          dispatch(updatePlaceFolder(folder));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchTours(folderId, userId, applying) {
  return async (dispatch) => {
    try {
      const res = await API.getTours(folderId, userId, applying);
      const tours = res.data.response;
      dispatch(getTours(tours));
      return tours;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
}

export function removeTourFromFolder(folderId, applying, ...toursId) {
  return (dispatch) => {
    API.removeTourFromFolder(folderId, toursId)
      .then((res) => res.data)
      .then((folder) => {
        if (applying === applyingTypes.TOUR_FOLDER) {
          dispatch(updateFolder(folder));
        } else {
          dispatch(updatePlaceFolder(folder));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function addTourToFolder(folderId, applying, ...toursId) {
  return (dispatch) => {
    API.addTourToFolder(folderId, toursId)
      .then((res) => res.data)
      .then((folders) => {
        if (applying === applyingTypes.TOUR_FOLDER) {
          dispatch(updateFolders(folders));
        } else {
          dispatch(updatePlaceFolders(folders));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function removeTourFromAllFolders(userId, tourId, applying) {
  return (dispatch) => {
    API.removeTourFromAllFolders(userId, tourId)
      .then((res) => res.data)
      .then((folders) => {
        if (applying === applyingTypes.TOUR_FOLDER) {
          dispatch(updateFolders(folders));
        } else {
          dispatch(updatePlaceFolders(folders));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export async function fetchDreamTours() {
  const dreamFoldersCookies = pageCache.get('dreamFolders') || {};
  API.fetchDreamTour()
    .then((res) => res.data)
    .then((data) => {
      const { code, result } = data;
      if (code === 200) {
        if (!_.isEqual(dreamFoldersCookies, result)) {
          pageCache.set('dreamFolders', result);
        }
      }
    });
}

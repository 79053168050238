import * as types from '../action_types';
import userAPI from '../../API/user.service.api';

export const getUserHistory = (id) => {
  return (dispatch) => {
    dispatch({
      type: types.USER_HISTORY_STARTED,
    });
    userAPI
      .getUserHistoryData(id)
      .then((res) => {
        dispatch({
          type: types.USER_HISTORY_SUCCESS,
          payload: JSON.parse(res.entities),
        });
      })
      .catch((err) => {
        dispatch({
          type: types.USER_HISTORY_FAILURE,
          payload: err,
        });
      });
  };
};

export const setHistoryScope = (scope, value) => {
  return {
    type: types.USER_HISTORY_CHANGE_SCOPE,
    payload: { scope, value },
  };
};

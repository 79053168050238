import React, { useEffect, useRef } from 'react';

import { Form, Input, Tooltip } from 'antd';

import withTranslations from 'app/components/HOGs/withTranslations';

import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';

import { Type } from 'app/constants/TourPoints';

import parse from 'html-react-parser';

import LocationIcon from 'static/img/club/position.svg';
import WebIcon from 'static/img/club/web.svg';

import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import { useSelector } from 'react-redux';
import { getCustomId } from 'app/libs/core/Unique';
import { setClubPosition } from '../../Map/map_contextmenu_utils';

import AutoCompleteAddressSearch from '../../../Utility/autocompleteAddressSearch';

import { SeeMapIcon } from '../../MainMenu/svg';

import { ProfileMailIcon } from '../../UserProfile/icons.svg';

const Contact = (props) => {
  const { tr, form } = props;

  const ref = useRef(null);

  const { setTypeMouseCursor } = useActions();

  const address = useSelector(
    (store) => store.clubFullDataReducer.data.address
  );
  const edit = useSelector((store) => store.clubFullDataReducer.isEditMode);
  const link = useSelector((store) => store.clubFullDataReducer.data.link);
  const contacts = useSelector(
    (store) => store.clubFullDataReducer.data.contacts
  );
  const lat = useSelector((store) => store.clubFullDataReducer.data.lat);
  const lng = useSelector((store) => store.clubFullDataReducer.data.lng);

  const showClubOnMap = () => {
    publish(way.CLUB_POINT_CREATE, {
      customId: getCustomId(),
      isLoading: false,
      point: { lat, lng, draggable: true, type: 'club_deact' },
    });
  };

  useEffect(() => {
    if (edit && form && address) {
      ref.current.update(parse(address));
      form.setFieldsValue({ address });
      if (lat && lng) showClubOnMap();
    }
  }, [address, edit]);

  useEffect(() => {
    if (edit && form && link) {
      form.setFieldsValue({ website: link });
    }
  }, [link, edit]);

  useEffect(() => {
    if (edit && form && contacts) {
      form.setFieldsValue({ additional: contacts });
    }
  }, [contacts, edit]);

  const onSelectAddressBikeClub = ({ rel }, setResultSearch) => {
    const e = { latlng: rel };
    setResultSearch([]);
    setClubPosition(e, tr);
  };

  const takeAddressByClickMap = () => {
    setTypeMouseCursor(`cursor_${Type.CLUB}`);
    publish(way.CLUB_EDIT_GET_ADDRESS_FROM_MAP_ON, (e) => {
      setClubPosition(e, tr);
      publish(way.CLUB_EDIT_GET_ADDRESS_FROM_MAP_OFF);
      setTypeMouseCursor('');
    });
  };

  const getAddon = () => (
    <Tooltip
      title={tr['Click on map to set address']}
      placement="topRight"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <div
        className="takeFromMapIcon"
        onClick={() => takeAddressByClickMap(tr)}
        aria-hidden
      >
        <SeeMapIcon />
      </div>
    </Tooltip>
  );

  const editAddress = () => {
    return (
      <Form.Item
        className="addressFormItem"
        name="address"
        rules={[
          {
            required: true,
            message: tr['Required fields are not filled in'],
          },
        ]}
      >
        <AutoCompleteAddressSearch
          className="club_create_address"
          onSelect={(option, setResultSearch) => {
            return onSelectAddressBikeClub(option, setResultSearch, tr);
          }}
          ref={ref}
        >
          <Input placeholder={tr['Write address']} addonAfter={getAddon()} />
        </AutoCompleteAddressSearch>
      </Form.Item>
    );
  };

  const editWeb = () => {
    return (
      <Form.Item
        name="website"
        rules={[
          {
            type: 'url',
            message: tr['Website url is not valid'],
          },
        ]}
      >
        <Input
          placeholder={tr['Link to your web site']}
          onFocus={() => {
            const field = form.getFieldsValue(['website']).website;
            return !field || field.length === 0
              ? form.setFieldsValue({ website: 'https://' })
              : link;
          }}
          onBlur={() => {
            return form.getFieldsValue(['website']).website === 'http://' ||
              form.getFieldsValue(['website']).website === 'https://'
              ? form.setFieldsValue({ website: '' })
              : false;
          }}
        />
      </Form.Item>
    );
  };

  const editAdditional = () => {
    return (
      <Form.Item name="additional">
        <Input.TextArea placeholder={tr['Additional contact information']} />
      </Form.Item>
    );
  };

  const getAddress = () => {
    if (edit) {
      return editAddress();
    }
    if (address) {
      return parse(address);
    }
    return tr['No address info yet'];
  };

  const getWebsite = () => {
    if (edit) {
      return editWeb();
    }
    if (link) {
      return (
        <a href={link} target="blank">
          {link}
        </a>
      );
    }
    return tr['No website info yet'];
  };

  const getAdditional = () => {
    if (edit) {
      return editAdditional();
    }
    if (contacts) {
      return parse(contacts);
    }
    return tr['No additional info yet'];
  };

  return (
    <div className="contact_layout">
      <div className="address">
        <div className="img_layout">
          <img src={LocationIcon} alt="" />
        </div>
        <div className="text">{getAddress()}</div>
      </div>

      <div className="website">
        <div className="img_layout">
          <img src={WebIcon} alt="" />
        </div>
        <div className="text">{getWebsite()}</div>
      </div>

      <div className="additional">
        <div className="img_layout">
          <ProfileMailIcon width={22} height={22} />
        </div>
        <div className="text">{getAdditional()}</div>
      </div>
    </div>
  );
};

export default withTranslations(Contact);

export const listFilter = Object.freeze({
  RATING: 'rating', // Sort by date using for Dreamtours detection
  NEW: 'new', // Sort by created date from new to old ones
  OLD: 'old', // Sort by created date from old to new ones
  SHORT: 'short', // Sort by distance from short to long ones
  LONG: 'long', // Sort by distance from long to short ones
  AZ: 'az', // Sort by title with alphabetic from A to Z
  ZA: 'za', // Sort by title with alphabetic from Z to A
  STAR: 'star', // Sort by availability star
});

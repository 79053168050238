import * as types from '../action_types';

export const setFilterScope = (payload) => {
  return {
    type: types.SEARCH_BAR_SCOPE,
    payload,
  };
};

export const setRoadTypesFilter = (data) => {
  return {
    type: types.SEARCH_BAR_ROAD_TYPES,
    payload: data,
  };
};

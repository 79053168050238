import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { message, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { clubAPI } from 'app/API/club.api';

import withTranslations from 'app/components/HOGs/withTranslations';

import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import { cachHandler } from 'app/components/Utility/notificationMessage';
import ModalPreviewPictures from 'app/components/Utility/modalPreviewPicture';

import deleted from 'static/img/Delete_purple.svg';
import search from 'static/img/Search_purple.svg';

const Pictures = ({ tr }) => {
  const { setClubPictures, setClubImageIds, loginModalOpen } = useActions();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [index, setIndex] = useState(0);

  const isLoggedIn = useSelector((store) => store.authentication.isLoggedIn);

  const clubId =
    useSelector((store) => store.clubFullDataReducer.data?.id) || 0;
  const images =
    useSelector((store) => store.clubFullDataReducer.data?.images) || [];
  const imgIds =
    useSelector((store) => store.clubFullDataReducer.data?.imgIds) || '';

  const getFileId = (img) =>
    img && img.response && img.response.response && img.response.response.id > 0
      ? img.response.response.id
      : '';

  const addFileId = (ids, file) => {
    let id = getFileId(file).toString();
    if (ids) {
      id += `,${ids}`;
    }
    return id;
  };

  const deletePictures = (img) => {
    clubAPI
      .removeClubPictureById(img.id)
      .then((res) => {
        const { code } = res;
        if (code === 200) {
          images.forEach((item) => {
            if (item === img) {
              images.splice(images.indexOf(item), 1);
              setClubPictures([...images]);
            }
          });
        } else {
          cachHandler(res);
        }
      })
      .catch((err) => cachHandler(err));
  };

  const handleChange = ({ file }) => {
    if (!isLoggedIn) {
      loginModalOpen(true);
      return;
    }
    if (file.status === 'done') {
      const res = file.response.response;
      const img = { id: res.id, title: '', src: `${res.src}` };
      setClubImageIds(addFileId(imgIds, file));
      message.success(`${file.name} ${tr['file uploaded successfully']}.`);
      setClubPictures([...images, img]);
    } else if (file.status === 'error') {
      message.error(`${file.name} ${tr['file upload failed']}.`);
    }
  };

  return (
    <div className="media_layout_create">
      <div className="title">{tr.Pictures}</div>
      <div className="clearfix">
        {images.map((item, i) => (
          <div className="list_item" key={item.id}>
            <img
              src={deleted}
              className="delete_btn"
              onClick={() => deletePictures(item)}
              alt=""
              aria-hidden
            />
            <img
              src={search}
              className="search_btn"
              alt=""
              aria-hidden
              onClick={() => {
                setPreviewVisible(true);
                setPreviewImage(item.src);
                setIndex(i);
              }}
            />
            <img
              src={item.src}
              className="backgroud"
              alt=""
              aria-hidden
              onClick={() => {
                setPreviewVisible(true);
                setPreviewImage(item.src);
                setIndex(i);
              }}
            />
          </div>
        ))}
        <Upload
          action={clubAPI.getUploadClubPictureURL(clubId)}
          listType="picture-card"
          onChange={handleChange}
          withCredentials
          multiple
        >
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">{tr.Upload}</div>
          </div>
        </Upload>
        {previewVisible ? (
          <ModalPreviewPictures
            visible={previewVisible}
            setVisible={setPreviewVisible}
            picture={previewImage}
            picturesData={images}
            index={index}
          />
        ) : null}
      </div>
    </div>
  );
};

export default withTranslations(Pictures);

import React from 'react';
import { ListView,SearchBar,LocaleProvider} from 'antd-mobile';
import back from '../../../../static/img/mobile/double-left-chevron.png';
import {history} from '../../../store';
import ruRU from 'antd-mobile/lib/locale-provider/ru_RU';

import './searchHeaders.css';
const SearchHeaders = ({handleClose=()=>{}, onChangeSearch}) => {
    return (
        <LocaleProvider locale={ruRU}>
            <div className="header">
                {/* <img
                    src={back}
                    alt="backArrow"
                    onClick={()=>handleClose(history.goBack)}
                /> */}
                <SearchBar placeholder="Search" onChange={(val) => onChangeSearch(val)}/>
            </div>
        </LocaleProvider>
    );
};
export default SearchHeaders;
const axios = require('axios');

const url = `${process.env.API_URL}/api`;

const handleResponse = (response) => {
  return new Promise((resolve, reject) => {
    const { data } = response;
    if (response.status !== 200) {
      const error = (data && data.message) || response.statusText;
      return reject(error);
    }
    return resolve(data);
  });
};

/*  Actual source */
let source = axios.CancelToken.source();

const updateSource = () => {
  if (source) source.cancel('');
  source = axios.CancelToken.source();
  return source.token;
};

function getMemberList(page, searchText) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    cancelToken: updateSource(),
  };
  return axios(
    `${url}/list.getMemberList?page=${page}&text=${searchText}`,
    requestOptions
  ).then(handleResponse);
}

function getStarList(text = '') {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    cancelToken: updateSource(),
  };
  return axios(`${url}/list.getStarList?text=${text}`, requestOptions).then(
    handleResponse
  );
}

export const memberAPI = {
  getMemberList,
  getStarList,
};

import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Upload, message, Tooltip } from 'antd';
import {
  DeleteFilled,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import ScrollMenu from 'react-horizontal-scrolling-menu';
import { FormattedMessage, useIntl } from 'react-intl';
import * as types from 'actions/action_types';

import { clubAPI } from 'app/API/club.api';

import { pageScope } from 'app/constants/mapConstants';
import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import withTranslations from 'app/components/HOGs/withTranslations';
import { cachHandler } from 'app/components/Utility/notificationMessage';
import {
  decodeSpecLetters,
  checkIfMainDomainAvilableInLink,
  isExistSub,
} from 'app/components/Utility/utility';
import ModalPreviewPictures from 'app/components/Utility/modalPreviewPicture';
import { fixHttps } from 'app/components/Utility/coreFunctions';
import profile from 'static/img/ProfilePictureLarge.svg';
import { ProfileMediaIcon, ViewAllIcon } from '../../UserProfile/icons.svg';
import { MemberIcon, BikerBedIcon, BikerClubIcon } from '../../MainMenu/svg';

const BikerClubMedia = (props) => {
  const { tr, scope, setOpenedScope } = props;
  const { setClubPictures, setClubImageIds } = useActions();

  const id = useSelector((store) => store.clubFullDataReducer.data.id);

  const members =
    useSelector((store) => store.clubFullDataReducer.data.members) || [];

  const isAdmin = useSelector(
    (store) => store.clubFullDataReducer.data.isAdmin
  );

  const images =
    useSelector((store) => store.clubFullDataReducer.data.images) || [];
  const imgIds =
    useSelector((store) => store.clubFullDataReducer.data?.imgIds) || '';
  const videos =
    useSelector((store) => store.clubFullDataReducer.data.videos) || [];
  const friends_bikerclub =
    useSelector((store) => store.clubFullDataReducer.data.friends_bikerclub) ||
    [];
  const friends_bikerbed =
    useSelector((store) => store.clubFullDataReducer.data.friends_bikerbed) ||
    [];

  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  /* Media */
  const [index, setIndex] = useState(0);
  const [visiblePreview, setVisiblePreview] = useState(false);

  const getData = () => {
    switch (scope) {
      case pageScope.media:
        return [...images, ...videos];
      case pageScope.member:
        return members;
      case pageScope.club:
        return friends_bikerclub;
      case pageScope.bikerbed:
        return friends_bikerbed;
      default:
        return [];
    }
  };

  const deleteImage = (e, img) => {
    e.stopPropagation();
    clubAPI
      .removeClubPictureById(img.id)
      .then((res) => {
        const { code } = res;
        if (code === 200) {
          const data = getData();
          data.forEach((item) => {
            if (item === img) {
              data.splice(data.indexOf(item), 1);
              setClubPictures([...data]);
            }
          });
        } else {
          cachHandler(res);
        }
      })
      .catch((err) => cachHandler(err));
  };

  const handleSeeAllOnClick = () => {
    switch (scope) {
      case pageScope.media:
        setVisiblePreview(true);
        break;
      case pageScope.member:
        setOpenedScope(pageScope.member);
        break;
      case pageScope.club:
        setOpenedScope(pageScope.club);
        dispatch({
          type: types.FETCH_CLUBS_ALL_FULFILLED,
          payload: getData().map((data) => {
            data.pointScope = pageScope.club;
            data.scope = pageScope.club;
            return data;
          }),
        });
        break;
      case pageScope.bikerbed:
        setOpenedScope(pageScope.bikerbed);
        dispatch({
          type: types.FETCH_BIKERBEDS_ALL_FULFILLED,
          payload: getData().map((data) => {
            data.pointScope = pageScope.bikerbed;
            data.scope = pageScope.bikerbed;
            return data;
          }),
        });
        break;
      default:
        break;
    }
  };

  const getIcon = () => {
    switch (scope) {
      case pageScope.media:
        return <ProfileMediaIcon />;
      case pageScope.member:
        return <MemberIcon />;
      case pageScope.club:
        return <BikerClubIcon />;
      case pageScope.bikerbed:
        return <BikerBedIcon />;
      default:
        return null;
    }
  };

  const getLink = (item) => {
    if (item.src) {
      return item.src;
    }
    if (!item.src) {
      return `https://img.youtube.com/vi/${item.source}/default.jpg`;
    }
    return item.prev;
  };

  const getSrc = (item) => {
    switch (scope) {
      case pageScope.media:
        return getLink(item);
      case pageScope.member:
        return fixHttps(checkIfMainDomainAvilableInLink(item.avatar));
      case pageScope.club:
        return fixHttps(checkIfMainDomainAvilableInLink(item.logo));
      case pageScope.bikerbed:
        return fixHttps(checkIfMainDomainAvilableInLink(item.logo));
      default:
        return '';
    }
  };

  const handleOnClick = (e, item, i) => {
    switch (scope) {
      case pageScope.media:
        setIndex(i);
        setVisiblePreview(true);
        break;
      case pageScope.member:
        history.push(item.url);
        break;
      case pageScope.club:
        if (isExistSub(item.url)) {
          window.location.href = item.url;
        } else {
          history.push(item.url);
        }
        break;
      case pageScope.bikerbed:
        if (isExistSub(item.url)) {
          window.location.href = item.url;
        } else {
          history.push(item.url);
        }
        break;
      default:
        break;
    }
  };

  const getEmptyTitle = () => {
    switch (scope) {
      case pageScope.media:
        return (
          <FormattedMessage
            id="No pictures yet"
            defaultMessage="No pictures yet"
          />
        );
      case pageScope.member:
        return (
          <FormattedMessage
            id="No members yet"
            defaultMessage="No members yet"
          />
        );
      case pageScope.club:
        return (
          <FormattedMessage
            id="No Bike Clubs friends yet"
            defaultMessage="No Bike Clubs friends yet"
          />
        );
      case pageScope.bikerbed:
        return (
          <FormattedMessage
            id="No BikerBeds friends yet"
            defaultMessage="No BikerBeds friends yet"
          />
        );
      default:
        return '';
    }
  };

  const getName = (item) => {
    switch (scope) {
      case pageScope.media:
        return isAdmin ? (
          <div className="name">
            <Tooltip
              title={intl.formatMessage({
                id: 'Delete',
                defaultMessage: 'Delete',
              })}
            >
              <DeleteFilled
                style={{ fontSize: 14 }}
                onClick={(e) => deleteImage(e, item)}
              />
            </Tooltip>
          </div>
        ) : null;
      case pageScope.member:
        return <div className="name">{decodeSpecLetters(item.name)}</div>;
      case pageScope.club:
        return <div className="name">{decodeSpecLetters(item.title)}</div>;
      case pageScope.bikerbed:
        return <div className="name">{decodeSpecLetters(item.title)}</div>;
      default:
        return null;
    }
  };

  const getFileId = (img) =>
    img && img.response && img.response.response && img.response.response.id > 0
      ? img.response.response.id
      : '';

  const addFileId = (ids, file) => {
    let id = getFileId(file).toString();
    if (ids) {
      id += `,${ids}`;
    }
    return id;
  };

  const GetUploadContainer = (props) => {
    const { children } = props;

    const propsUpload = {
      name: 'file',
      showUploadList: false,
      multiple: true,
      action: clubAPI.getUploadClubPictureURL(id),
      withCredentials: true,
      onChange(info) {
        if (info.file.status === 'done') {
          const res = info.file.response.response;
          const img = { id: res.id, title: '', src: `${res.src}` };
          setClubImageIds(addFileId(imgIds, info.file));
          message.success(
            `${info.file.name} ${tr['file uploaded successfully']}.`
          );
          setClubPictures([...getData(), img]);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} ${tr['file upload failed']}.`);
        }
      },
    };

    switch (scope) {
      case pageScope.media:
        return <Upload {...propsUpload}>{children}</Upload>;
      case pageScope.member:
        return <Link to={`/club/manageteamadvanced/${id}`}>{children}</Link>;
      case pageScope.club:
        return <Link to={`/club/managerelations/${id}`}>{children}</Link>;
      case pageScope.bikerbed:
        return (
          <Link to={`/club/managerelations/${id}?bikerbed=1`}>{children}</Link>
        );
      default:
        return null;
    }
  };

  const UploadButton = () => {
    return isAdmin ? (
      <GetUploadContainer>
        <Button type="primary" size="small" className="add">
          <PlusOutlined style={{ fontSize: 14 }} />
        </Button>
      </GetUploadContainer>
    ) : null;
  };

  const ViewAllButton = () => {
    return getData() && getData().length > 0 ? (
      <Tooltip
        title={intl.formatMessage({
          id: 'View all',
          defaultMessage: 'View all',
        })}
        placement="left"
      >
        <Button
          size="small"
          className="view_all"
          onClick={() => handleSeeAllOnClick()}
        >
          <ViewAllIcon />
        </Button>
      </Tooltip>
    ) : null;
  };

  const Menu = (list) =>
    list.map((item, i) => {
      return (
        <div
          className="item"
          key={item.id}
          onClick={(e) => handleOnClick(e, item, i)}
          aria-hidden
        >
          {getName(item)}
          <img src={getSrc(item) || profile} alt="" className="avatar" />
        </div>
      );
    });

  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>;
  };

  const ArrowLeft = Arrow({
    text: <LeftOutlined style={{ fontSize: 30 }} />,
    className: 'arrow-prev',
  });

  const ArrowRight = Arrow({
    text: <RightOutlined style={{ fontSize: 30 }} />,
    className: 'arrow-next',
  });

  const previewImageModal = () => {
    return (
      <ModalPreviewPictures
        visible={visiblePreview}
        setVisible={setVisiblePreview}
        picture={null}
        picturesData={getData()}
        index={index}
        deleteImage={deleteImage}
        isAuthUserPage={isAdmin}
      />
    );
  };

  return (
    <>
      <div className="media_layout">
        <div className="icon_layout">
          <div className="icon">{getIcon()}</div>
          <div className="count">{getData() && getData().length}</div>
        </div>
        <div className="list">
          {getData() && getData().length > 0 ? (
            <ScrollMenu
              data={Menu(getData())}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              hideArrows
              hideSingleArrow
              alignCenter={false}
              menuWidth="100%"
            />
          ) : (
            <div className="empty_title">{getEmptyTitle()}</div>
          )}
        </div>
        <div className="all_btn_layout">
          <UploadButton />
          <ViewAllButton />
        </div>
      </div>
      {visiblePreview ? previewImageModal() : null}
    </>
  );
};

export default withTranslations(BikerClubMedia);

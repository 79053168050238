import React, { useState, useRef, useImperativeHandle } from 'react';

import { AutoComplete, Input, Tag } from 'antd';

import { getTourPointAddress } from 'app/API/autocomplete';

const AutoCompleteAddressSearch = React.forwardRef((props, ref) => {
  const {
    children = <Input />,
    className = 'autocompleteAddressSearch',
    onSelect = () => console.log('onSelect'),
    callback = () => false,
  } = props;
  const inputRef = useRef(null);

  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setValue(null);
    },
    update: (val) => {
      setValue(val);
    },
  }));

  const result = (value) => {
    let res = [];

    res = value.map((el, i) => ({
      value: el.value,
      label: (
        <>
          <span>{el.value}</span>
          {el.tag ? <Tag>{el.tag.replace('_', ' ')}</Tag> : null}
        </>
      ),
      key: `${el.value}${i}`,
      rel: el.data,
    }));

    setOptions(res);
  };

  const handleSearch = (value) => {
    if (value) {
      if (value.length > 1) {
        getTourPointAddress(value, result);
      }
    } else {
      setOptions([]);
    }
  };

  return (
    <AutoComplete
      ref={(link) => {
        if (ref) {
          ref.current = link;
        }
        inputRef.current = link;
      }}
      value={value}
      onSearch={handleSearch}
      className={className}
      onSelect={(value, option) => onSelect(option, setOptions)}
      options={options}
      onChange={(val) => {
        setValue(val);
        callback(val);
      }}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {children}
    </AutoComplete>
  );
});

export default AutoCompleteAddressSearch;

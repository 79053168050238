import axios from 'axios';

const url = `${process.env.API_URL}/api`;

const handleResponse = (response) => {
  return new Promise((resolve, reject) => {
    const { data } = response;
    if (response.status !== 200) {
      const error = (data && data.message) || response.statusText;
      return reject(error);
    }
    return resolve(data);
  });
};

function myBikerBedsList() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${url}/club.myBikerBedsList`, requestOptions).then(
    handleResponse
  );
}

function myClubList() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${url}/club.myClubList`, requestOptions).then(handleResponse);
}

function previewClub(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.previewClub?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function previewBeds(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.previewBeds?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getClubFullInfo(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.getClubFullInfo?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getMembers(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${url}/club.getMembers?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getDescriptionForMobileList(id, scope) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${url}/club.getDescriptionForMobileList?id=${id}&scope=${scope}`,
    requestOptions
  ).then(handleResponse);
}

function getHistory(id, page) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${url}/club.getHistory?id=${id}&page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function getRequestInfo(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${url}/club.getRequestInfo?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function joinToClub(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${url}/club.joinToClub?club_id=${id}`, requestOptions).then(
    handleResponse
  );
}

function leaveClub(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${url}/club.leaveClub?club_id=${id}`, requestOptions).then(
    handleResponse
  );
}

function updateClub(id, club, lat, lng, scope, options, additionaly) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    params: {
      lat,
      lng,
      id,
      data: club,
      scope,
      options,
      additionaly,
    },
  };

  return axios(`${url}/club.setInfo`, requestOptions).then(handleResponse);
}

function getBikerbedFullInfo(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.getBikerbedFullInfo?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function followToBikerbed(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${url}/club.followToBikerbed?bikerbed_id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function unfollowBikerbed(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${url}/club.unfollowBikerbed?bikerbed_id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function isFollowBikerBed(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.isFollowBikerBed?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getFollowersBikerBed(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${url}/club.getFollowersBikerBed?id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function getServicesBikerBed(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.getServicesBikerBed?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getHistoryBikerBed(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.getHistoryBikerBed?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getToursClub(id, scope = 'club') {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${url}/club.getToursClub?id=${id}&scope=${scope}`,
    requestOptions
  ).then(handleResponse);
}

function getChangedUploadLogo() {
  return `${url}/club.uploadClubLogo`;
}

function getChangedUploadCover() {
  return `${url}/club.uploadClubCover`;
}

function createClub(club) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data: {
      data: club,
    },
  };

  return axios(`${url}/club.createClub`, requestOptions).then(handleResponse);
}

function deleteClub(club) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.deleteClub?id=${club}`, requestOptions).then(
    handleResponse
  );
}

function getUploadClubPictureURL(id) {
  let str = `${url}/club.uploadClubPicture`;
  if (id) str += `?id=${id}`;
  return str;
}

function removeClubPictureById(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.removeImage?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function uploadClubVideo(base, id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${url}/club.uploadClubVideo?up_type=url&type=video&url=${base}&id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function addComment(params) {
  const requestOptions = {
    method: 'GET',
    params,
    withCredentials: true,
  };

  return axios(`${url}/club.addCommentForClub`, requestOptions).then(
    handleResponse
  );
}

function uploadCommentClubPicture(club_id, comment_id) {
  return `${url}/club.uploadCommentClubPicture?club_id=${club_id}&comment_id=${comment_id}`;
}

function requestClub(id, type) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${url}/club.requestClub?club_id=${id}&type=${type}`,
    requestOptions
  ).then(handleResponse);
}

function wlaClubPoi(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${url}/club.wlaClubPoi?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getClubByName(name) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${url}/club.getClubByName?name=${name}`, requestOptions).then(
    handleResponse
  );
}

function checkSubdomain(domain, club_id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${url}/club.checkSubdomain?domain=${domain}&object_id=${club_id}`,
    requestOptions
  ).then(handleResponse);
}

function setSubdomain(domain, club_id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${url}/club.setSubdomain?domain=${domain}&object_id=${club_id}`,
    requestOptions
  ).then(handleResponse);
}

function is_admin(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.is_admin?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function get_members(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };
  return axios(`${url}/club.get_members`, requestOptions).then(handleResponse);
}

function get_users(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };
  return axios(`${url}/club.get_users`, requestOptions).then(handleResponse);
}

function get_title(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${url}/club.get_title?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function accept(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.accept`, requestOptions).then(handleResponse);
}

function reject(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.reject`, requestOptions).then(handleResponse);
}

function add(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.add`, requestOptions).then(handleResponse);
}

function get_all(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.get_all`, requestOptions).then(handleResponse);
}

function get_not_related_clubs(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.get_not_related_clubs`, requestOptions).then(
    handleResponse
  );
}

function add_request(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.add_request`, requestOptions).then(handleResponse);
}

function accept_relation(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.accept_relation`, requestOptions).then(
    handleResponse
  );
}

function remove_relation(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.remove_relation`, requestOptions).then(
    handleResponse
  );
}

function decline_relation(data) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data,
  };

  return axios(`${url}/club.decline_relation`, requestOptions).then(
    handleResponse
  );
}

export const clubAPI = {
  previewClub,
  previewBeds,
  getClubFullInfo,
  getMembers,
  getHistory,
  getRequestInfo,
  requestClub,
  joinToClub,
  leaveClub,
  updateClub,
  getDescriptionForMobileList,

  getBikerbedFullInfo,
  followToBikerbed,
  unfollowBikerbed,
  isFollowBikerBed,
  getFollowersBikerBed,
  getServicesBikerBed,
  getHistoryBikerBed,

  getToursClub,
  getChangedUploadLogo,
  getChangedUploadCover,

  createClub,
  deleteClub,
  getUploadClubPictureURL,
  removeClubPictureById,

  uploadClubVideo,

  myBikerBedsList,
  myClubList,

  addComment,
  uploadCommentClubPicture,

  wlaClubPoi,
  getClubByName,
  checkSubdomain,
  setSubdomain,
  is_admin,
  get_members,
  get_users,
  get_title,
  accept,
  reject,
  add,
  get_all,
  get_not_related_clubs,
  add_request,
  accept_relation,
  remove_relation,
  decline_relation,
};

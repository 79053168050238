import * as type from '../action_types';

export const getFolders = (folders) => ({
  type: type.FOLDERS_GET,
  payload: folders,
});

export const addFolder = (folder) => ({
  type: type.FOLDERS_ADD,
  payload: folder,
});

export const removeFolder = (folderId) => ({
  type: type.FOLDERS_REMOVE,
  payload: folderId,
});

export const updateFolder = (folderAttr = []) => ({
  type: type.FOLDERS_UPDATE,
  payload: folderAttr,
});

export const updateFolders = (foldersAttr = []) => ({
  type: type.FOLDERS_UPDATE_ALL,
  payload: foldersAttr,
});

export const getTours = (payload) => ({
  type: type.FETCH_TOURS_PUBLIC_FULFILLED,
  payload,
});

export const resortFolders = (prev, next) => ({
  type: type.FOLDERS_RESORT,
  payload: { prev, next },
});

export const getPlaceFolders = (folders) => ({
  type: type.FOLDERS_PLACE_GET,
  payload: folders,
});

export const addPlaceFolder = (folder) => ({
  type: type.FOLDERS_PLACE_ADD,
  payload: folder,
});

export const removePlaceFolder = (folderId) => ({
  type: type.FOLDERS_PLACE_REMOVE,
  payload: folderId,
});

export const updatePlaceFolder = (folderAttr) => ({
  type: type.FOLDERS_PLACE_UPDATE,
  payload: folderAttr,
});

export const updatePlaceFolders = (foldersAttr) => ({
  type: type.FOLDERS_PLACE_UPDATE_ALL,
  payload: foldersAttr,
});

export const resortPlaceFolders = (prev, next) => ({
  type: type.FOLDERS_PLACE_RESORT,
  payload: { prev, next },
});

import React, { useEffect } from 'react';

import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';

import store from 'app/store';
import * as types from 'app/actions/action_types';

const setMapFriendsClub = (clubs) => {
  const arr = [];

  clubs.forEach((club) => arr.push({ ...club, pointScope: 'club' }));

  return arr;
};

const setMapFriendsBeds = (beds) => {
  const arr = [];

  beds.forEach((bed) => arr.push({ ...bed, pointScope: 'bikerbed' }));

  return arr;
};

const setMapTours = (tours) => {
  const arr = [];

  tours.forEach((tour) => {
    arr.push({ ...tour, pointScope: 'tour' });
  });

  return arr;
};

const setMapPoi = (pois) => {
  const arr = [];

  pois.forEach((poi) => arr.push({ ...poi }));

  return arr;
};

const mainClubMarker = (club) =>
  club.lat && club.lng ? [{ ...club, pointScope: 'main_club_marker' }] : [];

const BikeClubViewFilters = ({ clubData, tours, poi, openedScope }) => {
  const { friends_bikerclub = [], friends_bikerbed = [] } = clubData;

  useEffect(() => {
    return () => {
      publish(way.CLUB_MAP_CLEAN);
    };
  }, []);

  useEffect(() => {
    if (!openedScope) {
      const all = [
        ...setMapPoi(poi),
        ...setMapFriendsBeds(friends_bikerbed),
        ...setMapFriendsClub(friends_bikerclub),
        ...setMapTours(tours),
        ...mainClubMarker(clubData),
      ];
      store.dispatch({
        type: types.FETCH_TOURS_PUBLIC_FULFILLED,
        payload: all,
      });
    }
  }, [tours, poi, friends_bikerclub, friends_bikerbed, openedScope]);

  return <div />;
};

export default BikeClubViewFilters;

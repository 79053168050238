import React from 'react';
import view from 'static/img/preview/preview_view_newdesign.png';
import download from 'static/img/preview/preview_download_newdesign.png';
import { FormattedMessage } from 'react-intl';

export default function PreviewInfoDownloadView({
  previewJson,
  isViewsAvailable = false,
}) {
  return (
    <div className="entityPreviewBodyContentInfo">
      <div className="viewInfo">
        <div>
          <img src={download} alt="download" style={{ marginRight: 5 }} />
          <span
            style={{
              color: '#231f20',
              fontSize: 10,
              fontWeight: 400,
            }}
          >
            {previewJson.downloads}
            <span style={{ marginLeft: 3 }}>
              <FormattedMessage id="download" defaultMessage="download" />
            </span>
          </span>
        </div>
        {isViewsAvailable && (
          <div style={{ marginLeft: -3 }}>
            <img src={view} alt="view" style={{ marginRight: 5 }} />
            <span
              style={{
                color: '#231f20',
                fontSize: 10,
                fontWeight: 400,
              }}
            >
              {previewJson.views}
              <span style={{ marginLeft: 3 }}>
                <FormattedMessage id="preview" defaultMessage="preview" />
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

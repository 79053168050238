import React, { useEffect, Suspense } from 'react';
import { Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom';

import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import { ErrorBoundary } from 'app/components/Utility/errorBoundary';
import { retry } from 'app/components/Utility/loadingChunkErrorHandler';

import {
  SEARCH_BAR_BIKE_CLUB,
  SEARCH_BAR_ALL,
} from 'app/constants/mainSearch.constants';

import pageDataAPI from 'app/API/pageData.api';

import AllBikeClub from './bikeClub.all';
import MyBikeClubs from './bikeClub.my';
import NafClubs from './bikeClub.naf';

import BikeClubView from './components/BikeClubView';
import BikeClubCreate from './components/BikeClubCreate';
import ManageTeamAdvanced from './Admin/ManageTeamAdvanced';
import TeamRelations from './Admin/TeamRelations';

import './bikerClub.scss';

const Previewer = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "previewtour" */ '../TourPreview/preview.conteiner'
    )
  )
);

const BikerClubContainer = ({ subDomainClubId }) => {
  const location = useLocation();

  const { cleanPreviewTourData, init, setFilterScope } = useActions();

  let link = subDomainClubId ? '/' : '/club';

  const match = useRouteMatch('/club/:page');
  const matchManage = useRouteMatch('/club/:pageManage/:idManage');

  if (match) link = match.url;

  const page =
    subDomainClubId || (match && match.params ? match.params.page : 'all');

  const idManage =
    matchManage && matchManage.params ? matchManage.params.idManage : '';

  useEffect(() => {
    setFilterScope(SEARCH_BAR_BIKE_CLUB);

    return () => {
      pageDataAPI.sourceCancel();
      setFilterScope(SEARCH_BAR_ALL);
      cleanPreviewTourData();
      init();
    };
  }, []);

  useEffect(() => {
    cleanPreviewTourData();
    init();
  }, [page]);

  const clubId =
    subDomainClubId || parseInt(location.pathname.split('_').splice(-1).pop());

  return (
    <ErrorBoundary>
      <Route
        path={`${link}`}
        render={(props) => {
          switch (page) {
            case 'all':
            case 'list':
              return (
                <Suspense fallback={<div />}>
                  <AllBikeClub {...props} page={page} init={init} />
                </Suspense>
              );
            case 'my':
              return (
                <Suspense fallback={<div />}>
                  <MyBikeClubs {...props} page={page} />
                </Suspense>
              );
            case 'naf':
              return (
                <Suspense fallback={<div />}>
                  <NafClubs {...props} page={page} />
                </Suspense>
              );
            case 'create':
              return (
                <Suspense fallback={<div />}>
                  <BikeClubCreate page={page} />
                </Suspense>
              );

            case 'manageteamadvanced':
              return (
                <Suspense fallback={<div />}>
                  <ManageTeamAdvanced page={page} clubId={idManage} />
                </Suspense>
              );

            case 'managerelations':
              return (
                <Suspense fallback={<div />}>
                  <TeamRelations page={page} clubId={idManage} />
                </Suspense>
              );

            default:
              if (clubId || page === 'edit') {
                return (
                  <Suspense fallback={<div />}>
                    <BikeClubView
                      page={page}
                      clubId={clubId}
                      location={location}
                    />
                  </Suspense>
                );
              }
              return <Redirect to="/club" />;
          }
        }}
      />
      <Suspense fallback={null}>
        <Previewer />
      </Suspense>
    </ErrorBoundary>
  );
};

export default BikerClubContainer;

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';

import './listMobile.css';

import { ListView, ActivityIndicator } from 'antd-mobile';
import { Typography, Card } from 'antd';

import PropTypes from 'prop-types';
import { MapDataDataProps } from '../../../constants/PropType/propTypesMapData';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchHeaders from '../searchHeaders/searchheaders';
import tourPrevAPI from '../../../API/tour.prev.api';

const { Paragraph } = Typography;

//IMAGE
import default_logo from 'static/img/Tourstart_org.jpg';

import { pageScope } from '../../../constants/mapConstants';

import { decodeSpecLetters } from '../../Utility/utility';

import ListItemMobile from './components/mobileListItem';

function MyBody(props) {
  return <div className="am-list-body my-body">{props.children}</div>;
}
const DATA = [];
export default class TourListMobile extends React.Component {
  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2 && r1.id !== r2.id,
    });
    this.state = {
      dataSource: dataSource.cloneWithRows(DATA),
      isLoading: true,
    };
  }

  componentDidMount() {
    const { data, dataMapFetching } = this.props;
    if (data && !dataMapFetching) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(data),
        isLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { data, dataMapFetching } = this.props;
    if (prevProps.data !== data && !dataMapFetching) {
      this.setState({
        dataSource: new ListView.DataSource({ rowHasChanged: (r1, r2) => r1 !== r2 }).cloneWithRows(data),
        isLoading: false,
      });
    }
  }

  render() {
    const { data, scope } = this.props;

    return (
      <div className="tourListMobileLayout">
        {this.state.isLoading ? (
          <ActivityIndicator toast text="loading" />
        ) : (
            <ListView
              ref={(el) => (this.lv = el)}
              dataSource={this.state.dataSource}
              renderBodyComponent={() => <MyBody />}
              renderRow={(rowData) => (
                <ListItemMobile scope={scope} item={rowData} />
              )}
              pageSize={4}
              onScroll={() => false}
              scrollRenderAheadDistance={500}
            />
          )}
      </div>
    );
  }
}

TourListMobile.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(MapDataDataProps)),
};

import React from 'react';

// import PropTypes from 'prop-types';
import { fixHttps } from 'app/components/Utility/coreFunctions';
import { ToursIcon } from '../../MainMenu/svg';
import { StarFilledIcon } from '../../TourstartAcademy/academy.svg';
//
// UTILITY
import { getImgBig, getSmallImg } from '../../../Utility/utility';

const ItemTypeListLayout = (props) => {
  const {
    title,
    star = false,
    iconType = <ToursIcon />,
    meas,
    edit = null,
    action = null,
    handleMouseEnter,
    handleMouseLeave,
    handleOnClick,
    type = null,
    item,
  } = props;

  const getImages = () => {
    if (type === 'place') {
      return (
        <div className="images_layout">
          {item.images && item.images.length > 0
            ? getSmallImg(item)
            : getImgBig(item, {
                height: '200px',
                width: '100%',
                objectFit: 'cover',
              })}
        </div>
      );
    }

    if (type) {
      return (
        <div className="images_layout">
          <img src={fixHttps(item.map_tour_image)} className="route" alt="" />
          <div className="images">
            {item.images && item.images.length > 0
              ? getSmallImg(item)
              : getImgBig(item, { height: 200 })}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className="list_item_layout"
      onMouseEnter={(e) => handleMouseEnter(e)}
      onMouseLeave={(e) => handleMouseLeave(e)}
      onClick={(e) => handleOnClick(e)}
      role="listitem"
      aria-hidden="true"
    >
      {getImages()}
      <div className="info">
        <div className="title">{title}</div>
        {star ? (
          <StarFilledIcon
            width="22px"
            height="22px"
            className="list_star_second"
          />
        ) : null}
        <div className="typeSvg">{iconType}</div>
      </div>
      <div className="list_item_action">
        {meas}
        {edit}
        {action}
      </div>
    </div>
  );
};

ItemTypeListLayout.propTypes = {
  // title: PropTypes.string,
  // star: PropTypes.bool,
  // iconType: PropTypes.element,
  // meas: PropTypes.element,
  // edit: PropTypes.element,
  // action: PropTypes.element,
  // handleMouseEnter: PropTypes.func,
  // handleMouseLeave: PropTypes.func,
  // handleOnClick: PropTypes.func,
  // type: PropTypes.string,
  // item: PropTypes.object,
};

ItemTypeListLayout.defaultProps = {};

export default ItemTypeListLayout;

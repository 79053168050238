import * as types from '../action_types';

export const setPreviewTourData = (data) => {
  return {
    type: types.FETCH_PREVIEW_DATA_FULFILLED,
    payload: data,
  };
};
export const cleanPreviewTourData = () => {
  return {
    type: types.FETCH_PREVIEW_DATA_CLEANE,
  };
};

export const cleanPreviewPolyTourData = () => {
  return {
    type: types.FETCH_PREVIEW_POLYLINE_CLEANE,
  };
};

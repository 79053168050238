import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import withTranslations from 'app/components/HOGs/withTranslations';

import { pageScope } from 'app/constants/mapConstants';
import List from '../List/list.container';
import pageDataAPI from '../../../API/pageData.api';

const MyBikeClubs = ({ tr, match }) => {
  const { getMyClubs, init, scope } = useActions();
  const isLoggedIn = useSelector((store) => store.authentication.isLoggedIn);
  const loginLoad = useSelector((store) => store.authentication.loginLoad);

  useEffect(() => {
    if (!loginLoad && isLoggedIn) getMyClubs();
    scope(pageScope.club);
    return () => {
      pageDataAPI.sourceCancel();
      init();
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn && !loginLoad) {
      getMyClubs();
    }
    if (!isLoggedIn && !loginLoad) {
      notification.warning({
        message: tr['Login to see your clubs'],
        duration: 0,
        top: 80,
      });
    }
  }, [isLoggedIn]);

  const getLabel = () => (
    <FormattedMessage id="My Bike Clubs" defaultMessage="My Bike Clubs" />
  );

  return (
    <Route
      path={`${match.url}/list`}
      render={() => <List label={getLabel()} />}
    />
  );
};

export default withTranslations(MyBikeClubs);

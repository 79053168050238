import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Tooltip, Button } from 'antd';
import { trackAPI } from '../../../../API/track.api';
import { ConvertDistance, urlTitle, isImageExists, getImgBig } from '../../../Utility/utility';
import { LengthType } from 'app/constants/user.constants';
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteIcon } from '../../TourFullView/components/svg';
import store from "app/store";
import { history } from 'app/store';
import { publish } from "app/libs/core/PubSub";
import * as way from "app/libs/core/PubSubWays";
import * as types from "actions/action_types";
import { TrackIcon } from '../../MainMenu/svg';
import { pageScope } from '../../../../constants/mapConstants';
import ItemTypeListLayout from '../listComponents/listItemLayout';
import { deleteTrack } from '../../Track/trackPreview/trackPreview';
//CONST
const TYPE_BLOCK = "block";

export const handleClick = (item, props) => {
  history.push('/track');
  const scope = item.scope;
  const trackId = item.id;
  const trackList = store.getState().mapDataReducer.data;
  const hiperPrevTrackData = trackList[trackList.findIndex(t=>t.id===trackId)];
  store.dispatch((dispatch)=>{
    dispatch({
      type: types.FETCH_PREVIEW_DATA_FULFILLED,
      payload: {...hiperPrevTrackData, scope}
    })
    dispatch({
      type: types.FETCH_PREVIEW_POLYLINE,
      payload: trackAPI.getAproxDataTrack(trackId)
                    .then(res => {
                      publish(way.SHOW_POLYLINE_FIT_BOUNDS, res.response.parts[0].poly)
                      return res.response
                    })
    });
  });
};

let delay = null;
const scope = pageScope.track;
const handleMouseEnter = props => {
  const { item, data, previewJson } = props;
  if(delay){
    clearTimeout(delay);
  }
  const trackList = data;
  const trackId = item.id;
  const hiperPrevTrackData = trackList[trackList.findIndex(t=>t.id===trackId)];

  delay = setTimeout(()=>{
    const prevTrack = previewJson;
    if(!prevTrack || prevTrack.id != trackId){
      store.dispatch((dispatch)=>{
        dispatch({
          type: types.FETCH_PREVIEW_DATA_FULFILLED,
          payload: {...hiperPrevTrackData, scope}
        })
        dispatch({
          type: types.FETCH_PREVIEW_POLYLINE,
          payload: trackAPI.getAproxDataTrack(trackId)
            .then(res => res.response)
        });
      });
    }
  }, 500);
}
const handleMouseLeave = () => {
  if(delay){
    clearTimeout(delay);
  }
}

const blockItem = (item, props, getDistAndDuration, user) => {
    const imgStyle = isImageExists(props.previewJson) ? {} : { height: '100%' };

    return(
      <Card 
        bordered={true} 
        onClick={() => handleClick(item, props)}
        onMouseEnter={e => handleMouseEnter(props)} 
        onMouseLeave={handleMouseLeave}
      >
      <div style={{position: "absolute", top: 150, right: 5}} className="typeSvg">
        <TrackIcon/>
      </div>
      <div className="ListBlockImg">
        {getImgBig(props.previewJson, imgStyle)}
      </div>
      {item.country && 
          <ErrorBoundary errorView={<div style={{display:'none'}}></div>}>
            <CountryFlag country={item.country}/>
          </ErrorBoundary>
        }
      <div className="preview-card-info">
        <div className="preview-card-title" style={{fontSize: "14px", color: '#aa2493', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%", fontWeight: 'bold', textTransform: 'uppercase'}}>{item.title}</div>
        {getDistAndDuration()}
      </div>
    </Card>
    )
}

const ListTrackItem = (props) => {
  const { item, type } = props;
  const {user} = useSelector(store => store.authentication);

  const intl = useIntl();

  const getDistAndDuration = () => (
    <div className="preview-card-small-info" style={{alignItems: 'center', fontSize: "12px", color: 'gray', fontWeight: 'bold', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100%", lineHeight: "24px"}}>
      <ConvertDistance distance={item.distance} duration={item.duration} type={user.units ? user.units: LengthType.KM}/>
    </div>
  );

  const getAction = () => (
    <div style={{display: "flex"}}>
        <Tooltip
          title={<FormattedMessage id="Delete" defaultMessage="Delete" />}
        >
          <Button 
            style={{width:20, height:20, backgroundColor: "transparent", border: "none", display:"flex", justifyContent:'center', alignItems:"center", padding: 0}} 
            className="deleteTour"
            onClick={(e) => {
              e.stopPropagation();
              deleteTrack(item.id, intl);
            }}
          >
            <DeleteIcon style={{width:16, height:16}}/>
          </Button>
        </Tooltip>
    </div>
  )    
    return (type === TYPE_BLOCK)
        ? blockItem(item, props, getDistAndDuration, user)
        : <ItemTypeListLayout 
            title={item.title}
            iconType={<TrackIcon />}
            meas={getDistAndDuration()}
            // edit={item.updated_at ? getDateEdit() : null}
            action={getAction()}
            handleMouseEnter={(e) => handleMouseEnter(props)} 
            handleMouseLeave={(e) => handleMouseLeave()}
            handleOnClick={(e) => handleClick(item, props)}
          />

}

export default  ListTrackItem;
import React from 'react';
import { connect } from 'react-redux';
import { setFilterScope } from 'app/actions/Search/search.action';
import { bindActionCreators } from 'redux';
import { memberAPI } from 'app/API/members.api';
import withMobileDetecting from 'app/components/HOGs/detectMobileDesign';
import Members from './members';
import MembersMobile from '../../Mobile/Members/members';
import {
  SEARCH_BAR_ALL,
  SEARCH_BAR_MEMBER,
} from '../../../constants/mainSearch.constants';
import userAPI from '../../../API/user.service.api';
import { cachHandler } from '../../Utility/notificationMessage';
import { ErrorBoundary } from '../../Utility/errorBoundary';

const MEMBER = 'member';
const FRIEND = 'friend';
const CLUB = 'club';
const STAR = 'star';

const getStarFromStrSearch = (str) => {
  const index = str.indexOf(STAR);
  return index > -1;
};

class MembersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      searchText: '',
      data: [],
      loadingFirst: false,
      handlerLoading: false,
      hasMore: false,
      admin: false,
      scope: MEMBER,
    };
  }

  componentWillUnmount() {
    this.props.setFilterScope(SEARCH_BAR_ALL);
  }

  componentDidMount() {
    const { profile, club } = this.props;
    const { page, searchText } = this.state;
    this.setState({
      loadingFirst: true,
    });
    this.props.setFilterScope(SEARCH_BAR_MEMBER);

    if (getStarFromStrSearch(this.props.router.location.search)) {
      this.setState({
        admin:
          this.props.authUserData.role && this.props.authUserData.role === 0,
        scope: STAR,
      });
    } else if (profile) {
      this.setState({
        admin:
          this.props.authUserData.role && this.props.authUserData.role === 0,
        scope: FRIEND,
      });
    } else if (club) {
      this.setState({
        admin:
          this.props.authUserData.role && this.props.authUserData.role === 0,
        scope: CLUB,
      });
    } else {
      this.getList(page, searchText);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchText, page } = this.state;
    if (prevProps.authUserData !== this.props.authUserData) {
      if (this.props.authUserData.role && this.props.authUserData.role === 0) {
        this.setAdmin(true);
      }
    }
    if (prevState.scope !== this.state.scope) {
      this.setLoadingFirst(true);
      if (this.state.scope === STAR) {
        this.getStarList(searchText);
      } else if (this.state.scope === FRIEND) {
        this.getFriendList(searchText);
      } else if (this.state.scope === CLUB) {
        this.getClubList();
      } else {
        this.getList(page, searchText);
      }
    }
  }

  setAdmin = (value) => {
    this.setState({
      admin: value,
    });
  };

  setLoadingFirst = (value) => {
    this.setState({
      loadingFirst: value,
    });
  };

  changeScope = (value) => {
    this.setState({ scope: value });
  };

  getStarList = (searchText) => {
    memberAPI
      .getStarList(searchText)
      .then((res) => {
        const { code, response } = res;
        if (code === 200) {
          this.setState({
            data: response.data,
            hasMore: response.hasMore,
            admin: response.admin,
            loadingFirst: false,
            handlerLoading: false,
          });
        } else {
          this.setState({
            loadingFirst: false,
            handlerLoading: false,
            hasMore: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loadingFirst: false,
          handlerLoading: false,
          hasMore: false,
        });
      });
  };

  getFriendList = (searchText) => {
    const { authUserData, profile, profileId, isLoggedIn } = this.props;

    this.setState({
      handlerLoading: true,
    });

    const getUserId = () => {
      if (profile && profileId) {
        return profileId;
      }
      if (isLoggedIn) {
        return authUserData.id;
      }
      return null;
    };

    userAPI
      .getFriendsList(getUserId(), searchText)
      .then((res) => {
        const { code, friends } = res;
        if (code === 200) {
          this.setState({
            data: friends,
            hasMore: false,
            loadingFirst: false,
            handlerLoading: false,
          });
        } else {
          cachHandler(res);
          this.setState({
            loadingFirst: false,
            handlerLoading: false,
            hasMore: false,
          });
        }
      })
      .catch(() => {
        // cachHandler(err);
        this.setState({
          loadingFirst: false,
          handlerLoading: false,
          hasMore: false,
        });
      });
  };

  getClubList = () => {
    const { dataClub } = this.props;

    this.setState({
      data: dataClub,
      hasMore: false,
      loadingFirst: false,
      handlerLoading: false,
    });
  };

  getList = (page, searchText) => {
    memberAPI
      .getMemberList(page, searchText)
      .then((res) => {
        const { code, response } = res;
        if (code === 200) {
          this.setState({
            data: response.data,
            hasMore: response.hasMore,
            admin: response.admin,
            loadingFirst: false,
            handlerLoading: false,
          });
        } else {
          this.setState({
            loadingFirst: false,
            handlerLoading: false,
            hasMore: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          loadingFirst: false,
          handlerLoading: false,
          hasMore: false,
        });
      });
  };

  loadMore = (page) => {
    const { searchText, data } = this.state;

    if (this.state.hasMore && this.state.scope === MEMBER) {
      memberAPI
        .getMemberList(page, searchText)
        .then((res) => {
          const { code, response } = res;
          if (code === 200) {
            this.setState({
              data: [...data, ...response.data],
              hasMore: response.hasMore,
              page,
            });
          } else {
            cachHandler(res);
            this.setState({
              data,
              hasMore: false,
              page,
            });
          }
        })
        .catch((err) => {
          cachHandler(err);
          this.setState({
            data,
            hasMore: false,
          });
        });
    }
  };

  updateData = (page, text) => {
    const { data } = this.state;

    const updateContent = (res = []) => {
      const t = [];
      data.forEach((item) => {
        res.forEach((a) => {
          if (item.id === a.id) {
            item = a;
          }
        });
        t.push(item);
      });
      return t;
    };
    if (this.state.scope === MEMBER) {
      memberAPI
        .getMemberList(page, text)
        .then((res) => {
          const { code, response } = res;
          if (code === 200) {
            this.setState({
              data: [...updateContent(response.data)],
            });
          } else {
            cachHandler(res);
            this.setState({
              data,
            });
          }
        })
        .catch((err) => {
          cachHandler(err);
          this.setState({
            data,
          });
        });
    }
  };

  onChangeSearch = (text) => {
    const { page } = this.state;
    this.setState({
      handlerLoading: true,
      searchText: text,
    });

    if (this.state.scope === STAR) {
      this.getStarList(text);
    } else if (this.state.scope === FRIEND) {
      this.getFriendList(text);
    } else {
      this.getList(page, text);
    }
  };

  render() {
    const {
      page,
      searchText,
      data,
      loadingFirst,
      handlerLoading,
      hasMore,
      admin,
    } = this.state;

    const component = this.props.isMobileDesign ? (
      <MembersMobile
        data={data}
        loadingFirst={loadingFirst}
        onChangeSearch={this.onChangeSearch}
        handlerLoading={handlerLoading}
        loadMore={this.loadMore}
        hasMore={hasMore}
        admin={admin}
        authUserData={this.props.authUserData}
        page={page}
        getList={this.getList}
        searchText={searchText}
        updateData={this.updateData}
        isLoggedIn={this.props.isLoggedIn}
        scope={this.state.scope}
        profile={this.props.profile}
        closeMemberList={this.props.closeMemberList}
        changeScope={this.changeScope}
        getFriendList={this.getFriendList}
      />
    ) : (
      <Members
        data={data}
        loadingFirst={loadingFirst}
        onChangeSearch={this.onChangeSearch}
        handlerLoading={handlerLoading}
        loadMore={this.loadMore}
        hasMore={hasMore}
        admin={admin}
        authUserData={this.props.authUserData}
        page={page}
        getList={this.getList}
        searchText={searchText}
        updateData={this.updateData}
        isLoggedIn={this.props.isLoggedIn}
        scope={this.state.scope}
        profile={this.props.profile}
        club={this.props.club}
        closeMemberList={this.props.closeMemberList}
        changeScope={this.changeScope}
        getFriendList={this.getFriendList}
      />
    );

    return <ErrorBoundary>{component}</ErrorBoundary>;
  }
}

const mapStateToProps = (store) => {
  return {
    authUserData: store.authentication.user,
    isLoggedIn: store.authentication.isLoggedIn,
    router: store.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setFilterScope,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMobileDetecting(MembersContainer));

import { listFilter } from '../../../constants/filter.constant';

const shortFilter = (a, b) => a.distance - b.distance;
const longFilter = (a, b) => b.distance - a.distance;
const azFilter = (a, b) =>
  a.title.localeCompare(b.title, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
const zaFilter = (a, b) =>
  b.title.localeCompare(a.title, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
const newFilter = (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at);
const oldFilter = (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at);
const popularFilter = (a, b) => b.views + b.downloads - (a.views + a.downloads);
const starFilter = (a, b) => b.star - a.star;

export const getSortFunction = (filterType) => {
  switch (filterType) {
    case listFilter.SHORT:
      return shortFilter;
    case listFilter.LONG:
      return longFilter;
    case listFilter.AZ:
      return azFilter;
    case listFilter.ZA:
      return zaFilter;
    case listFilter.NEW:
      return newFilter;
    case listFilter.OLD:
      return oldFilter;
    case listFilter.RATING:
      return popularFilter;
    case listFilter.STAR:
      return starFilter;
    default:
      return newFilter;
  }
};

import * as types from '../action_types';

export const mapPositionToPoint = (data) => {
  return {
    type: types.MAP_POSITION,
    payload: data,
  };
};

export const addTourPoint = (data) => {
  return {
    type: types.ADD_POINT_TO_ACTIVE_TOUR_DAY,
    payload: data,
  };
};

import { history } from 'app/store';

const checkPrevLink = (previousLocation, currentLocation) => {
  if (previousLocation && previousLocation.includes('edit')) {
    history.go(-2);
  } else if (previousLocation) {
    if (previousLocation.includes('createtour')) {
      history.push('/tour');
    } else {
      history.go(-1);
    }
  } else if (currentLocation && currentLocation.includes('club')) {
    history.push('/club');
  } else if (currentLocation && currentLocation.includes('bikerbed')) {
    history.push('/bikerbed');
  } else if (currentLocation && currentLocation.includes('tour')) {
    history.push('/tour');
  } else {
    history.push('/');
  }
};
export default checkPrevLink;

import React, { useEffect, Suspense } from 'react';
import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import { FormattedMessage } from 'react-intl';
import { pageScope } from 'app/constants/mapConstants';
import pageDataAPI from '../../../API/pageData.api';
import List from '../List/list.container';

const AllBikeClub = ({ page }) => {
  const { init, getAllClubs, scope } = useActions();

  useEffect(() => {
    getAllClubs();
    scope(pageScope.club);
    return () => {
      pageDataAPI.sourceCancel();
      init();
    };
  }, []);

  const getLabel = () => (
    <FormattedMessage id="Bike Clubs" defaultMessage="Bike Clubs" />
  );

  return (
    page === 'list' && (
      <Suspense>
        <List label={getLabel()} />
      </Suspense>
    )
  );
};

export default AllBikeClub;

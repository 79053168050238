import React from 'react';
const axios = require('axios');
const url = process.env.API_URL+"/api";

export const trackAPI = {
    getListTrack,
    getAproxDataTrack,
    getFullDataTrack,
    removeTrack
}

function getListTrack(){
    const requestOptions = {
        method: 'GET',
        withCredentials: true,
    };

    return axios(`${url}/track.getListTrack`, requestOptions).then(handleResponse);
}

function getAproxDataTrack(id){
    const requestOptions = {
        method: 'GET',
        withCredentials: true,
    };

    return axios(`${url}/track.getAproxDataTrack?track_id=${id}`, requestOptions).then(handleResponse);
}

function getFullDataTrack(id){
    const requestOptions = {
        method: 'GET',
        withCredentials: true,
    };

    return axios(`${url}/track.getFullDataTrack?id=${id}`, requestOptions).then(handleResponse);
}

function removeTrack(id) {
    const requestOptions = {
        method: 'GET',
        withCredentials: true,
    };
    return axios(`${url}/track.removeTrack?id=${id}` , requestOptions).then(handleResponse);    
}

const handleResponse = response => {
    return new Promise((resolve, reject) => {
        const data = response.data;
        if (response.status !== 200) {
            const error = (data && data.message) || response.statusText;
            return reject( error );
        }
        return resolve( data );
    });
}

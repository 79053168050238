import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { PlusOutlined } from '@ant-design/icons';

import { clubAPI } from 'app/API/club.api';

import withTranslations from 'app/components/HOGs/withTranslations';

import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import { cachHandler } from 'app/components/Utility/notificationMessage';
import {
  ModalPreviewVideo,
  ModalUploadVideo,
} from 'app/components/Utility/modalPreviewVideo';

import deleted from 'static/img/Delete_purple.svg';
import search from 'static/img/Search_purple.svg';

const Videos = ({ tr }) => {
  const { setClubVideo, setClubImageIds, loginModalOpen } = useActions();

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [previewVideosData, setPreviewVideosData] = useState({});
  const [index, setIndex] = useState(0);

  const isLoggedIn = useSelector((store) => store.authentication.isLoggedIn);

  const videos =
    useSelector((store) => store.clubFullDataReducer.data.videos) || [];
  const imgIds =
    useSelector((store) => store.clubFullDataReducer.data.imgIds) || '';

  const deleteVideo = (video) => {
    clubAPI
      .removeClubPictureById(video.id)
      .then((res) => {
        const { code } = res;
        if (code === 200) {
          videos.forEach((item) => {
            if (item === video) {
              videos.splice(videos.indexOf(item), 1);
              setClubVideo([...videos]);
            }
          });
        } else {
          cachHandler(res.message);
        }
      })
      .catch((err) => cachHandler(err.message));
  };

  return (
    <div className="media_layout_create">
      <div className="title">{tr.Videos}</div>
      <div className="clearfix">
        {visiblePreview ? (
          <ModalPreviewVideo
            visible={visiblePreview}
            setVisible={setVisiblePreview}
            video_id={previewVideosData.source}
            videosData={videos}
            index={index}
          />
        ) : null}
        <ModalUploadVideo
          visible={visibleUpload}
          setVisible={setVisibleUpload}
          setVideosData={setClubVideo}
          videosData={videos}
          setImgIds={setClubImageIds}
          imgIds={imgIds}
          tr={tr}
        />
        {videos.map((item, i) => (
          <div className="list_item" key={item.source}>
            <img
              src={deleted}
              className="delete_btn"
              onClick={() => deleteVideo(item)}
              alt=""
              aria-hidden
            />
            <img
              src={search}
              className="search_btn"
              alt=""
              aria-hidden
              onClick={() => {
                setVisiblePreview(true);
                setPreviewVideosData(item);
                setIndex(i);
              }}
            />
            <img
              src={`https://img.youtube.com/vi/${item.source}/default.jpg`}
              aria-hidden
              className="backgroud_video"
              alt=""
              onClick={() => {
                setVisiblePreview(true);
                setPreviewVideosData(item);
                setIndex(i);
              }}
            />
          </div>
        ))}
        <div
          className="upload_video"
          onClick={() => {
            if (!isLoggedIn) {
              loginModalOpen(true);
              return;
            }
            setVisibleUpload(true);
          }}
          aria-hidden
        >
          <PlusOutlined />
          <div className="upload_text">{tr.Upload}</div>
        </div>
      </div>
    </div>
  );
};

export default withTranslations(Videos);
